import React from 'react';
import { CabinetPageTemplate } from '../cabinet/CabinetPageTemplate';
import { ComposerPageTemplate } from '../cabinet/ComposerPageTemplate';

export const ComposerAboutPage = (): JSX.Element => {
  return (
    <ComposerPageTemplate>
      <div>
        Страница с реквизитами
      </div>
    </ComposerPageTemplate>
  );
};

export default ComposerAboutPage;
