import React, {Suspense, lazy} from 'react';
import {RegularPageTemplate} from '../RegularPageTemplate';
import {MainPageGenres} from '../direction/MainPageGenres';
import {Spinner} from '../Spinner';
import {useQuery} from '@apollo/react-hooks';
import {Typography} from 'antd';

const {Title} = Typography;

const BannerCarousel = lazy(() => import('../carousel/MainPageCarousel'));
import gql from 'graphql-tag';
import {isAuthenticated} from "../../graphql/common.queries";


export const CAROUSEL_QUERY = gql`
query CommonNamespace {
  common {
    carouselImages {
      filename
      url
      active
    }
    directions {
        find {
          id
          active
          name
          imgUrl
      }
    }
  }
}
`;

const styles = require('./MainPage-styles.module.css');

export function MainPage(): JSX.Element {
    const {loading, data} = useQuery(CAROUSEL_QUERY);
    //console.log(data?.common?.carouselImages);
    const directions = data?.common?.directions?.find.filter(el => el.active);

    return (
        loading ? (<Spinner/>) :
            <RegularPageTemplate style={{padding: 0}}>
                <Suspense fallback={<Spinner/>}>
                    <BannerCarousel>
                        {data?.common?.carouselImages?.map((i, k): JSX.Element => (
                                <img alt="" src={'/' + i?.url} key={k}/>
                            )
                        )}
                    </BannerCarousel>
                </Suspense>

                <div id="genres" className={styles.genres}>
                    <Title className={styles.genresTitle} level={2}>Выберите
                        свой музыкальный стиль</Title>
                    <MainPageGenres directions={directions}/>
                </div>
            </RegularPageTemplate>
    );
}
