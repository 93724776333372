import React from 'react';
import { Form } from '@ant-design/compatible';
import { Select } from 'antd';
import gql from 'graphql-tag';
import { TrackSiderFilterOptionsQuery } from '../../__generated__/TrackSiderFilterOptionsQuery';
import { useQuery } from '@apollo/react-hooks';
import { useLocation, useHistory } from 'react-router-dom';

const TRACK_SIDER_FILTER_OPTIONS_QUERY = gql`
  query TrackSiderFilterOptionsQuery($filter: TrackCommonFilterOptionsInput) {
    common {
      track {
        filterOptions(filter: $filter) {
          moods
          genres
          directions
          instruments
          tempos
        }
      }
    }
  }
`;

export function TrackSiderFilterForm(): JSX.Element {
  const query = new URLSearchParams(useLocation().search);

  const { data } = useQuery<TrackSiderFilterOptionsQuery>(TRACK_SIDER_FILTER_OPTIONS_QUERY, {
    variables: {
      filter: {
        genres: query.get('genres')?.split(','),
        moods: query.get('moods')?.split(','),
        directions: query.get('directions')?.split(','),
        instruments: query.get('instruments')?.split(','),
        tempos: query.get('tempos')?.split(','),
      },
    },
  });
  const { push } = useHistory();

  function handleSelectChange(values, name): void {
    if (values.length > 3) return;
    if (values.length) query.set(name, values);
    else query.delete(name);
    push(`/tracks?${query}`);
  }

  const selectData = [
    {
      name: 'genres',
      items: data?.common?.track?.filterOptions?.genres || [],
      placeholder: 'Жанры...',
    },
    {
      name: 'moods',
      items: data?.common?.track?.filterOptions?.moods || [],
      placeholder: 'Настроения...',
    },
    {
      name: 'directions',
      items: data?.common?.track?.filterOptions?.directions || [],
      placeholder: 'Направления...',
    },
    {
      name: 'instruments',
      items: data?.common?.track?.filterOptions?.instruments || [],
      placeholder: 'Инструменты...',
    },
    {
      name: 'tempos',
      items: data?.common?.track?.filterOptions?.tempos || [],
      placeholder: 'Темп...',
    },
  ];

  return (
    <Form>
      {selectData.map(elem => (
        <Form.Item key={`formItem${elem.name}`}>
          <Select
            mode={'tags'}
            placeholder={elem.placeholder}
            onChange={(values): void => handleSelectChange(values, elem.name)}
            value={query.get(elem.name)?.split(',')}
          >
            {elem.items.map(item => (
              <Select.Option key={`trackFilter${name}${item}`} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ))}
    </Form>
  );
}
