// @ts-nocheck
import React, { Suspense, lazy } from 'react';
import { RegularPageTemplate } from '../RegularPageTemplate';
import { Collapse } from 'antd';
const { Panel } = Collapse;
import { useQuery } from '@apollo/react-hooks';

import gql from 'graphql-tag';
import {PRIVACY_POLICY} from "../../graphql/common.queries";


export function PrivacyPolicy(): JSX.Element {


	function callback(key) {
		console.log(key);
	}

	const {data, loading} = useQuery(PRIVACY_POLICY);

	console.log("DATA" , data)
	const text = data?.common?.privacyPolicy[0].text

	return (
		<RegularPageTemplate  style={{ padding: 0 }}>
			<div className={"container"}>
				<h2>
					Политика конфиденциальности
				</h2>
				<div  dangerouslySetInnerHTML={{__html: text}} />
			</div>
		</RegularPageTemplate>
	);
}
