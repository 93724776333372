// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { CabinetPageTemplate } from '../cabinet/CabinetPageTemplate';
import { Input, InputNumber, Button, Collapse, message} from "antd";
const { Panel } = Collapse;
import { Form } from '@ant-design/compatible';
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {PayloadStatuses} from "../../types";
const { TextArea } = Input;

const ADMIN_FAQ_PATCH_MUTATION = gql`
  mutation AdminMutationsFaq($input: FaqPatchInput) {
    admin {
      faq {
        patch (input: $input) {
            status 
        }
      }
    }
  }
`;

const ADMIN_FAQ_FIND_ALL_QUERY = gql`
  query AdminFAQFindQuery {
    admin {
      faq {
        find {
          id
          text
          sort
          title
        }
      }
    }
  }
`;

const ACCOUNT_FAQ_DELETE_MUTATION = gql`
  mutation FaqDeleteMutation($id: String!) {
    admin {
      faq {
        delete(id: $id) {
          status
        }
      }
    }
  }
`;

const ACCOUNT_FAQ_CREATE_MUTATION = gql`
  mutation FaqCreateMutation($input:  CreateFAQInput) {
    admin {
      faq {
        create(input: $input) {
            status
             errors {
                    message
                }
        }
      }
    }
  }
`;

export const AdminAddEditFAQPage = (): JSX.Element => {

    const [texts, setTexts] = useState<any>();

    const {data, loading} = useQuery(ADMIN_FAQ_FIND_ALL_QUERY,{onCompleted: data => {
        console.log("ADMIN_FAQ_FIND_ALL_QUERY");
            if(data?.admin?.faq?.find) {
                setTexts(data?.admin?.faq?.find)
            } else {
                message.error('Не удалось загрузить данные');
            }
        } });

    const [deleteFaq, { loadingDelete }] = useMutation(ACCOUNT_FAQ_DELETE_MUTATION, {
        onCompleted: data => {
            console.log(data);
            if (data.admin?.faq?.delete?.status === 'success') message.success('Пункт успешно удалён');
            else message.error('Во время удаления пункта произошла ошибка');
        },
        refetchQueries: [{ query: ADMIN_FAQ_FIND_ALL_QUERY }],
        awaitRefetchQueries: true,
    });

    const [createFaq, { loadingCreate }] = useMutation(ACCOUNT_FAQ_CREATE_MUTATION, {
        onCompleted: data => {
            console.log(data);
            if (data.admin?.faq?.create?.status === 'success') message.success('Пункт успешно добавлен');
            else message.error('Во время создания пункта произошла ошибка');
        },
        refetchQueries: [ {query: ADMIN_FAQ_FIND_ALL_QUERY } ],
        awaitRefetchQueries: true,
    })


    const [patchFaq, { loading: patchFaqLoading }] = useMutation(
        ADMIN_FAQ_PATCH_MUTATION,
        {
            refetchQueries: [
                {
                    query: ADMIN_FAQ_FIND_ALL_QUERY,
                },
            ],
            onCompleted: data => {
                if (data.admin?.faq?.patch?.status === 'success') message.success('Пункт успешно обновлен');
                else message.error('Во время создания пункта произошла ошибка');
            },
        },
    );

    const handleChange = (e) => {
        const text = e.target.value;
        const index = e.target.id;
        const items = [...texts];
        items[index] = {text: text, title: items[index]?.title, id: items[index]?.id, sort: items[index]?.sort};
        setTexts(items);
        console.log(items);
    }

    const handleChangeTitle = (e) => {
        const title = e.target.value;
        const index = e.target.id.replace(/\D/g, "");;
        const items = [...texts];
        console.log(" index ", index );
        items[index] = {text: items[index]?.text, title: title,id: items[index]?.id, sort: items[index]?.sort};
        setTexts(items);
    }

    const handleChangeSort = (e) => {
        const sort = e.target.value;
        const index = e.target.id.match(/\d/g)[0];
        const items = [...texts];
        items[index] = {text: items[index]?.text, title: items[index]?.title,id: items[index]?.id, sort: Number(sort)};
        setTexts(items);
    }

    const addField = () => {
        // pick the biggest number and +1 it
        const sorts = texts.map(text => text.sort);
        const maxSort = sorts.sort((a, b) => b - a);
        console.log('maxSort ', maxSort[0]);
        setTexts(prev =>{
            return [...prev, {title: '', text: '', sort: maxSort[0]+1}];
        })
    };

    const handleSave = el => () => {
        console.log(el);
        if (!!el.id) {
            return patchFaq({ variables: { input: {id: el.id, title: el.title,  text: el.text, sort: Number(el.sort) } }});
        }
        return createFaq({ variables: { input: { title: el.title,  text: el.text, sort: Number(el.sort) } }});
    };
    const handleDelete = id => () => {
        const deleteFaqRes = deleteFaq({
            variables: { id },
        });
        return deleteFaqRes
    }


  return (
    <CabinetPageTemplate>
        {!texts ? <p>...Loading </p> :  <Form.Item>
            <Collapse defaultActiveKey={['0']} >
        {texts.sort((a, b) => a.sort - b.sort).map((el,i): JSX.Element => (
            <Panel header={el.title}  key={el.sort}>
                <div style={{marginBottom: 5}}>
                    Редактировать заголовок: <br/>
                    <Input placeholder="Заголовок" style={{maxWidth: 600}} onChange={handleChangeTitle} id={"t" +i} value={el.title}  />
                </div>
                <div style={{marginBottom: 5}}>
                    Сортировка: <br/>
                    <InputNumber onBlur={handleChangeSort} id={"n" +i} value={el.sort}  />
                </div>
                Текст: <br/>
                <TextArea style={{marginBottom: 5}} onChange={handleChange} id={i} rows={4} value={el.text}  />
                {!el.id ? <p> </p> :  <Button onClick={handleDelete(el.id)}
                 style={{marginRight: '5px'}}
                 type={'default'}>
                    Удалить
                </Button>
                    }

                <Button onClick={handleSave(el)} type={'primary'}>
                    Сохранить
                </Button>
                </Panel>
                )
            )}
            </Collapse>
            <Button onClick={addField} >
                Добавить пункт
            </Button>

        </Form.Item>
        }
    </CabinetPageTemplate>
  );
};

export default AdminAddEditFAQPage;
