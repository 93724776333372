import gql from "graphql-tag";

export const CREATE_DIRECTION_MUTATION = gql`
  mutation DirectionCreateMutation($input: CreateDirectionInput) {
    admin {
      direction {
        create(input: $input) {
          status
          direction {
            name,
            sort,
            imgUrl,
            filename
          }
        }
      }
    }
  }
`;

export const DIRECTION_DELETE_MUTATION = gql`
  mutation DirectionDeleteMutation($id: String!) {
    admin {
      direction {
        delete(id: $id) {
          status
        }
      }
    }
  }
`;

export const DIRECTION_PATCH_MUTATION = gql`
  mutation DirectionPatchMutation($input: PatchDirectionInput) {
    admin {
      direction {
        patch(input: $input) {
          status
          group {
            name
          }
        }
      }
    }
  }
`;
