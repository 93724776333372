import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import '@ant-design/compatible/assets/index.css';
import './css/ant.design.rewrites.css';
import {App} from './components/App';
import * as serviceWorker from './serviceWorker';
import {ApolloProvider} from 'react-apollo';
import {ApolloClient} from 'apollo-client';
import {createHttpLink} from 'apollo-link-http';
import {onError} from 'apollo-link-error';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {CONFIG} from './config';

const httpLink = createHttpLink({
    uri: CONFIG.GQL_GATEWAY,
    credentials: 'include',
});

console.log("CONFIG.GQL_GATEWAY: "+ CONFIG.GQL_GATEWAY);

const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
        for (const err of graphQLErrors) {
            if (err && err.extensions && err.extensions.code === 'UNAUTHENTICATED') {
                console.warn("UNAUTHENTICATED", err);
                // window.location.replace('/login');
            }
        }
    }
    if (networkError) {
        throw new Error(`[Network error]: ${networkError}`);
    }
});

const link = errorLink.concat(httpLink);

const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <App/>
    </ApolloProvider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
