import React, { useEffect } from 'react';
import { Button } from 'antd';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { ComposerGetPaymentManageCredentialsQuery } from '../../__generated__/ComposerGetPaymentManageCredentialsQuery';

const COMPOSER_GET_PAYMENT_MANAGE_CREDENTIALS_QUERY = gql`
  query ComposerGetPaymentManageCredentialsQuery {
    client {
      composer {
        payment {
          getManageCredentials {
            url
            data {
              PhoneNumber
              PlatformBeneficiaryId
              PlatformId
              ReturnUrl
              Signature
              Timestamp
            }
          }
        }
      }
    }
  }
`;

export function ComposerManageCardsButton(props: { buttonText: string }): JSX.Element {
  const [getSignature, { data, loading }] = useLazyQuery<ComposerGetPaymentManageCredentialsQuery>(
    COMPOSER_GET_PAYMENT_MANAGE_CREDENTIALS_QUERY,
    {
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    getSignature();
    const renewSignatureInterval = setInterval(() => {
      getSignature();
    }, 30000);
    return function cleanup(): void {
      clearInterval(renewSignatureInterval);
    };
  }, []);

  const fieldsData = data?.client?.composer?.payment?.getManageCredentials?.data;
  fieldsData && delete fieldsData.__typename;
  console.log('url для привязки',data?.client?.composer?.payment?.getManageCredentials?.url)
  return (
    <form method="POST" action={data?.client?.composer?.payment?.getManageCredentials?.url || ''}>
      {fieldsData &&
        Object.keys(fieldsData).map(fieldName => (
          <input
            name={fieldName}
            type="hidden"
            value={fieldsData[fieldName] || ''}
            key={`ManageCardsButton${fieldName}`}
          />
        ))}
      <Button loading={loading} htmlType={'submit'} type={"primary"}>
        {props.buttonText}
      </Button>
    </form>
  );
}
