// @ts-nocheck
import React, { useCallback } from 'react';
import { UploadSamplesList } from './UploadSamplesList';
import { message, Upload } from 'antd';
import { ROUTES } from '../../routes';
import { UploadProps } from 'antd/es/upload';
import { UploadSampleButton } from './UploadSampleButton';
import {UploadMainSample, UploadSample} from './track.interfaces';

import { PayloadStatuses } from '../../types';

interface MainSampleFieldProps {
    samples?: UploadSample[];
    onChange?(samples: UploadSample[]): void;
}

import { PlusOutlined } from '@ant-design/icons';



const styles = require('./MainSampleField-styles.module.css');

export const MainSampleField = (props: MainSampleFieldProps): JSX.Element => {
  const handleUploadChange = useCallback(
    ({ file, fileList }): void => {
      props.onChange && props.onChange([...fileList]);
      if (file.status === 'done' && file.response?.result === PayloadStatuses.SUCCESS) {
        props.onChange && props.onChange(fileList.map(f => (f.uid === file.uid ? { ...f, id: file.response.id } : f)));
        message.success(`Семпл ${file.name} успешно загружен!`);
      } else if (file.status === 'error') {
        message.error(`Произошла ошибка при загрузке ${file.name}!`);
      }
    },
    [props],
  );
//   const handleBeforeUpload =  useCallback(
//       ( file, fileList ): any => {
//           if (file.type !== 'image/png') {
//               message.error(`${file.name} is not a png file`);
//           }
//           return file.type === 'image/png' ? true : false;
//       },
//         [props],
// );

  return (
    <div>
        {props && props.samples && props.samples.length >= 1 && Array.isArray(props.samples) && (
          <UploadSamplesList
          samples={props.samples.slice(0, 1)}
          allSamples = {props.samples}
          onRemove={(s): void => {
              console.log( "MainSampleField UploadSamplesList", props.samples, "s", s )
              props.samples && props.onChange && props.onChange(props.samples.filter(sample => sample.id !== s.id));
            message.success(`Основная версия ${s.name} успешно удалён`);
          }}
          onChange={(allSamples): void => {
              console.log("SAMPLE DESCR CHANGED MainSampleFieldProps")
              props.onChange && props.onChange([...allSamples]);
          }}
        />
      )}
        { props && props.samples && props.samples.length == 0 ?
      <Upload
        {...({
          name: 'file',
          multiple: false,
          action: ROUTES.CABINET.COMPOSER.UPLOAD_SAMPLE,
          className: styles.sampleUploadField,
          showUploadList: false,
          accept: '.wav,audio/mp3',
          listType: 'picture',
          fileList: props.samples[0],
          withCredentials: true,
            maxCount: 1,
          onChange: handleUploadChange,
         // beforeUpload: handleBeforeUpload
        } as UploadProps)}
      >
          <div className={styles.sampleUploadButton}>
              <input type="file" accept="" style={{ display: 'none' }} />
              <div>
                  <PlusOutlined />
                  <p className="ant-upload-text">Добавить основной трек</p>
              </div>
          </div>
      </Upload> : "" }
    </div>
  );
};
