import React from 'react';
import { Button, message, Popconfirm, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { ComposerTrackWithdrawMutation } from '../../../../__generated__/ComposerTrackWithdrawMutation';
import { ExecutionResult } from 'graphql';
import { TRACK_STATUS } from '../../track.interfaces';

const COMPOSER_TRACK_WITHDRAW_MUTATION = gql`
  mutation ComposerTrackWithdrawMutation($id: String!) {
    client {
      composer {
        track {
          withdraw(id: $id) {
            id
            status
          }
        }
      }
    }
  }
`;

export const TrackTableWithdrawTrackButton = (props: { status?: number; trackId: string }): JSX.Element => {
  const [withdraw, { loading }] = useMutation<ComposerTrackWithdrawMutation>(COMPOSER_TRACK_WITHDRAW_MUTATION, {
    onCompleted: data => {
      if (data.client?.composer?.track?.withdraw.status === TRACK_STATUS.REJECTED.value) {
        message.success('Трек успешно отозван');
      }
    },
  });

  const text = 'Вы уверены, что хотите отозвать трек?';

  function handleConfirm(): Promise<ExecutionResult> {
    return withdraw({
      variables: {
        id: props.trackId,
      },
    });
  }

  return (
    <Popconfirm title={text} onConfirm={handleConfirm}>
      <Tooltip title="Отозвать трек" placement={'left'}>
        <Button size={'small'} shape={'circle'} loading={loading}>
          <CloseOutlined style={{ color: 'red' }} />
        </Button>
      </Tooltip>
    </Popconfirm>
  );
};
