import React, {useEffect, useRef} from 'react';
import {Button, message} from 'antd';
import gql from 'graphql-tag';
import {useMutation} from '@apollo/react-hooks';
import {ExecutionResult} from 'graphql';
import {TrackCheckoutMutation} from '../../../__generated__/TrackCheckoutMutation';
import {CART_PAGE_TRACKS_FIND_QUERY} from '../../cart/CartPage';
import {CUSTOMER_ORDERS_PAGE_ORDERS_FIND_QUERY} from '../../order/CustomerOrdersPage';

const TRACK_CHECKOUT_MUTATION = gql`
  mutation TrackCheckoutMutation($trackId: String!) {
    client {
      cart {
        checkout(trackId: $trackId) {
          status
          url
          data {
            PlatformDealId
            PlatformId
            ReturnUrl
            Signature
            Timestamp
          }
        }
      }
    }
  }
`;

export function TrackTableCheckoutButton(props: { trackId: string }): JSX.Element {
    const formRef = useRef<HTMLFormElement>(null);

    // todo заменить эту мутацию на создание заказа в Тинькофф, убрать очистку корзины на данном этапе
    const [checkout, {data, loading}] = useMutation<TrackCheckoutMutation>(TRACK_CHECKOUT_MUTATION, {
        onError: error => {
            if (error?.message?.includes('Supplier must setup payment tool!')) {
                message.error('Композитор не указал карту для вывода средств! Покупка невозможна');
            }
        },
    });

    function handleCheckout(e): Promise<ExecutionResult> {
        e.stopPropagation();
        return checkout({
            variables: {
                trackId: props.trackId,
            },
            fetchPolicy: 'no-cache',
        });
    }

    useEffect(() => {
        console.log("Submitting form", data);
        if (formRef && formRef.current && data?.client?.cart?.checkout?.url === formRef.current.action)
            formRef.current.submit();
    }, [data]);

    const fieldsData = data?.client?.cart?.checkout?.data;
    fieldsData && delete fieldsData.__typename;

    return (
        <>
            <form method="POST" target="_blank" action={data?.client?.cart?.checkout?.url || ''} ref={formRef}>
                {fieldsData &&
                    Object.keys(fieldsData).map(fieldName => (
                        <input
                            name={fieldName}
                            type="hidden"
                            value={fieldsData[fieldName] || ''}
                            key={`ManageCardsButton${fieldName}`}
                        />
                    ))}
            </form>
            <Button type={'primary'} size={'small'} onClick={handleCheckout} loading={loading}>
                Оформить заказ
            </Button>
        </>
    );
}
