// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { CabinetPageTemplate } from '../cabinet/CabinetPageTemplate';
import {Form, Input, Typography, Button, message, Spin, InputNumber, Space} from 'antd';

import { formItemLayout } from '../form/options';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ROUTES } from '../../routes';
import { UploadSample} from './track.interfaces';
import { PayloadStatuses } from '../../types';
import { useParams, useHistory } from 'react-router';
import { SamplesField } from './SamplesField';
import { GenresField } from '../genre/GenresField';
import { InstrumentsField } from '../instrument/InstrumentsField';
import { MoodsField } from '../mood/MoodsField';
import { MainSampleField } from './MainSampleField';
import { DirectionsSelect } from '../direction/DirectionSelect';
import gql from 'graphql-tag';
import { TrackStatusField } from './TrackStatusField';
import { AdminTrackEditPageFindByIdQuery } from '../../__generated__/AdminTrackEditPageFindByIdQuery';
import { AdminTrackEditPagePatchMutation } from '../../__generated__/AdminTrackEditPagePatchMutation';
//import { ADMIN_TRACKS_PAGE_TABLE_FIND_ALL_QUERY } from './AdminTracksTablePage';
import { ADMIN_TRACKS_PAGE_TABLE_FIND_ALL_QUERY } from '../../graphql/admin.queries';
import { PublisherSelectField } from '../publisher/PublisherSelectField';
import { MessageType } from 'antd/es/message';
import { ExecutionResult } from 'graphql';
import { TRACK_FIND_BY_ID } from "../../graphql/admin.queries"
const styles = require('./AdminTrackEditPage-styles.module.css');



const TRACK_PATCH_MUTATION = gql`
  mutation AdminTrackEditPagePatchMutation($input: TrackAdminPatchInput) {
    admin {
      track {
        patch(input: $input) {
          status
        }
      }
    }
  }
`;

function TrackEditForm(): JSX.Element {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();
  const [uploadSampleList, setUploadSampleList] = useState<UploadSample[]>([]);
  const { data: findTrackData, loading: findTrackLoading } = useQuery<AdminTrackEditPageFindByIdQuery>(
    TRACK_FIND_BY_ID,
    {
      variables: { id },
    },
  );

  const [patchTrack, { loading: patchTrackLoading }] = useMutation<AdminTrackEditPagePatchMutation>(
    TRACK_PATCH_MUTATION,
    {
      refetchQueries: [
        {
          query: ADMIN_TRACKS_PAGE_TABLE_FIND_ALL_QUERY,
        },
        {
          query: TRACK_FIND_BY_ID,
          variables: { id },
        },
      ],
      onCompleted: data => {
        if (data?.admin?.track?.patch?.status === PayloadStatuses.SUCCESS)
          history.push(ROUTES.CABINET.ADMIN.TRACKS.LIST);
      },
    },
  );

  const track = findTrackData?.common?.track?.findById;

  useEffect(() => {
    if (track?.samples) setUploadSampleList(track.samples);
  }, [track]);

  const handleSubmit = (values): MessageType | Promise<ExecutionResult> => {
    if (uploadSampleList.length === 0) return message.error('Пожалуйста, загрузите хотя бы один семпл!');
    const input = {
      id: track?.id,
      ...values,
      coauthor: values.coauthor+"",
      samples: uploadSampleList.reduce((samples: UploadSample[], sample) => {
        return sample.id ? samples.concat({ id: sample.id, description: sample.description }) : samples;
      }, []),
    };

    return patchTrack({
      variables: {
        input,
      },
    });
  };

  return findTrackLoading || patchTrackLoading || !track ? (
      <Spin />
    ) : (
    <div className="layout-center">
      <div className="container" >
    <Form
      {...formItemLayout}
      style={{transform: "translateX(-200px)"}}
      className={styles.addTrackForm}
      form={form}
      initialValues={{
        name: track.name,
        author: track.author,
        status: track?.status,
        publisherId: track?.publisherId,
        price: track.price,
        genres: track.genres.map(genre => genre.name),
        instruments: track.instruments.map(instrument => instrument.name),
        moods: track.moods.map(mood => mood.name),
        coauthor: track?.coauthor?.replaceAll('"', "").split(','),
        directions: track.directions.map(direction => direction.name),
        bpm: track.bpm,
        description: track.description,
      }}
      onFinish={handleSubmit}
      scrollToFirstError={true}
    >
      <Typography.Title level={3} className={styles.title}>
        Управление треком
      </Typography.Title>
      <Form.Item label={'Статус'} name={'status'}>
        <TrackStatusField />
      </Form.Item>
      <Form.Item
        label="Название трека"
        name={'name'}
        rules={[{ required: true, message: 'Пожалуйста, введите название трека!' }]}
      >
        <Input placeholder={'Вечерний вальс'} />
      </Form.Item>

      <Form.Item label={'Издатель'} name={'publisherId'}>
        <PublisherSelectField />
      </Form.Item>
      <Form.Item
        label={'Соавтор'}
        name={'coauthor'}>
      <Form.List
          initialValue={track?.coauthor?.substring(1).substring(0, track?.coauthor.length-2).replaceAll('"', "").split(',')}
          name={"coauthor"}>

        {(fields, { add, remove }) => (
            <>
            {(fields.map((field, index) => (
                <div key={index}  style={{display: "flex",  width: '100%'}} >
                  <Form.Item
                      style={{ minWidth: '90%'}}
                      {...field}
                  >
                    <Input style={{ minWidth: '90%'}} />

                  </Form.Item>
                  <MinusCircleOutlined style={{marginTop: '10px'}} onClick={() => remove(field.name)} />
                </div>
            )))}

              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Добавить соавтора
                </Button>
              </Form.Item>
            </>
        )}
      </Form.List>
    </Form.Item>
      <Form.Item label="Цена" name={'price'} rules={[{ required: true, message: 'Пожалуйста, укажите цену!' }]}>
        <InputNumber min={0} placeholder={'120.99'} />
      </Form.Item>
      <GenresField />
      <InstrumentsField />
      <MoodsField />
      <Form.Item label={'Направления'} name={'directions'}>
        <DirectionsSelect mode={'multiple'} maxTagCount={3} />
      </Form.Item>
      <Form.Item label="BPM" name={'bpm'}>
        <InputNumber min={0} placeholder={'120'} />
      </Form.Item>
      <Form.Item label="Основной трек">
        <MainSampleField
            samples={uploadSampleList}
            onChange={(samples): void => {
              setUploadSampleList([...samples]);
            }}
        />
      </Form.Item>
      <Form.Item label="Версии трека">
        <SamplesField
          samples={uploadSampleList}
          onChange={(samples): void => {
            setUploadSampleList([...samples]);
          }}
        />
      </Form.Item>
      <Form.Item>
        <Button type={'primary'} htmlType={'submit'}>
          Сохранить
        </Button>
      </Form.Item>
    </Form>
      </div>
        </div>
  );
}

export function AdminTrackEditPage(): JSX.Element {
  return (
    <CabinetPageTemplate>
      <TrackEditForm />
    </CabinetPageTemplate>
  );
}
