import React, { useCallback, useState } from 'react';
import { CabinetPageTemplate } from '../cabinet/CabinetPageTemplate';
import { ComposerPageTemplate } from '../cabinet/ComposerPageTemplate';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Form } from '@ant-design/compatible';
import { Checkbox, Input, message, Spin, Button } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';

import { pick } from '../../utils';
import gql from 'graphql-tag';
import { InputINN } from '../form/InputINN';
import { INNInterface } from '../inn/inn.interface';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { formItemLayout } from "../form/options";
import { COMPOSER_PASSPORT_QUERY } from "../../graphql/client.queries"

export interface ComposerInterface {
  id: string;
  nickname: string;
  showNickname: boolean;
  howlong: string;
  instruments: string;
  styles: string;
  liveInstruments: string;
  readyToOrder: boolean;
  readyToRemake: boolean;
}

interface Account {
  passport: Passport;
  inn: INNInterface;
  composer: ComposerInterface;
  email: string;
}

interface Passport {
  id: string;
  secondName: string;
  firstName: string;
  thirdName: string;
  series: string;
  number: string;
  registrationAddress: string;
}

const styles = require('./ComposerPassportPage-styles.module.css');

const { Item } = Form;



export const COMPOSER_PATCH_PASSPORT_MUTATION = gql`
  mutation PatchPassport($passport: PassportPatchInput, $inn: INNPatchITC, $composer: ComposerPatchAboutInput) {
    client {
      composer {
        patchPassport(input: $passport) {
          status
        }
        patchINN(input: $inn) {
          status
        }
        patchAbout(input: $composer) {
          status
        }
      }
    }
  }
`;

const PassportForm = Form.create<FormComponentProps>({ name: 'passportForm' })((props: FormComponentProps) => {
  const [showNickname, setShowNickname] = useState<boolean>(false);
  const { data: passportData } = useQuery(COMPOSER_PASSPORT_QUERY, {
    onCompleted: data => {
      const composer = pick<ComposerInterface>(data, 'client.account.composer');
      setShowNickname(!!composer && composer.showNickname);
    },
  });
  const [patchPassportPage] = useMutation(COMPOSER_PATCH_PASSPORT_MUTATION, {
    refetchQueries: [{ query: COMPOSER_PASSPORT_QUERY }],
    onCompleted: () => {
      message.success('Данные успешно сохранены!');
    },
  });

  const account = pick<Account>(passportData, 'client.account');

  const { getFieldDecorator, validateFields } = props.form;

  const handleSave = useCallback((): void => {
    account &&
      validateFields((errors, values) => {
        if (!errors) {
          return patchPassportPage({
            variables: {
              passport: { ...values.passport, id: account.passport.id },
              composer: { ...values.composer, id: account.composer.id, showNickname },
              inn: { id: account.inn.id, number: values.inn.number },
            },
          });
        }
      });
  }, [patchPassportPage, validateFields, account, showNickname]);

  return account ? (
    <div>
      <div>
        <Form {...formItemLayout} className={styles.form} labelAlign={'left'}>
          <Item label="Фамилия">
            {getFieldDecorator('passport.secondName', {
              initialValue: account.passport.secondName,
              rules: [{ required: true, message: 'Пожалуйста, введите свою фамилию!' }],
            })(<Input placeholder={'Иванов'} />)}
          </Item>
          <Item label="Имя">
            {getFieldDecorator('passport.firstName', {
              initialValue: account.passport.firstName,
              rules: [{ required: true, message: 'Пожалуйста, введите своё имя!' }],
            })(<Input placeholder={'Иван'} />)}
          </Item>
          <Item label="Отчество">
            {getFieldDecorator('passport.thirdName', {
              initialValue: account.passport.thirdName,
              rules: [{ required: true, message: 'Пожалуйста, введите своё отчество!' }],
            })(<Input placeholder={'Иванович'} />)}
          </Item>

          <Item label="Творческий псевдоним">
            {getFieldDecorator('composer.nickname', {
              initialValue: account.composer.nickname,
            })(<Input placeholder={'Ваш псевдоним'} />)}
          </Item>

          <Item label="Отображать псевдоним вместо ФИО">
            <Checkbox
              checked={showNickname}
              onChange={(event: CheckboxChangeEvent): void => {
                setShowNickname(event.target.checked);
              }}
            />
          </Item>

          <Item label={'Электронная почта'}>
            {getFieldDecorator('email', {
              initialValue: account.email,
              rules: [{ required: true, message: 'Пожалуйста, введите адрес электронной почты!' }],
            })(<Input placeholder={'myname@yandex.ru'} />)}
          </Item>

          <Item label={'Паспорт серия'}>
            {getFieldDecorator('passport.series', {
              initialValue: account.passport.series,
              rules: [{ required: true, message: 'Пожалуйста, введите серию паспорта!' }],
            })(<Input placeholder={'1134'} />)}
          </Item>
          <Item label={'Паспорт номер'}>
            {getFieldDecorator('passport.number', {
              initialValue: account.passport.number,
              rules: [{ required: true, message: 'Пожалуйста, введите номер паспорта!' }],
            })(<Input placeholder={'543377'} />)}
          </Item>

          <Item label="Адрес регистрации">
            {getFieldDecorator('passport.registrationAddress', {
              initialValue: account.passport.registrationAddress,
              rules: [{ required: true, message: 'Пожалуйста, адрес регистрации по паспорту!' }],
            })(<Input placeholder={'г. Санкт-Петербург, ул. Комсомольская, д. 5'} />)}
          </Item>
          <InputINN
            name={'inn.number'}
            initialValue={account && account.inn.number}
            getFieldDecorator={getFieldDecorator}
          />
          <Item wrapperCol={{ span: 18 }} style={{ textAlign: 'right' }}>
            <Button onClick={handleSave} type={'primary'}>
              Сохранить
            </Button>
          </Item>
        </Form>
      </div>
    </div>
  ) : (
    <Spin />
  );
});

export const ComposerPassportPage = (): JSX.Element => {
  return (
    <ComposerPageTemplate>
      <PassportForm />
    </ComposerPageTemplate>
  );
};

export default ComposerPassportPage;
