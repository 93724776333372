import React, {useState} from 'react';
import {CabinetPageTemplate} from '../cabinet/CabinetPageTemplate';
import {ComposerPageTemplate} from '../cabinet/ComposerPageTemplate';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {Form} from '@ant-design/compatible';
import {Input, message, Select, Spin, Button, Checkbox} from 'antd';
import {FormComponentProps} from '@ant-design/compatible/es/form';
import styles from '../cabinet/composer/onboarding/onboarding.module.css';
import {pick} from '../../utils';
import gql from "graphql-tag";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {ComposerInterface} from "./ComposerPassportPage";

const {Item} = Form;

interface Composer {
	id: string;
	nickname: string;
	howlong: string;
	instruments: string;
	styles: string;
	liveInstruments: string;
	showNickname: boolean;
	readyToOrder: boolean;
	readyToRemake: boolean;
}

export const COMPOSER_ABOUT_QUERY = gql`
  query ComposerAboutPageQuery {
    client {
      profile {
        composer {
          id
          nickname
          showNickname
          howlong
          instruments
          styles
          liveInstruments
          readyToOrder
          readyToRemake
        }
      }
    }
  }
`;

export const COMPOSER_PATCH_ABOUT_MUTATION = gql`
  mutation BecomeAComposerAboutStepMutation($input: ComposerPatchAboutInput) {
    client {
      composer {
        patchAbout(input: $input) {
          status
          errors {
            message
          }
        }
      }
    }
  }
`;

const AboutForm = Form.create<FormComponentProps>({name: 'aboutForm'})((props: FormComponentProps) => {
	const [showNickname, setShowNickname] = useState<boolean>(false);
	const {data: aboutData, loading: aboutLoading} = useQuery(COMPOSER_ABOUT_QUERY, {
		fetchPolicy: "no-cache",
		onCompleted: data => {
			setShowNickname(!!composer && composer.showNickname);
		},
	});
	const [saveAbout, {loading: aboutMutationLoading}] = useMutation(COMPOSER_PATCH_ABOUT_MUTATION, {
		refetchQueries: [{query: COMPOSER_ABOUT_QUERY}],
		onCompleted: () => {
			{
				message.success('Информация "О себе" успешно сохранена!');
			}
		},
	});

	const composer = pick<any>(aboutData, 'client.profile.composer');

	const formItemLayout = {
		labelCol: {
			xs: {span: 16},
		},
		wrapperCol: {
			xs: {span: 8},
		},
	};
	const {getFieldDecorator, validateFields} = props.form;

	const handleSave = (): void => {
		validateFields(async (errors, values) => {
			if (!errors) {
				values = {
					...values,
					id: composer && composer.id,
					showNickname,
					readyToOrder: values.readyToOrder === 'Да',
					readyToRemake: values.readyToRemake === 'Да',
				} as Composer;
				await saveAbout({variables: {input: values}});
			}
		});
	};

	return composer ? (
		<div className="layout-center">
			<div className={styles.stepsContentWrapperBlue} style={{transform: "translateX(-50px)"}}>
				{aboutLoading || aboutMutationLoading ? (
					<Spin/>
				) : (
					<Form {...formItemLayout} className={styles.wizardFormBlue} labelAlign={'left'}>

						<Item label={<label style={{color: "white"}}>Ваш псевдоним</label>}
						      style={{marginBottom: "12px"}}>
							{getFieldDecorator('nickname', {
								initialValue: composer.nickname,
							})(<Input type={'text'} placeholder=" "/>)}
						</Item>

						<Item label={<label style={{color: "white"}}>Отображать псевдоним вместо ФИО</label>}
						      style={{marginBottom: "12px"}}>
							{getFieldDecorator('showNickname', {
								initialValue: composer.showNickname,
							})(
								<Checkbox
									style={{display: "inline"}}
									checked={showNickname}
									onChange={(event: CheckboxChangeEvent): void => {
										setShowNickname(event.target.checked);
									}}
								/>)}
						</Item>

						<Item label={<label style={{color: "white"}}>С какого года Вы сочиняете музыку?</label>}
						      style={{marginBottom: "12px",}}>
							{getFieldDecorator('howlong', {
								initialValue: composer.howlong,
							})(<Input type={'number'} placeholder="2010" min={1900} max={new Date().getFullYear()}/>)}
						</Item>

						<Item label={<label style={{color: "white"}}>Какими музыкальными инструментами Вы
							владеете?</label>} style={{marginBottom: "12px"}}>
							{getFieldDecorator('instruments', {
								initialValue: composer.instruments,
							})(<Input placeholder="Гитара."/>)}
						</Item>

						<Item label={<label style={{color: "white"}}>Ваши любимые стили написания музыки?</label>}
						      style={{marginBottom: "12px"}}>
							{getFieldDecorator('styles', {
								initialValue: composer.styles,
							})(<Input placeholder="Фанк"/>)}
						</Item>

						<Item
							label={<label style={{color: "white"}}>Используете ли Вы в записи «живые инструменты»? Если
								да, то какие?</label>} style={{marginBottom: "12px"}}>
							{getFieldDecorator('liveInstruments', {
								initialValue: composer.liveInstruments,
							})(<Input placeholder="Труба"/>)}
						</Item>

						<Item label={<label style={{color: "white"}}>Готовы ли Вы писать музыку «на заказ»?</label>}
						      style={{marginBottom: "12px"}}>
							{getFieldDecorator('readyToOrder', {
								initialValue: composer.readyToOrder ? 'Да' : 'Нет',
							})(
								<Select>
									<Select.Option value={'Да'}>Да</Select.Option>
									<Select.Option value={'Нет'}>Нет</Select.Option>
								</Select>,
							)}
						</Item>

						<Item label={<label style={{color: "white"}}>Готовы ли Вы переделать готовый трек по просьбе
							заказчика?</label>} style={{marginBottom: "12px"}}>
							{getFieldDecorator('readyToRemake', {
								initialValue: composer.readyToRemake ? 'Да' : 'Нет',
							})(
								<Select>
									<Select.Option value={'Да'}>Да</Select.Option>
									<Select.Option value={'Нет'}>Нет</Select.Option>
								</Select>,
							)}
						</Item>
						<Item wrapperCol={{span: 24}} style={{textAlign: 'right'}}>
							<Button onClick={handleSave} type={'primary'} style={{
								background: "linear-gradient(#1e73be, #1e73be, #315581)",
								boxShadow: "2px 2px 6px rgb(0 0 0 / 32%)"
							}}>
								Сохранить
							</Button>
						</Item>
					</Form>
				)}
			</div>
		</div>
	) : (
		<Spin/>
	);
});

export const ComposerAboutPage = (): JSX.Element => {
	return (
		<ComposerPageTemplate>
			<AboutForm/>
		</ComposerPageTemplate>
	);
};

export default ComposerAboutPage;
