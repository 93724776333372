import { FormProps } from 'antd/es/form';

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    md: { span: 7 },
    lg: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 15 },
    lg: { span: 20 },
  },
};

export const spaceBetweenFormLayout = {
  labelCol: {
    xs: { span: 24 },
    md: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 10 },
  },
  labelAlign: 'left',
} as FormProps;
