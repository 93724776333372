import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FastBackwardOutlined, FastForwardOutlined, CaretRightOutlined, PauseOutlined } from '@ant-design/icons';

import VolumeBar from './VolumeBar/VolumeBar';
import { useAudioPlayer } from './useAudioPlayer';
import WaveSurfer from 'wavesurfer.js/dist/wavesurfer';
import SeekBar from './SeekBar';

const styles = require('./AudioPlayer-styles.module.css');

const transformSecondsToMinutesWithSeconds = (seconds: number): string => {
  return `${Math.floor(seconds / 60)}:${('0' + Math.floor(seconds % 60)).slice(-2)}`;
};

const getPercentage = (value, amount): number => {
  return value && amount ? (value / amount) * 100 : 0;
};

export const AudioPlayer = (): React.ReactElement => {
  const { togglePlay, player, trackName, prev, next, currentTrack } = useAudioPlayer();

  const [seeking, setSeeking] = useState(false);
  const [played, setPlayed] = useState<number>(player.currentTime);
  const [duration, setDuration] = useState<number>(player.duration);
  const [volume, setVolume] = useState<number>(0.6);
  const [muted, setMuted] = useState<boolean>(false);
  const [bufferedVisible, setBufferedVisible] = useState(false);
  const [progressVisible, setProgressVisible] = useState(false);

  const backgroundRef = useRef<HTMLDivElement>(null);
  const bufferedRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);

  const handleTimeUpdate = useCallback(
    (event): void => {
      event.preventDefault();
      !seeking && setPlayed(event.target.currentTime);
    },
    [seeking],
  );

  const handleDurationChange = useCallback((event): void => {
    event.preventDefault();
    setDuration(event.target.duration);
  }, []);

  const addPlayerEventListeners = useCallback(
    (player: HTMLMediaElement): void => {
      player.addEventListener('timeupdate', handleTimeUpdate);
      player.addEventListener('durationchange', handleDurationChange);
    },
    [handleTimeUpdate, handleDurationChange],
  );

  const removePlayerEventListeners = useCallback(
    (player: HTMLMediaElement): void => {
      player.removeEventListener('timeupdate', handleTimeUpdate);
      player.removeEventListener('durationchange', handleDurationChange);
    },
    [handleTimeUpdate, handleDurationChange],
  );

  useEffect(() => {
    addPlayerEventListeners(player);
    player.volume = muted ? 0 : volume;

    return function clear(): void {
      player && removePlayerEventListeners(player);
    };
  }, [player, seeking, volume, muted, addPlayerEventListeners, removePlayerEventListeners]);

  const clearWaves = (): void => {
    progressRef && progressRef.current && (progressRef.current.innerHTML = '');
    bufferedRef && bufferedRef.current && (bufferedRef.current.innerHTML = '');
    backgroundRef && backgroundRef.current && (backgroundRef.current.innerHTML = '');
  };

  useEffect(() => {
    const waveContainer = document.querySelector(`.${styles.waveBarContainer}`);
    const commonWavesConfig = {
      interact: false,
      height: 32,
      // barWidth: 2,
      cursorWidth: 0,
      hideScrollbar: true,
      // partialRender: true,
    };

    if (waveContainer) {
      const backgroundWave = WaveSurfer.create({
        container: backgroundRef.current,
        waveColor: '#f5f5f5',
        ...commonWavesConfig,
      });
      const bufferedWave = WaveSurfer.create({
        container: bufferedRef.current,
        waveColor: '#e9e9e9',
        ...commonWavesConfig,
      });

      bufferedWave.on('ready', () => {
        setBufferedVisible(true);
      });

      const progressWave = WaveSurfer.create({
        container: progressRef.current,
        waveColor: '#1e73be',
        ...commonWavesConfig,
      });

      progressWave.on('ready', () => {
        setProgressVisible(true);
      });

      [backgroundWave, bufferedWave, progressWave].map(async wave => {
        await wave.load(currentTrack.src);
      });
    }
    return function clear(): void {
      setBufferedVisible(false);
      setProgressVisible(false);
      clearWaves();
    };
  }, [currentTrack.src]);

  const getSecondsLoaded = (): number => {
    if (!player || player.buffered.length === 0) return 0;

    const end = player.buffered.end(player.buffered.length - 1);

    return end > duration ? duration : end;
  };

  const handleSeeking = (value): void => {
    setPlayed(value * duration);
    !seeking && setSeeking(true);
  };

  const handleSeeked = (value): void => {
    player && (player.currentTime = value * duration);
    setSeeking(false);
  };

  const handleVolumeChanged = (volume): void => {
    setVolume(volume);
    setMuted(false);
  };

  const handleMuteClick = (): void => {
    setMuted(!muted);
  };

  //console.log(trackName);

  return trackName ? (
    <div className={styles.playerWrapper}>
      <div className={styles.player}>
        <div className={styles.playButtonsArea}>
          <button className={styles.actionButton} onClick={prev}>
            <FastBackwardOutlined />
          </button>
          <button className={styles.actionButton} onClick={togglePlay}>
            {player.paused ? <CaretRightOutlined /> : <PauseOutlined />}
          </button>
          <button className={styles.actionButton} onClick={next}>
            <FastForwardOutlined />
          </button>
        </div>
        <div className={styles.infoArea}>
          <div className={styles.sampleName}>{trackName}</div>
          <div className={styles.progressbarArea}>
            <div className={styles.progressbarTime}>{transformSecondsToMinutesWithSeconds(played)}</div>
            <div className={styles.progressbarContainer}>
              <SeekBar onSeeking={handleSeeking} onSeeked={handleSeeked}>
                <div className={styles.waveBarContainer}>
                  <div id={'playerBackground'} className={styles.waveBar} ref={backgroundRef} />
                  <div
                    id={'playerBuffered'}
                    className={styles.waveBar}
                    style={{
                      visibility: bufferedVisible ? 'visible' : 'hidden',
                      width: (bufferedVisible ? getPercentage(getSecondsLoaded(), duration) : 100) + '%',
                    }}
                    ref={bufferedRef}
                  />
                  <div
                    id={'playerProgresss'}
                    className={styles.waveBar}
                    style={{
                      visibility: progressVisible ? 'visible' : 'hidden',
                      width: (progressVisible ? getPercentage(played, duration) : 100) + '%',
                    }}
                    ref={progressRef}
                  />
                </div>
              </SeekBar>
            </div>
            <div className={styles.progressbarTime}>{transformSecondsToMinutesWithSeconds(duration)}</div>
          </div>
        </div>
        <div className={styles.volumeBarArea}>
          <VolumeBar onChange={handleVolumeChanged} onMuteClick={handleMuteClick} value={volume} muted={muted} />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default AudioPlayer;
