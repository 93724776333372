import React from 'react';
import {useHistory, useLocation} from "react-router";

export function TrackTableNameCell(text, track: { name: string; authorName?: string }): JSX.Element {
    const query = new URLSearchParams(useLocation().search);
    const {push} = useHistory();

    function handleSelectChange(values, name): void {
        let keys: Array<string> = [];
        for (let key of query.keys()) {
            console.log('key', key);
            keys.push(key)
        }
        for (let key of keys) {
            query.delete(key);
        }
        query.set(name, values);
        push(`/tracks?${query}`);
    }

    return (
        <div>
            <div style={{ fontWeight: 'bold' }}>{track.name}</div>
            <a
                onClick={(): void => handleSelectChange(track.authorName, "authorName")}>
                {track.authorName && <div>{track.authorName}</div>}
            </a>
        </div>
    );
}
