import gql from "graphql-tag";

export const INSTRUMENTS_FIND_ALL_QUERY = gql`
  query InstrumentsFindAllQuery {
    common {
      instruments {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

export const CREATE_INSTRUMENT_MUTATION = gql`
  mutation CreateInstrument($input: CreateInstrumentInput) {
    admin {
      instrument {
        create(input: $input) {
          status
          instrument {
            name
          }
        }
      }
    }
  }
`;

export const DELETE_INSTRUMENT_MUTATION = gql`
  mutation DeleteInstrument($id: String!) {
    admin {
      instrument {
        delete(id: $id) {
          status
        }
      }
    }
  }
`;

export const PATCH_INSTRUMENT_MUTATION = gql`
  mutation PatchInstrument($input: PatchInstrumentInput) {
    admin {
      instrument {
        patch(input: $input) {
          status
          group {
            name
          }
        }
      }
    }
  }
`;
