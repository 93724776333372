import React from 'react';
import { Button, Typography } from 'antd';

import { ROUTES } from '../../routes';
import { NavLink } from 'react-router-dom';
import { HeartFilled } from '@ant-design/icons';

const style = require('./FavoritesEmptyText-style.module.css');

export function FavoritesEmptyText(): JSX.Element {
  return (
    <div className={style['empty-favorites-wrapper']}>
      <Typography.Title level={3}>Список избранных треков пуст</Typography.Title>
      <Typography.Paragraph>
        Добавляйте треки в избранное нажимая на <HeartFilled />{' '}
      </Typography.Paragraph>
      <NavLink to={ROUTES.COMMON.TRACKS.MAIN}>
        <Button type={'primary'}>Найти <HeartFilled /> треки</Button>
      </NavLink>
    </div>
  );
}
