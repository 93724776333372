import gql from "graphql-tag";

export const SIGN_UP = gql`
  mutation SignUp($input: SignUpInput) {
    common {
      auth {
        signUp(input: $input) {
          status
          errors {
            message
          }
        }
      }
    }
  }
`;

export const LOGIN = gql`
  mutation Login($login: Email!, $password: String!) {
    common {
      auth {
        login(input: { login: $login, password: $password }) {
          status
          errors {
            message
          }
        }
      }
    }
  }
`;

/**
 * Мутация Забыли пароль.
 * Высылает ссылку на сброс пароля на емейл пользователя.
 */
export const FORGOT_PASSWORD = gql`
  mutation Forgot($login: Email!) {
    common {
      auth {
        forgot(input: { login: $login }) {
          status
          errors {
            message
          }
        }
      }
    }
  }
`;

