import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { IS_AUTHENTICATED } from '../../graphql/common.queries';
import { Spin } from 'antd';
import { Redirect } from 'react-router';
import { ROUTES } from '../../routes';
import gql from "graphql-tag";
import { IsAuthenticatedQuery } from '../../__generated__/IsAuthenticatedQuery';
import { LogoutMutation } from '../../__generated__/LogoutMutation';

const LOGOUT_MUTATION = gql`
  mutation LogoutMutation {
    client {
      logout {
        status
        errors {
          message
        }
      }
    }
  }
`;

export const Logout = (): JSX.Element => {
  const { data, loading } = useQuery<IsAuthenticatedQuery>(IS_AUTHENTICATED);
  const [logout] = useMutation<LogoutMutation>(LOGOUT_MUTATION, {
    awaitRefetchQueries: true,
    fetchPolicy: 'no-cache',
    refetchQueries: [{ query: IS_AUTHENTICATED }],
    ignoreResults: true,
  });

  if (loading) return <Spin />;

  if (data?.common?.isAuthenticated) logout().then(() => {
    return <Redirect to={ROUTES.AUTH.LOGIN} />;
  });
  return <Redirect to={ROUTES.AUTH.LOGIN} />;
};

