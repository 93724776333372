import React, { useCallback, useEffect, useRef } from 'react';


const styles = require('./SeekBar-styles.module.css');

interface SeekBarProps {
  children?: React.ReactElement | React.ReactElement[];
  value?: number;
  onSeeking(value): void;
  onSeeked(value): void;
}

export const SeekBar = (props: SeekBarProps): JSX.Element => {
  const barRef = useRef<HTMLDivElement>(null);

  const getValuePercentage = (cursorPositionX): number => {
    if (barRef && barRef.current) {
      const { left, width } = barRef.current.getBoundingClientRect();
      const num = cursorPositionX > left ? cursorPositionX - left : 0;
      return num > width ? 1 : num / width;
    } else return 0;
  };

  const handleSeeking = useCallback(
    (cursorPositionX): void => {
      props.onSeeking && props.onSeeking(getValuePercentage(cursorPositionX));
    },
    [props],
  );

  const handleSeeked = useCallback(
    (cursorPositionX): void => {
      props.onSeeked && props.onSeeked(getValuePercentage(cursorPositionX));
    },
    [props],
  );

  const handleMouseMoveOnControlDown = useCallback(
    (event): void => {
      barRef && barRef.current && handleSeeking(event.clientX);
    },
    [handleSeeking],
  );

  const handleMouseUp = useCallback(
    (event: MouseEvent): void => {
      if (barRef && barRef.current) {
        handleSeeked(event.clientX);
        document.removeEventListener('mousemove', handleMouseMoveOnControlDown);
        document.removeEventListener('mouseup', handleMouseUp);
      }
    },
    [handleSeeked, handleMouseMoveOnControlDown],
  );

  const handleMouseDown = useCallback(
    (event): void => {
      if (barRef && barRef.current) {
        handleSeeking(event.clientX);
        document.addEventListener('mousemove', handleMouseMoveOnControlDown);
        document.addEventListener('mouseup', handleMouseUp);
      }
    },
    [handleSeeking, handleMouseUp, handleMouseMoveOnControlDown],
  );

  const handleTouchMoveOnControlDown = useCallback(
    (event): void => {
      if (barRef && barRef.current && event.targetTouches && event.targetTouches.length) {
        handleSeeking(event.targetTouches[0].clientX);
      }
    },
    [handleSeeking],
  );

  const handleTouchEnd = useCallback(
    (event: TouchEvent): void => {
      if (barRef && barRef.current) {
        handleSeeking(event.changedTouches[0].clientX);
        document.removeEventListener('touchmove', handleTouchMoveOnControlDown);
        document.removeEventListener('touchend', handleTouchEnd);
      }
    },
    [handleSeeking, handleTouchMoveOnControlDown],
  );

  const handleTouchStart = useCallback(
    (event: TouchEvent): void => {
      if (barRef && barRef.current && event.targetTouches && event.targetTouches.length > 0) {
        handleSeeking(event.targetTouches[0].clientX);
        document.addEventListener('touchmove', handleTouchMoveOnControlDown);
        document.addEventListener('touchend', handleTouchEnd);
      }
    },
    [handleSeeking, handleTouchEnd, handleTouchMoveOnControlDown],
  );

  const addListeners = useCallback(
    (bar: HTMLDivElement): void => {
      bar.addEventListener('mousedown', handleMouseDown);
      bar.addEventListener('touchstart', handleTouchStart, { passive: true });
    },
    [handleMouseDown, handleTouchStart],
  );

  const removeListeners = useCallback(
    (bar: HTMLDivElement): void => {
      bar.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mousemove', handleMouseMoveOnControlDown);
      bar.removeEventListener('mouseup', handleMouseUp);

      bar.removeEventListener('touchstart', handleTouchStart);
      // document.removeEventListener('touchmove', handleTouchMoveOnControlDown);
      bar.removeEventListener('touchend', handleTouchEnd);
    },
    [handleMouseUp, handleMouseDown, handleMouseMoveOnControlDown, handleTouchStart, handleTouchEnd],
  );

  useEffect(() => {
    const bar = barRef.current;
    bar && addListeners(bar);

    return function clear(): void {
      bar && removeListeners(bar);
    };
  }, [addListeners, removeListeners]);

  return (
    <div className={styles.seekBarWrapper} ref={barRef}>
      {props.children}
    </div>
  );
};

export default SeekBar;
