// @ts-nocheck
import React from 'react';
import { CabinetPageTemplate } from '../cabinet/CabinetPageTemplate';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { CustomerOrdersPageOrdersFindQuery } from '../../__generated__/CustomerOrdersPageOrdersFindQuery';
import { Spinner } from '../Spinner';
import { TableTrack, TrackTable } from '../track/table/TrackTable';
import { TrackTableRowColumns } from '../track/table/TrackTableRow';

export const CUSTOMER_ORDERS_PAGE_ORDERS_FIND_QUERY = gql`
  query CustomerOrdersPageOrdersFindQuery {
    client {
      order {
        find {
          id
          track {
            id
            name
            article
            bpm
            publisher {
              name
            }
            description
            authorName
            mainSampleId
            isFavorite
            inCart
            sameTracksCount
            getSameTracks {
                name
                id
                authorName
                samples {
                  id
                  name
                  description
                  duration
                }
            }
            genres {
              name
            }
            instruments {
              name
            }
            moods {
              name
            }
            samples {
              id
              name
              description
              duration
            }
          }
          status
          price
          createdAt
          state
        }
      }
    }
  }
`;

export function CustomerOrdersPage(): JSX.Element {
 const { data, loading } = useQuery<CustomerOrdersPageOrdersFindQuery>(CUSTOMER_ORDERS_PAGE_ORDERS_FIND_QUERY);

  const tracks =
    data?.client?.order?.find?.map(
      (order): TableTrack => ({
        ...order.track,
        orderId: order.id,
        orderState: order.state,
        price: order.price,
        orderDate: order.createdAt,
        orderNumber: order.id,
      }),
    ) || [];
    const orders =
        data?.client?.order?.find?.map(
            (order) => ({
                id: order.id,
            }),
        ) || [];

  //console.log("TRACKS", tracks)
  //console.log("Orders", orders)

  const columns: TrackTableRowColumns = {
    article: true,
    actions: {downloadFiles:true, certificate: true},
    orderState: true,
    orderDate: true,
    orderNumber: true,
    price: true
  };

  return <CabinetPageTemplate>{<TrackTable  tracks={tracks} cols={columns} />}</CabinetPageTemplate>;
}
