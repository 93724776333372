import React from 'react';
import { CabinetPageTemplate } from '../../../cabinet/CabinetPageTemplate';
import { Layout, Menu } from 'antd';
import { ShopOutlined, UserOutlined } from '@ant-design/icons';
import { RouteChildrenProps, withRouter } from 'react-router';
import { ROUTES } from '../../../../routes';
import { Link } from 'react-router-dom';

const ComposerSideMenu = withRouter(
  (props: RouteChildrenProps): JSX.Element => {
    return (
      <Layout.Sider theme="light">
        <Menu mode="inline" defaultSelectedKeys={['1']} selectedKeys={[props.location.pathname]}>
          <Menu.Item key={ROUTES.CABINET.COMPOSER.PASSPORT}>
            <ShopOutlined />
            <span>Паспорт</span>
            <Link to={ROUTES.CABINET.COMPOSER.PASSPORT} />
          </Menu.Item>
          <Menu.Item key={ROUTES.CABINET.COMPOSER.INN}>
            <UserOutlined />
            <span>ИНН</span>
            <Link to={ROUTES.CABINET.COMPOSER.INN} />
          </Menu.Item>
          <Menu.Item key={ROUTES.CABINET.COMPOSER.PAYMENT}>
            <UserOutlined />
            <span>Реквизиты</span>
            <Link to={ROUTES.CABINET.COMPOSER.PAYMENT} />
          </Menu.Item>
        </Menu>
      </Layout.Sider>
    );
  },
);

export const ComposerPage = (): JSX.Element => {
  return (
    <CabinetPageTemplate>
      <>
        <ComposerSideMenu />
        <Layout.Content
          style={{
            margin: '24px 16px',
            padding: 24,
            background: '#fff',
            minHeight: 280,
          }}
        >
          <div>привет!</div>
        </Layout.Content>
      </>
    </CabinetPageTemplate>
  );
};

export default ComposerPage;
