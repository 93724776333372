import React, { useCallback, useEffect, useRef } from 'react';

import VolumeButton from './VolumeButton';

const styles = require('./VolumeBar-styles.module.css');

export const VolumeBar = (props: {
  onChange(value: number): void;
  onMuteClick(): void;
  value: number;
  muted: boolean;
}): JSX.Element => {
  const barRef = useRef<HTMLDivElement>(null);

  const getValuePercentage = (event): number => {
    if (barRef && barRef.current) {
      const num = event.clientX > barRef.current.offsetLeft ? event.clientX - barRef.current.offsetLeft : 0;
      return num > barRef.current.offsetWidth ? 1 : num / barRef.current.offsetWidth;
    } else return 0;
  };

  const getValuePercent = (barOffsetLeft: number, barWidth: number, cursorPosition: number): number => {
    const num = cursorPosition > barOffsetLeft ? cursorPosition - barOffsetLeft : 0;
    return num > barWidth ? 1 : num / barWidth;
  };

  const handleValueChange = useCallback(
    (value): void => {
      props.onChange(value);
    },
    [props],
  );

  const handleMouseMoveOnControlDown = useCallback(
    (event): void => {
      barRef && barRef.current && handleValueChange(getValuePercentage(event));
    },
    [handleValueChange],
  );

  const handleTouchMoveOnControlDown = useCallback(
    (event): void => {
      if (barRef && barRef.current && event.targetTouches && event.targetTouches.length) {
        handleValueChange(
          getValuePercent(barRef.current.offsetLeft, barRef.current.offsetWidth, event.targetTouches[0].clientX),
        );
      }
    },
    [handleValueChange],
  );

  const handleControlUp = useCallback(
    (event): void => {
      event.preventDefault();
      if (barRef && barRef.current) {
        document.removeEventListener('mousemove', handleMouseMoveOnControlDown);
        document.removeEventListener('touchmove', handleTouchMoveOnControlDown);
        document.removeEventListener('mouseup', handleControlUp);
        document.removeEventListener('touchend', handleControlUp);
      }
    },
    [handleMouseMoveOnControlDown, handleTouchMoveOnControlDown],
  );

  const handleControlDown = useCallback(
    (event): void => {
      if (barRef && barRef.current) {
        handleValueChange(getValuePercentage(event));
        document.addEventListener('mousemove', handleMouseMoveOnControlDown);
        document.addEventListener('mouseup', handleControlUp);
      }
    },
    [handleMouseMoveOnControlDown, handleControlUp, handleValueChange],
  );

  const handleTouchStart = useCallback(
    (event: TouchEvent): void => {
      if (barRef && barRef.current && event.targetTouches && event.targetTouches.length > 0) {
        handleValueChange(
          getValuePercent(barRef.current.offsetLeft, barRef.current.offsetWidth, event.targetTouches[0].clientX),
        );

        document.addEventListener('touchmove', handleTouchMoveOnControlDown);
        document.addEventListener('touchend', handleControlUp);
      }
    },
    [handleControlUp, handleTouchMoveOnControlDown, handleValueChange],
  );

  const addListeners = useCallback(
    (bar: HTMLDivElement): void => {
      bar.addEventListener('mousedown', handleControlDown);
      bar.addEventListener('touchstart', handleTouchStart);
    },
    [handleControlDown, handleTouchStart],
  );

  const removeListeners = useCallback(
    (bar: HTMLDivElement): void => {
      bar.removeEventListener('mousedown', handleControlDown);
      bar.removeEventListener('touchstart', handleTouchStart);
      bar.removeEventListener('mouseup', handleControlUp);
      bar.removeEventListener('touchend', handleControlUp);
      document.removeEventListener('mousemove', handleMouseMoveOnControlDown);
      document.removeEventListener('touchmove', handleTouchMoveOnControlDown);
    },
    [handleControlDown, handleTouchStart, handleControlUp, handleMouseMoveOnControlDown, handleTouchMoveOnControlDown],
  );

  useEffect(() => {
    const bar = barRef.current;
    bar && addListeners(bar);

    return function clear(): void {
      bar && removeListeners(bar);
    };
  }, [addListeners, removeListeners]);

  return (
    <div className={styles.volumeBarWrapper}>
      <VolumeButton onClick={props.onMuteClick} muted={props.muted} volume={props.value} />
      <div className={styles.volumeBarContainer} ref={barRef}>
        <div className={styles.bar} style={{ width: (props.muted ? 0 : props.value * 100) + '%' }} />
      </div>
    </div>
  );
};

export default VolumeBar;
