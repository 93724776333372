import React, { ChangeEvent, MouseEvent } from 'react';
import { Input, Progress } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { UploadSample } from './track.interfaces';

const styles = require('./UploadSamplesList-styles.module.css');

export const UploadSamplesList = (props: {
  samples: UploadSample[];
  allSamples: any;
  onChange?(samples: UploadSample[]): void;
  onRemove?(sample: UploadSample): void;
  onInputChange?(value: string, sample: UploadSample): void;
}): JSX.Element => {
  return (
    <div>
      <div className={styles.uploadListWrapper}>
        {props.samples.map(sample => (
          <div className={styles.uploadListItem} key={sample.id || sample.uid}>
            <div className={styles.uploadListItemInfo}>
              <Progress type="circle" width={40} percent={sample.id ? 100 : Math.floor(sample.percent || 0)} />
              <span className={styles.uploadListItemName} title={sample.name}>
                {sample.name}
              </span>
              <Input
                placeholder={'Краткое описание'}
                value={sample.description || ''}
                onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                  props.onChange &&
                  props.onChange(
                      props.allSamples.map(s => (s.id === sample.id ? { ...sample, description: event.target.value } : s)),
                    );
                  props.onInputChange && props.onInputChange(event.target.value, sample);
                }}
                disabled={!sample.id}
              />
            </div>
            <CloseOutlined
              title={'Удалить версию'}
              onClick={(event: MouseEvent<HTMLElement>): void => {
                event.preventDefault();
                props.onRemove && props.onRemove(sample);
              }}
              className={styles.uploadListItemCloseButton}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default UploadSamplesList;
