// @ts-nocheck
import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {CommonTracksPageTableFindAllQuery} from '../../__generated__/CommonTracksPageTableFindAllQuery';
import {RegularPageTemplate} from '../RegularPageTemplate';
import {TrackTable} from './table/TrackTable';
import {useLocation} from 'react-router';
import {TrackSiderFilter} from './TrackSiderFilter';
import {TrackTableExpandedRowContent} from './table/TrackTableExpandedRowContent';

export const COMMON_TRACKS_PAGE_TABLE_FIND_ALL_QUERY = gql`
  fragment CommonTracksPageTableTrackFragment on Track {
    id
    name
    article
    price
    bpm
    publisher {
      name
    }
     directions {
      name
    }
    description
    authorName
    coauthor
    mainSampleId
    isFavorite
    inCart
    sameTracksCount
    genres {
      name
    }
    instruments {
      name
    }
    moods {
      name
    }
    samples {
      id
      name
      description
      duration
    }
    getSameTracks {
        name
        id
        price
        article
        bpm
        publisher {
            name
        }
        directions {
            name
        }
         genres {
          name
        }
        instruments {
          name
        }
        moods {
          name
        }
        coauthor
        authorName
        isFavorite
        inCart
        samples {
          id
          name
          description
          duration
        }
    }
  }
  query CommonTracksPageTableFindAllQuery($filter: TrackCommonFindFilterInput) {
    common {
      track {
        find(filter: $filter) {
          ...CommonTracksPageTableTrackFragment
        }
      }
    }
  }
`;

export const CommonTracksTablePage = (): JSX.Element => {
    const query = new URLSearchParams(useLocation().search);
    const {loading, data} = useQuery<CommonTracksPageTableFindAllQuery>(COMMON_TRACKS_PAGE_TABLE_FIND_ALL_QUERY, {
        variables: {
            filter: {
                genres: query.get('genres')?.split(','),
                moods: query.get('moods')?.split(','),
                directions: query.get('directions')?.split(','),
                instruments: query.get('instruments')?.split(','),
                tempos: query.get('tempos')?.split(','),
                authorName: query.get('authorName'),
            },
        },
    });

    const tracks = (data?.common?.track?.find || []).filter(track => !(query.has('favorites') && !track.isFavorite));


    return (
        <RegularPageTemplate>
            <div className="container" style={{display: 'flex'}}>
                <TrackSiderFilter style={{width: '220px', flexShrink: 0}}/>
                <TrackTable
                    loading={loading}
                    tracks={tracks}
                    cols={{
                        sameTrack: true,
                        duration: true,
                        bpm: true,
                        price: true,
                        actions: {
                            demo: true,
                            favorite: true,
                            cart: true,
                        },
                    }}
                    style={{width: '100%'}}
                    expandable={{
                        expandedRowRender: TrackTableExpandedRowContent,
                        expandRowByClick: true,
                    }}
                />
            </div>
        </RegularPageTemplate>
    );
};
