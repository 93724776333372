import React from 'react';
import {DeleteOutlined} from '@ant-design/icons';
import {Button, message, Popconfirm, Tooltip} from 'antd';
import gql from 'graphql-tag';
import {useMutation} from "@apollo/react-hooks";
import {TrackDeleteMutation} from "../../../../__generated__/TrackDeleteMutation";

import {ExecutionResult} from "graphql";
import {useParams} from "react-router";
//import {ADMIN_TRACKS_PAGE_TABLE_FIND_ALL_QUERY} from "../../AdminTracksTablePage";
import { ADMIN_TRACKS_PAGE_TABLE_FIND_ALL_QUERY } from '../../../../graphql/admin.queries';
import {TRACK_DELETE_MUTATION} from "../../../../graphql/client.mutations"


export const TrackTableDeleteButton = (props: { trackId: string }): JSX.Element => {
	const {id} = useParams();
	const [deleteTrack] = useMutation(TRACK_DELETE_MUTATION, {
		refetchQueries: [{query: ADMIN_TRACKS_PAGE_TABLE_FIND_ALL_QUERY}],
		onCompleted: () => {
			message.success('Трек успешно удален');
		},
	});

	const text = 'Вы уверены, что хотите удалить трек?';

	function handleConfirm(): Promise<ExecutionResult> {
		console.log(props.trackId)
		return deleteTrack({
			variables: {
				id: props.trackId
			}
		})
	}

	return (
		<Popconfirm title={text} onConfirm={handleConfirm}>
			<Tooltip title="Удалить">
				<Button
					size={'small'}
					shape={'circle'}
				>
					<DeleteOutlined/>
				</Button>
			</Tooltip>
		</Popconfirm>
	);
};