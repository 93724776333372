import React, {Suspense} from "react";
import {Image} from 'antd';
import {CONFIG} from "../../config";


const styles = require('./MainPageGenres-styles.module.css');

export function MainPageGenres(props): JSX.Element {
    const handleNavigationClick = (name) => {
        //window.location.href = `${CONFIG.HOST}/tracks?directions=${name}`;
        window.location.href = `/tracks?directions=${name}`;
    };
    return (
        <div className="gridContainer container directions">
            {
                props.directions.map((el, index) => (
                    <div className={styles.direction} key={index} style={{
                        //backgroundImage: `linear-gradient(rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.15) 50%, rgba(0, 0, 0, 0) 75%), url(/${el.imgUrl})`,
                        //backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(/${el.imgUrl})`,
                        //backgroundImage: `url(/${el.imgUrl})`,
                    }}
                         onClick={() => handleNavigationClick(el.name)}>
                        <div className={styles.directionImg} style={{
                            aspectRatio: '1',
                            background: ` linear-gradient(
							          rgba(0, 0, 0, 0.5), 
							          rgba(0, 0, 0, 0.5)
							        ), url(/${el.imgUrl})`,
                            backgroundSize: "cover", backgroundPosition: "center",
                            boxShadow: `0px 0px 7px  #FFFFFF`,
                            display: "flex", alignItems: "center",
                            justifyContent: "center", cursor: "pointer"
                        }}>
                        </div>
                        <div className={styles.directionName}>
                            {el.name}
                        </div>
                    </div>
                ))
            }
        </div>
    );
}