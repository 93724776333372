// @ts-nocheck

import React, {useState, useCallback, useEffect} from 'react';
import { CabinetPageTemplate } from '../cabinet/CabinetPageTemplate';
import { useMutation, useQuery } from '@apollo/react-hooks';
import LzEditor from 'react-lz-editor'
import gql from 'graphql-tag';
import {format} from "date-fns";
import {Button, message} from "antd";
import {PayloadStatuses} from "../../types";
import {ROUTES} from "../../routes";
import {MessageType} from "antd/es/message";
import {ExecutionResult} from "graphql";

const ADMIN_PRIVACY_POLICY_INFO_FIND_QUERY = gql`
  query AdminPrivacyPolicyInfoFindQuery {
    admin {
      privacyPolicyInfo {
        find {
          id
          text
          title
        }
      }
    }
  }
`;

const ADMIN_PRIVACY_POLICY_INFO_PATCH_MUTATION = gql`
  mutation AdminMutationsPrivacyPolicy ($input: PrivacyPolicyPatchInput) {
    admin {
      privacyPolicyInfo {
        patch (input: $input) {
            status
        }
      }
    }
  }
`;

export function AdminEditPrivacyPolicyInfoPage(): JSX.Element {

   const [text, setText] = useState<any>();
   const {data, loading} = useQuery(ADMIN_PRIVACY_POLICY_INFO_FIND_QUERY,{fetchPolicy: "no-cache",onCompleted: data => {
       if(data?.admin?.privacyPolicyInfo?.find[0].text) {
           setText(data?.admin?.privacyPolicyInfo?.find[0].text)
        }
       } });

   let textQuery = data?.admin?.privacyPolicyInfo?.find[0].text
   let textID = data?.admin?.privacyPolicyInfo?.find[0].id

   // console.log("textQuery  ", textQuery)


    const [patchPrivacyPolicyInfo, { loading: patchPrivacyPolicyInfoLoading }] = useMutation(
        ADMIN_PRIVACY_POLICY_INFO_PATCH_MUTATION,
        {
            refetchQueries: [
                {
                    query: ADMIN_PRIVACY_POLICY_INFO_FIND_QUERY,
                },
            ],
            onCompleted: data => {
                if (data?.admin?.privacyPolicyInfo?.patch?.status === PayloadStatuses.SUCCESS) {
                    console.log(data?.admin?.privacyPolicyInfo?.patch?.status);
                    message.success('Сохранено');
                }
                else message.error('Во время сохранения произошла ошибка');
            },
        },
    );


    function handleChange(textInput): void {
        console.log(textInput)
        setText(textInput);
    }

    const handleSubmit = (values): MessageType | Promise<ExecutionResult> => {
        const input = {
            id: textID,
            title: '!',
            text: text
        }

        return patchPrivacyPolicyInfo({
            variables: {
                input,
            },
        });
    }
        return (
            <CabinetPageTemplate>
            <div>
                <div> Редактирование текста</div>
                <LzEditor
                    active={true}
                    importContent={textQuery}
                    image={false}
                    video={false}
                    audio={false}
                    pasteNoStyle={false}
                    cbReceiver={handleChange}/>
            </div>
                <Button onClick={handleSubmit} type={'primary'}>
                    Сохранить
                </Button>
            </CabinetPageTemplate>
        );
    }
