// @ts-nocheck
import React, { Suspense, lazy } from 'react';
import { RegularPageTemplate } from '../RegularPageTemplate';
import { Collapse, Button } from 'antd';
const { Panel } = Collapse;
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { IS_AUTHENTICATED, isAuthenticated } from '../../graphql/common.queries';
import gql from 'graphql-tag';
import {ROUTES} from "../../routes";


export function BecomeComposerInfoPage(): JSX.Element {

	const { data: authData, loading: authLoading } = useQuery(IS_AUTHENTICATED/*, {fetchPolicy: "no-cache"}*/);
	const COMMON_BECOME_COMPOSER_INFO_FIND_QUERY = gql`
    query BecomeComposerInfo {
        common {
	      becomeComposerInfo {
	          id
	          text
	          title
	      }
	    }
	  }
	`;

	function callback(key) {
		console.log(key);
	}

	const {data, loading} = useQuery(COMMON_BECOME_COMPOSER_INFO_FIND_QUERY);

	console.log("DATA" , data)
	const text = data?.common?.becomeComposerInfo[0].text

	return (
		<RegularPageTemplate  style={{ padding: 0 }}>
			<div className={"container"}>
				<h2>
					Как стать композитором
				</h2>
				<div  dangerouslySetInnerHTML={{__html: text}} />
				<div>
					<Button type={"primary"} style={{margin: "auto", display: "block"}}>
						<Link to={isAuthenticated(authData) ? ROUTES.CABINET.COMPOSER.NEW :  ROUTES.AUTH.SIGN_UP_COMPOSER }> Стать композитором </Link>
					</Button>
				</div>
			</div>
		</RegularPageTemplate>
	);
}
