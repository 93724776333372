import React from 'react';
import {TableTrack} from '../TrackTable';
import {TrackTableDownloadDemoButton} from './TrackTableDownloadDemoButton';
import {Link} from 'react-router-dom';
import {ROUTES} from '../../../../routes';
import {Button} from 'antd';
import {TrackToggleFavoritesButton} from './TrackToggleFavoritesButton';
import {TrackToggleCartButton} from './TrackToggleCartButton';
import {TrackTableWithdrawTrack} from './TrackTableWithdrawTrack';
import {EditOutlined} from '@ant-design/icons';
import {TrackTableAdminEditButton} from './TrackTableAdminEditButton';
import {TrackTableDownloadFilesButton} from './TrackTableDownloadFilesButton';
import {TrackTableDeleteButton} from './TrackTableDeleteButton';
import {TrackTableCheckoutButton} from '../TrackTableCheckoutButton';
import {TrackCertificateButton} from './TrackCertificateButton';

export type TrackTableActions = {
    demo?: boolean;
    composerEdit?: boolean;
    favorite?: boolean;
    cart?: boolean;
    certificate?: boolean;
    withdraw?: boolean;
    adminEdit?: boolean;
    downloadFiles?: boolean;
    deleteFiles?: boolean;
    checkout?: boolean;
};

export function getTrackTableActionsCell(actions?: TrackTableActions) {
    return function TrackTableActionsCell(text, track: TableTrack): JSX.Element {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'left',
                    gap: '5px'
                }}
            >
                {actions?.downloadFiles && <TrackTableDownloadFilesButton track={track}/>}
                {actions?.adminEdit && <TrackTableAdminEditButton trackId={track.id}/>}
                {actions?.demo && <TrackTableDownloadDemoButton sampleId={track.mainSampleId}/>}
                {actions?.deleteFiles && <TrackTableDeleteButton trackId={track.id}/>}
                {actions?.composerEdit && (
                    <Link to={ROUTES.CABINET.COMPOSER.TRACKS.EDIT.replace(':id', track.id)}>
                        <Button icon={<EditOutlined/>} size={'small'} shape={'circle'}/>
                    </Link>
                )}
                {actions?.checkout && <TrackTableCheckoutButton trackId={track.id}/>}
                {actions?.favorite &&
                    <TrackToggleFavoritesButton trackid={track.id} isfavorite={track.isFavorite || false}/>}
                {actions?.cart && <TrackToggleCartButton trackid={track.id} incart={track.inCart || false}/>}
                {actions?.withdraw && <TrackTableWithdrawTrack status={track.status} trackId={track.id}/>}
                {actions?.certificate && <TrackCertificateButton track={track}/>}
            </div>
        );
    };
}
