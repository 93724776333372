import React from 'react';
import { RegularPageTemplate } from '../RegularPageTemplate';

const ErrorOrder: React.FC = () => {
    return (
        <RegularPageTemplate style={{ padding: 0, paddingBottom: 20 }}>
            <div style={{ textAlign: 'center', padding: '20px' }}>
                <h1>Ошибка при оплате</h1>
                <p>Произошла ошибка при обработке вашего платежа. Пожалуйста, попробуйте снова.</p>
            </div>
        </RegularPageTemplate>
    );
};

export default ErrorOrder;