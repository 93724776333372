import React, { Dispatch, SetStateAction, useState } from 'react';

const defaultState = {
  player: new Audio(),
  currentTrack: {
    name: '',
    src: '',
  },
  playList: [],
  isPlaying: false
};

const AudioPlayerContext = React.createContext<[AudioPlayerState, Dispatch<SetStateAction<AudioPlayerState>>]>([
  defaultState,
  (): void => {},
]);

export type AudioPlayerTrack = {
  name: string;
  src: string;
};

export type AudioPlayerState = {
  player: HTMLAudioElement;
  currentTrack: AudioPlayerTrack;
  playList: AudioPlayerTrack[];
  isPlaying: boolean;
};

const AudioPlayerProvider = (props: { children: React.ReactElement }): React.ReactElement => {
  const [state, setState] = useState<AudioPlayerState>(defaultState);
  return <AudioPlayerContext.Provider value={[state, setState]}>{props.children}</AudioPlayerContext.Provider>;
};

export { AudioPlayerContext, AudioPlayerProvider };
