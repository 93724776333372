// @ts-nocheck
import React, { useCallback } from 'react';
import { UploadSamplesList } from './UploadSamplesList';
import { message, Upload } from 'antd';
import { ROUTES } from '../../routes';
import { UploadProps } from 'antd/es/upload';
import { UploadSampleButton } from './UploadSampleButton';
import { UploadSample, UploadMainSample } from './track.interfaces';

import { PayloadStatuses } from '../../types';

interface SamplesFieldProps {
  samples?: UploadSample[];
  onChange?(samples: UploadSample[]): void;
}

const styles = require('./SamplesField-styles.module.css');

export const SamplesField = (props: SamplesFieldProps): JSX.Element => {
  const handleUploadChange = useCallback(
    ({ file, fileList }): void => {
        console.log(fileList)
      props.onChange && props.onChange([...fileList]);
      if (file.status === 'done' && file.response?.result === PayloadStatuses.SUCCESS) {
        props.onChange && props.onChange(fileList.map(f => (f.uid === file.uid ? { ...f, id: file.response.id } : f)));
        message.success(`Семпл ${file.name} успешно загружен!`);
      } else if (file.status === 'error') {
        message.error(`Произошла ошибка при загрузке ${file.name}!`);
      }
    },
    [props],
  );

  return (
    <div>
        {props && props.samples && Array.isArray(props.samples) && (
        <UploadSamplesList
            samples={props.samples.slice(1)}
            allSamples = {props.samples}
          onRemove={(s): void => {
              console.log( "SamplesField UploadSamplesList", props.samples, "s", s )
            props.samples && props.onChange && props.onChange(props.samples.filter(sample => sample.id !== s.id));
            message.success(`Семпл ${s.name} успешно удалён`);
          }}
          onChange={(allSamples): void => {
              console.log("SAMPLE DESCR CHANGED")
            props.onChange && props.onChange([...allSamples]);
          }}
        />
      )}

      <Upload
        {...({
          name: 'file',
          multiple: true,
          action: ROUTES.CABINET.COMPOSER.UPLOAD_SAMPLE,
          className: styles.sampleUploadField,
          showUploadList: false,
          accept: '.wav,audio/mp3',
          listType: 'picture',
          fileList: props.samples,
          withCredentials: true,
          onChange: handleUploadChange,
        } as UploadProps)}
      >
        <UploadSampleButton />
      </Upload>
    </div>
  );
};
