// @ts-nocheck
import React, {useEffect, useState} from 'react';
import { Layout, Menu } from 'antd';
import { ShopOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { RouteChildrenProps } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { CabinetSideMenuGetAccountQuery } from '../../__generated__/CabinetSideMenuGetAccountQuery';
import { GET_ACCOUNT_QUERY } from '../../graphql/common.queries';


export const CabinetSideMenu = withRouter(
  (props: RouteChildrenProps): JSX.Element => {
    const { data } = useQuery<CabinetSideMenuGetAccountQuery>(GET_ACCOUNT_QUERY, {
        fetchPolicy: "no-cache"
    });

    const menuKeys = {
      requisitesSubMenu: 'requisites',
      buyerMenu: 'buyer',
      composerMenu: 'composer',
      adminSubMenu: 'admin',
      adminSettingsSubMenu: 'adminSettings',
    };
      const [state, setState] = useState([])

      useEffect(() => {
          if (data) {
              setState(data)
          }
      }, [data])


    return (
      <Layout.Sider theme="light">
        <Menu
          mode="inline"
          defaultSelectedKeys={['1']}
          selectedKeys={[props.location.pathname]}
          defaultOpenKeys={[
            //   menuKeys.requisitesSubMenu,
            menuKeys.buyerMenu,
            menuKeys.adminSubMenu,
            menuKeys.adminSettingsSubMenu,
          ]}
        >
          {/*<Menu.Item key={ROUTES.CABINET.MAIN}>
            <span>Главная</span>
            <Link to={ROUTES.CABINET.MAIN} />
          </Menu.Item>*/}
          <Menu.Item key={ROUTES.CABINET.PROFILE}>
            <span>Настройки</span>
            <Link to={ROUTES.CABINET.PROFILE} />
          </Menu.Item>
            <Menu.Item key={ROUTES.CABINET.ORDERS}>
                <span>Мои покупки</span>
                <Link to={ROUTES.CABINET.ORDERS}/>
            </Menu.Item>

          {/*{data?.client?.account && (*/}

          {/*)}*/}

          {state?.client?.account?.composer?.onboardingFinished && (
            <>
              <Menu.Item key={ROUTES.CABINET.COMPOSER.NEW_TRACK}>
                Загрузить трек
                <Link to={ROUTES.CABINET.COMPOSER.NEW_TRACK} />
              </Menu.Item>
              <Menu.Item key={ROUTES.CABINET.COMPOSER.TRACKS.LIST}>
                Мои треки
                <Link to={ROUTES.CABINET.COMPOSER.TRACKS.LIST} />
              </Menu.Item>
              <Menu.Item key={ROUTES.CABINET.COMPOSER.SALES}>
                Мои продажи
                <Link to={ROUTES.CABINET.COMPOSER.SALES} />
              </Menu.Item>
              <Menu.Item key={ROUTES.CABINET.COMPOSER.WITHDRAW.MAIN}>
                Привязка карты
                <Link to={ROUTES.CABINET.COMPOSER.WITHDRAW.MAIN} />
              </Menu.Item>
              {/*<Menu.SubMenu key={menuKeys.requisitesSubMenu} title="Профиль">*/}
                <Menu.Item key={ROUTES.CABINET.COMPOSER.ABOUT}>
                  О себе
                  <Link to={ROUTES.CABINET.COMPOSER.ABOUT} />
                </Menu.Item>
                {/*<Menu.Item key={ROUTES.CABINET.COMPOSER.PASSPORT}>*/}
                {/*  Паспорт*/}
                {/*  <Link to={ROUTES.CABINET.COMPOSER.PASSPORT} />*/}
                {/*</Menu.Item>*/}
              {/*</Menu.SubMenu>*/}
            </>
          )}
          {state?.client?.account?.isAdmin && (
            <Menu.SubMenu
              key={menuKeys.adminSubMenu}
              title={
                <span>
                  <SettingOutlined />
                  <span>Админ</span>
                  <Link to={ROUTES.CABINET.ADMIN.MAIN} />
                </span>
              }
            >
              <Menu.Item key={ROUTES.CABINET.ADMIN.TRACKS.LIST}>
                Все треки
                <Link to={ROUTES.CABINET.ADMIN.TRACKS.LIST} />
              </Menu.Item>
                <Menu.Item key={ROUTES.CABINET.ADMIN.TRACKS.NEW}>
                    Новые треки
                    <Link to={ROUTES.CABINET.ADMIN.TRACKS.NEW} />
                </Menu.Item>
              <Menu.Item key={ROUTES.CABINET.ADMIN.ACCOUNTS.MAIN}>
                Пользователи
                <Link to={ROUTES.CABINET.ADMIN.ACCOUNTS.MAIN} />
              </Menu.Item>
              <Menu.SubMenu key={menuKeys.adminSettingsSubMenu} title="Настройки">
                <Menu.Item key={ROUTES.CABINET.ADMIN.GENRES}>
                  Жанры
                  <Link to={ROUTES.CABINET.ADMIN.GENRES} />
                </Menu.Item>
                <Menu.Item key={ROUTES.CABINET.ADMIN.INSTRUMENTS}>
                  Инструменты
                  <Link to={ROUTES.CABINET.ADMIN.INSTRUMENTS} />
                </Menu.Item>
                <Menu.Item key={ROUTES.CABINET.ADMIN.DIRECTIONS}>
                  Направления
                  <Link to={ROUTES.CABINET.ADMIN.DIRECTIONS} />
                </Menu.Item>
                <Menu.Item key={ROUTES.CABINET.ADMIN.MOODS}>
                  Настроения
                  <Link to={ROUTES.CABINET.ADMIN.MOODS} />
                </Menu.Item>
                <Menu.Item key={ROUTES.CABINET.ADMIN.PUBLISHERS}>
                  Издатели
                  <Link to={ROUTES.CABINET.ADMIN.PUBLISHERS} />
                </Menu.Item>
                <Menu.Item key={ROUTES.CABINET.ADMIN.CAROUSEL}>
                  Карусель
                  <Link to={ROUTES.CABINET.ADMIN.CAROUSEL} />
                </Menu.Item>
                <Menu.Item key={ROUTES.CABINET.ADMIN.FAQ}>
                  FAQ
                  <Link to={ROUTES.CABINET.ADMIN.FAQ} />
                </Menu.Item>
                <Menu.Item key={ROUTES.CABINET.ADMIN.AGREEMENT}>
                    Соглашение
                  <Link to={ROUTES.CABINET.ADMIN.AGREEMENT} />
                </Menu.Item>
                <Menu.Item key={ROUTES.CABINET.ADMIN.PRIVACY_POLICY}>
                    Политика конфиденциальности
                    <Link to={ROUTES.CABINET.ADMIN.PRIVACY_POLICY} />
                </Menu.Item>
                <Menu.Item key={ROUTES.CABINET.ADMIN.BECOME_COMPOSER_INFO}>
                    Как стать композитором
                  <Link to={ROUTES.CABINET.ADMIN.BECOME_COMPOSER_INFO} />
                </Menu.Item>
              </Menu.SubMenu>
            </Menu.SubMenu>
          )}
        </Menu>
      </Layout.Sider>
    );
  },
);
