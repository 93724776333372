import React from 'react';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routes';

export const AccountTableEditButton = (props: { accountId: string }): JSX.Element => {
  return (
    <Link to={ROUTES.CABINET.ADMIN.ACCOUNTS.EDIT.replace(':id', props.accountId)}>
      <Button icon={<EditOutlined />} size={'small'} shape={'circle'} />
    </Link>
  );
};
