// @ts-nocheck
import React, { useEffect } from 'react';
import { CabinetPageTemplate } from '../cabinet/CabinetPageTemplate';
import { useQuery } from '@apollo/react-hooks';
import { useAudioPlayer } from '../audioplayer/useAudioPlayer';
import gql from 'graphql-tag';
import { AdminTracksPageTableFindAllQuery } from '../../__generated__/AdminTracksPageTableFindAllQuery';
import { getSampleSrc } from '../../utils';
import { TrackTable } from './table/TrackTable';
import { TrackTableRowColumns } from './table/TrackTableRow';
//import { ADMIN_TRACKS_PAGE_TABLE_FIND_ALL_QUERY } from '../../graphql/admin.queries';
import { ADMIN_NEW_TRACKS_PAGE_TABLE_FIND_ALL_QUERY } from '../../graphql/admin.queries';

export function AdminTracksTablePage(): JSX.Element {
  const { setPlayList, playList } = useAudioPlayer();
  const { loading, data } = useQuery(ADMIN_NEW_TRACKS_PAGE_TABLE_FIND_ALL_QUERY, {
    variables: {
      filter: {
      },
    },
  });

  const tracks = data?.admin?.track?.find || [];
  //console.log("AdminTracksTablePage tracek: "+tracks);

  useEffect(() => {
    const newPlayList = tracks
      ? tracks.map(track => ({ name: track.name, src: getSampleSrc(track.mainSampleId) }))
      : [];
    if (JSON.stringify(newPlayList) !== JSON.stringify(playList)) {
      setPlayList(newPlayList);
    }
  }, [playList, setPlayList, tracks]);

  const dataSource =
    tracks &&
    tracks.map(tracks => {
      return { ...tracks, key: tracks.id };
    });

  const columns: TrackTableRowColumns = {
    article: true,
    createdAt: true,
    status: true,
    publisher: true,
    duration: true,
    bpm: true,
    price: true,
    actions: {
      downloadFiles: true,
      deleteFiles: true,
      adminEdit: true,
    },
  };

  return (
    <CabinetPageTemplate>
      <TrackTable loading={loading} tracks={dataSource} cols={columns} />
    </CabinetPageTemplate>
  );
}
