// @ts-nocheck
import React from 'react';
import { CabinetPageTemplate } from '../cabinet/CabinetPageTemplate';
import { Spinner } from '../Spinner';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { TableTrack, TrackTable } from '../track/table/TrackTable';
import { ComposerSalesFindQuery } from '../../__generated__/ComposerSalesFindQuery';
import { TrackTableRowColumns } from '../track/table/TrackTableRow';
import { Result } from 'antd';

const COMPOSER_SALES_FIND_QUERY = gql`
  query ComposerSalesFindQuery {
    client {
      composer {
        sales {
          id
          price
          state
          createdAt
          track {
            id
            name
            authorName
            article
            mainSampleId
            samples {
              id
              name
              duration
            }
          }
        }
      }
    }
  }
`;

export function ComposerSalesPage(): JSX.Element {
  const { data, loading } = useQuery<ComposerSalesFindQuery>(COMPOSER_SALES_FIND_QUERY);

  const tracks =
    data?.client?.composer?.sales.map(
      (order): TableTrack => ({
        ...order.track,
        saleDate: order.createdAt,
        price: order.price,
        orderNumber: order.id,
      }),
    ) || [];

  const columns: TrackTableRowColumns = {
    saleDate: true,
    orderNumber: true,
    article: true,
    price: true,
  };

  return (
    <CabinetPageTemplate>
      {loading ? (
        <Spinner />
      ) : tracks.length ? (
        <TrackTable tracks={tracks} cols={columns} />
      ) : (
        <Result status={'warning'} title={`У Вас пока нет продаж`} />
      )}
    </CabinetPageTemplate>
  );
}
