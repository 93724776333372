import React from 'react';
import { Button } from 'antd';

export function ActionEditButton(props: {
  editing: boolean;
  onEditClick(): void;
  onCancelClick(): void;
  onSaveClick(): void;
  saveLoading?: boolean;
}): JSX.Element {
  return props.editing ? (
    <Button.Group>
      <Button onClick={props.onCancelClick} size={'small'}>
        Cancel
      </Button>
      <Button size={'small'} loading={props.saveLoading} onClick={props.onSaveClick}>
        Save
      </Button>
    </Button.Group>
  ) : (
    <Button shape={'circle'} icon={'edit'} onClick={props.onEditClick} size={'small'} />
  );
}
