import React, {useState, useEffect} from 'react';
import {useMutation, useQuery, useLazyQuery} from '@apollo/react-hooks';
import {DateTimeFormat} from '../../config';
import {format} from 'date-fns';
import {CONFIG} from '../../config';
import {DIRECTION_DELETE_MUTATION, DIRECTION_PATCH_MUTATION} from './direction.graphql';

import {ActionEditButton} from '../table/ActionEditButton';
import {EditableTableCell} from '../table/EditableTableCell';
import {Form} from '@ant-design/compatible';
import {FormComponentProps} from '@ant-design/compatible/es/form';
import {sortAsc} from '../../utils';
import gql from 'graphql-tag';
import {DeleteOutlined} from '@ant-design/icons';
import {Table, Button, Popconfirm, message, Checkbox,} from 'antd';
import {ExecutionResult} from 'graphql';
//import {PatchDirectionMutation} from '../../__generated__/PatchDirectionMutation';
import {DirectionPatchMutation} from '../../__generated__/DirectionPatchMutation';
import {DirectionDeleteMutation} from '../../__generated__/DirectionDeleteMutation';
import {DirectionsTableDirectionsFindAllQuery} from '../../__generated__/DirectionsTableDirectionsFindAllQuery';
import axios from "axios";
import {ROUTES} from "../../routes";
import {CheckboxChangeEvent} from "antd/es/checkbox";

const {Item} = Form;
const styles = require('./DirectionsTable-styles.module.css');

export const DIRECTIONS_TABLE_DIRACTIONS_FIND_ALL_QUERY = gql`
  query DirectionsTableDirectionsFindAllQuery {
    common {
      directions {
        find {
          id
          active
          name
          sort
          imgUrl
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const DirectionsTable = (props: FormComponentProps): JSX.Element => {
    const [editing, setEditing] = useState<string | undefined>();
    const [editingKey, setEditingKey] = useState();
    const [currentRowValues, setCurrentRowValues] = useState([]);
    const [rowList, setRowList] = useState<any>();
    const [directions, setDirections] = useState<any>();
    const [file, setFile] = useState<any>(null);
    const [active, setActive] = useState<any>(false);
    const {loading, data} = useQuery<DirectionsTableDirectionsFindAllQuery>(DIRECTIONS_TABLE_DIRACTIONS_FIND_ALL_QUERY,
        {
            onCompleted: data => {
            }
        });
    //const directions = data?.common?.directions?.find || [];

    const [deleteGroup, {loading: deleteLoading}] = useMutation<DirectionDeleteMutation>(DIRECTION_DELETE_MUTATION, {
        refetchQueries: [{query: DIRECTIONS_TABLE_DIRACTIONS_FIND_ALL_QUERY}],
        onCompleted: (): void => {
            message.success('Направление успешно удалёно');
        },
    });


    useEffect(() => {
        if (data) {
            console.log("fetch data ");
            setDirections([...data?.common?.directions?.find]);
        }
    }, [data?.common?.directions?.find]);

    /*	useEffect(() => {
            if (refetchNeeded) {
                console.log("refetch Data ");
                setRefetchNeeded(false);
                refetchDirections();
                //setDirections([...refetchData?.common?.directions?.find]);
            }
        }, [refetchNeeded]);*/

    /*	useEffect(() => {
            if (refetchData) {
                console.log("refetch data! ", refetchData?.common?.directions?.find);
                setDirections([...refetchData?.common?.directions?.find])
            }
        }, [refetchData?.common?.directions?.find]);*/

    /*  useEffect(() => {

      }, [data?.common?.directions?.find]);*/


    /*  const [patchDirection, { loading: patchLoading }] = useMutation<DirectionPatchMutation>(DIRECTION_PATCH_MUTATION, {
          refetchQueries: [{ query: DIRECTIONS_TABLE_DIRACTIONS_FIND_ALL_QUERY }],
          onCompleted: (): void => {
            message.success(`Направление успешно обновлено`);
            setEditing(undefined);
          },
        });*/

    const patchDirection = (record, values) => {
        let data = new FormData();

        data.append("direction", JSON.stringify({
            "sort": values.sort,
            "id": record.id,
            "name": values.name,
            "active": values.active,
        }));

        if (file) data.append("file", file, file.name)
        // todo хотела сделать красиво через GraphQL lazyQuery, но пока axios
        axios.put(ROUTES.CABINET.ADMIN.DIRECTIONS_UPLOAD_IMAGE,
            data, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
        ).then((res) => {
            setFile(null);
            message.success('Сохранено');
            //setRefetchNeeded(true)
            console.log("res.data ", res.data);
            setDirections([...res.data?.directions])
            setEditing(undefined);
        }).catch((e) => {
            console.log("error ", e);
            message.error('Произошла ошибка, попробуйте еще раз');
        });
    };


    /*  const saveDirection = (data) => {

      };*/

    const {form} = props;
    const {resetFields} = form;

    const isRowEditing = (id: string): boolean => {
        return editing === id;
    };

    const editRow = (rowKey) => {
        setEditingKey(rowKey);
        setCurrentRowValues({...rowList.find((row) => row.key === rowKey)});
    };

    const handleSaveDirection = (record): any => {
        props.form.validateFields((errors, values): any => {
            if (!errors) return patchDirection(record, values);
        });
    };

    const handleDeleteGroup = (id: string): Promise<ExecutionResult> => {
        return deleteGroup({variables: {id}});
    };

    const dataSource = directions ? directions.map(direction => ({
        ...direction,
        key: direction.id
    })).sort(sortAsc('sort')) : [];

    const ActionCell = (text, record): JSX.Element => {
        const isThisRowEditing = isRowEditing(record.id);
        return (
            <div className={styles.actionsCell}>
                <ActionEditButton
                    editing={isThisRowEditing}
                    onEditClick={(): void => {
                        editing && resetFields();
                        setEditing(record.id);
                    }}
                    onCancelClick={(): void => setEditing(undefined)}
                    onSaveClick={() => handleSaveDirection(record)}
                    /*saveLoading={patchLoading}*/
                />
                <Popconfirm
                    title="Удалить направление?"
                    onConfirm={(): Promise<ExecutionResult> => handleDeleteGroup(record.id)}
                >
                    <Button icon={<DeleteOutlined/>} shape={'circle'} size={'small'} loading={deleteLoading}/>
                </Popconfirm>
            </div>
        );
    };

    const ImageCell = (text, record): JSX.Element => {
        const handleFileChange = (e) => {
            if (!e.target.files) return
            setFile(e.target.files[0])
        };

        const isThisRowEditing = isRowEditing(record.id);
        return (!isThisRowEditing ? <img style={{maxHeight: 50}} src={CONFIG.HOST + '/' + record.imgUrl}/>
                : <input onChange={handleFileChange} type="file" accept="image/*" name="" id=""/>
        )
    };

    const ActiveCell = (text, record): JSX.Element => {
        const getFieldDecorator = props.form.getFieldDecorator
        const isThisRowEditing = isRowEditing(record.id);
        const index = directions.findIndex((el) => el.id === record.id);
        return <Item>{getFieldDecorator('active', {
            initialValue: directions[index]['active'],
        })(<Checkbox
            disabled={!isThisRowEditing}
            style={{display: "inline"}}
            checked={directions[index]['active']}
            onChange={(event: CheckboxChangeEvent): void => {
                const index = directions.findIndex((el) => el.id === editing);
                const newCheckboxState = [...directions];
                newCheckboxState[index]['active'] = event.target.checked;
                setDirections(newCheckboxState);
                console.log("directions[index]['active']", index, record.id, directions[index]['active']);
            }}
        />)}
        </Item>
    };

    const columns = [
        {
            title: 'Активность',
            dataIndex: 'active',
            render: ActiveCell,
        },
        {
            title: 'Сортировка',
            dataIndex: 'sort',
            editable: true,
            sorter: (a, b) => a.sort - b.sort
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdAt',
            render: (text, direction): string => format(new Date(direction.createdAt), DateTimeFormat),
            sorter: (a, b) => Number(new Date(a.createdAt)) - Number(new Date(b.createdAt))
        },
        {
            title: 'Направление',
            dataIndex: 'name',
            editable: true,
        },
        {
            title: 'Изображение',
            dataIndex: 'imgUrl',
            inputType: 'file',
            render: ImageCell,
            //render: (text, direction): string => format(new Date(direction.createdAt), DateTimeFormat),
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            render: ActionCell,
        },
    ].map(col =>
        col.editable
            ? {
                ...col,
                onCell: (record): object => ({
                    name: col.dataIndex,
                    title: col.title,
                    editing: isRowEditing(record.id),
                    record,
                    getFieldDecorator: props.form.getFieldDecorator,
                }),
            }
            : col,
    );

    const components = {
        body: {
            cell: EditableTableCell,
        },
    };

    return (
        <Table
            components={components}
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            pagination={{pageSize: 50}}
        />
    );
};

export default Form.create<FormComponentProps>()(DirectionsTable);
