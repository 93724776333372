// @ts-nocheck
import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import {Typography} from 'antd';
import gql from 'graphql-tag';
import {RegularPageTemplate} from '../RegularPageTemplate';
import {TrackTable} from '../track/table/TrackTable';
import {ClientFavoriteTracksFindQuery} from '../../__generated__/ClientFavoriteTracksFindQuery';
import {FavoritesEmptyText} from './FavoritesEmptyText';
import {TrackTableRowColumns} from "../track/table/TrackTableRow";
import {TrackTableExpandedRowContent} from "../track/table/TrackTableExpandedRowContent";

export const CLIENT_FAVORITE_TRACKS_FIND_QUERY = gql`
  query ClientFavoriteTracksFindQuery {
    client {
      favorite {
        find {
          id
          name
          price
          bpm
          publisher {
            name
          }
          description
          authorName
          mainSampleId
          isFavorite
          inCart
          sameTracksCount
              getSameTracks {
		        name
		        id
		        price
                bpm
		        authorName
		        isFavorite
		        inCart
		        samples {
		          id
		          name
		          description
		          duration
		        }
		    }
          genres {
            name
          }
          instruments {
            name
          }
          moods {
            name
          }
          samples {
            id
            name
            description
            duration
          }
        }
      }
    }
  }
`;

export const ClientFavoriteTracksPage = (): JSX.Element => {
	const {loading, data} = useQuery<ClientFavoriteTracksFindQuery>(CLIENT_FAVORITE_TRACKS_FIND_QUERY);

	const tracks = data?.client?.favorite?.find || [];

	return (
		<RegularPageTemplate>
			<Typography.Title level={2}>Избранные треки</Typography.Title>
			{tracks.length ? <TrackTable loading={loading} tracks={tracks}
			                             expandable={{
				                             expandedRowRender: TrackTableExpandedRowContent,
				                             expandRowByClick: true,
			                             }}
			                             cols={{
				                             sameTrack: true,
				                             duration: true,
				                             bpm: true,
				                             price: true,
				                             actions: {
					                             demo: true,
					                             favorite: true,
					                             cart: true,
				                             },
			                             }}
			/> : <FavoritesEmptyText/>}
		</RegularPageTemplate>
	);
};
