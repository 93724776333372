import gql from "graphql-tag";

export const COMPOSER_PASSPORT_QUERY = gql`
  query composerPassportPageQuery {
    client {
      account {
        passport {
          id
          firstName
          secondName
          thirdName
          series
          number
          registrationAddress
        }
        inn {
          id
          number
        }
        composer {
          id
          nickname
          showNickname
        }
        email
      }
    }
  }
` 

export const GET_PROFILE_QUERY = gql`
  query GetProfileQuery {
    client {
      profile {
        id
        login
        account {
          mobileNumber
          vk
          facebook
          email
        }
        passport {
          firstName
          secondName
          thirdName
          series
          number
          registrationAddress
        }
      }
    }
  }
`;

export const COMPOSER_ONBOARDING_ABOUT_STEP_QUERY = gql`
  query CompmoserOnboardingAboutStepQuery {
    client {
      profile {
        composer {
          nickname
          howlong
          instruments
          styles
        }
      }
    }
  }
`;

export const COMPOSER_ONBOARDING_DOCUMENTS_STEP_QUERY = gql`
  query CompmoserOnboardingDocumentsStepQuery {
    client {
      account {
        mobileNumber
        passport {
          firstName
          secondName
          thirdName
          series
          number
          registrationAddress
        }
        inn {
          number
        }
      }
    }
  }
`;

export const ACCOUNT_INN_QUERY = gql`
  query AccountINNQuery {
    client {
      account {
        inn {
          id
          number
        }
      }
    }
  }
`;
