import { useContext } from 'react';
import { AudioPlayerContext, AudioPlayerTrack } from './AudioPlayerContext';

type UseAudioPlayer = {
  player: HTMLAudioElement;
  play(track: AudioPlayerTrack);
  togglePlay();
  currentTrack: AudioPlayerTrack;
  setPlayList(playList: AudioPlayerTrack[]);
  playList: AudioPlayerTrack[];
  trackName: string;
  prev(): void;
  next(): void;
};

export const useAudioPlayer = (): UseAudioPlayer => {
  const [state, setState] = useContext(AudioPlayerContext);

  const togglePlay = async (): Promise<void> => {
    if (!state.player.paused) {
      state.player.pause();
      setState({ ...state, isPlaying: false });
    } else {
      await state.player.play();
      setState({ ...state, isPlaying: true });
    }
  };

  const play = async (track?: AudioPlayerTrack): Promise<void> => {
    if (!track) {
      return setState({ ...state, isPlaying: false, currentTrack: { name: '', src: '' } });
    }

    if (track.src === state.player.src) {
      if (state.player.paused) {
        await state.player.play();
        setState({ ...state, currentTrack: track, isPlaying: true });
      } else {
        state.player.pause();
        setState({ ...state, currentTrack: track, isPlaying: false });
      }
    } else {
      state.player.pause();
      state.player.src = track.src;
      await state.player.play();
      setState({ ...state, currentTrack: track });
    }
  };

  const setPlayList = (playList: AudioPlayerTrack[]): void => {
    if (JSON.stringify(playList) !== JSON.stringify(state.playList)) setState({ ...state, playList });
  };

  const prev = (): void => {
    if (state.playList && state.playList.length) {
      const currentIndex = state.playList.findIndex(track => track.src === state.currentTrack.src);
      currentIndex && play(state.playList[currentIndex - 1]);
    }
  };

  const next = (): void => {
    if (state.playList && state.playList.length) {
      const currentIndex = state.playList.findIndex(track => track.src === state.currentTrack.src);
      state.playList.length > currentIndex + 1 && play(state.playList[currentIndex + 1]);
    }
  };

  return {
    player: state.player,
    currentTrack: state.currentTrack,
    playList: state.playList,
    trackName: state.currentTrack.name,
    play,
    togglePlay,
    setPlayList,
    prev,
    next,
  };
};
