import React from 'react';
import { Button, message, Popconfirm, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { ExecutionResult } from 'graphql';
import { TRACK_STATUS } from '../../track.interfaces';
import { ComposerTrackReturnMutation } from '../../../../__generated__/ComposerTrackReturnMutation';

const COMPOSER_TRACK_RETURN_MUTATION = gql`
  mutation ComposerTrackReturnMutation($id: String!) {
    client {
      composer {
        track {
          return(id: $id) {
            id
            status
          }
        }
      }
    }
  }
`;

export const TrackTableReturnTrackButton = (props: { status?: number; trackId: string }): JSX.Element => {
  const [returnTrack, { loading }] = useMutation<ComposerTrackReturnMutation>(COMPOSER_TRACK_RETURN_MUTATION, {
    onCompleted: data => {
      if (data.client?.composer?.track?.return.status === TRACK_STATUS.REJECTED.value) {
        message.success('Трек на модерации');
      }
    },
  });

  const text = 'Вы уверены, что хотите вернуть трек?';

  function handleConfirm(): Promise<ExecutionResult> {
    return returnTrack({
      variables: {
        id: props.trackId,
      },
    });
  }

  return (
    <Popconfirm title={text} onConfirm={handleConfirm}>
      <Tooltip title="Вернуть трек" placement={'left'}>
        <Button size={'small'} shape={'circle'} loading={loading}>
          <ReloadOutlined style={{ color: 'green' }} />
        </Button>
      </Tooltip>
    </Popconfirm>
  );
};
