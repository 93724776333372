import React from 'react';
import { Input } from 'antd';
import { Form } from '@ant-design/compatible';

interface InputINNProps {
  getFieldDecorator(name: string, options: object);
  name: string;
  initialValue?: string;
}

export function InputINN(props: InputINNProps): JSX.Element {
  return (
    <Form.Item label="ИНН">
      {props.getFieldDecorator(props.name, {
        initialValue: props.initialValue || '',
        rules: [{ required: true, message: 'Пожалуйста, введите ИНН!' }],
      })(<Input placeholder={'035651518571'} />)}
    </Form.Item>
  );
}
