import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { DateTimeFormat } from '../../config';
import { format } from 'date-fns';

import { ActionEditButton } from '../table/ActionEditButton';
import { EditableTableCell } from '../table/EditableTableCell';
import { Mood } from './index';
import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { sortByDate } from '../../utils';
import { Popconfirm, Button, message, Table } from 'antd';
import { ExecutionResult } from 'graphql';
import gql from 'graphql-tag';
import { MoodsTableMoodsFindAllQuery } from '../../__generated__/MoodsTableMoodsFindAllQuery';
import { MoodsDeleteMutation } from '../../__generated__/MoodsDeleteMutation';
import { MoodsPatchMutation } from '../../__generated__/MoodsPatchMutation';

const styles = require('./MoodsTable-styles.module.css');

export const MOODS_TABLE_MOODS_FIND_ALL_QUERY = gql`
  query MoodsTableMoodsFindAllQuery {
    common {
      moods {
        find {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const MOODS_PATCH_MUTATION = gql`
  mutation MoodsPatchMutation($input: PatchMoodInput) {
    admin {
      mood {
        patch(input: $input) {
          status
          group {
            name
          }
        }
      }
    }
  }
`;

const MOODS_DELETE_MUTATION = gql`
  mutation MoodsDeleteMutation($id: String!) {
    admin {
      mood {
        delete(id: $id) {
          status
        }
      }
    }
  }
`;

export const MoodsTable = (props: FormComponentProps): JSX.Element => {
  const [editing, setEditing] = useState<string | undefined>();
  const { loading, data } = useQuery<MoodsTableMoodsFindAllQuery>(MOODS_TABLE_MOODS_FIND_ALL_QUERY);
  const [deleteGroup, { loading: deleteLoading }] = useMutation<MoodsDeleteMutation>(MOODS_DELETE_MUTATION, {
    refetchQueries: [{ query: MOODS_TABLE_MOODS_FIND_ALL_QUERY }],
    onCompleted: () => {
      message.success('Настроение успешно удалёно');
    },
  });
  const [patchMood, { loading: patchLoading }] = useMutation<MoodsPatchMutation>(MOODS_PATCH_MUTATION, {
    refetchQueries: [{ query: MOODS_TABLE_MOODS_FIND_ALL_QUERY }],
    onCompleted: () => {
      message.success(`Настроение успешно обновлено`);
      setEditing(undefined);
    },
  });

  const { form } = props;
  const { resetFields } = form;

  const moods = data?.common?.moods?.find || [];

  const isRowEditing = (id: string): boolean => {
    return editing === id;
  };

  const handleSaveMood = (): void => {
    props.form.validateFields((errors, values) => {
      if (!errors) {
        return patchMood({ variables: { input: { ...values, id: editing } } });
      }
    });
  };

  const handleDeleteMood = (id: string): Promise<ExecutionResult> => {
    return deleteGroup({ variables: { id } });
  };

  const dataSource = moods.map(mood => ({ ...mood, key: mood.id })).sort(sortByDate('createdAt'));

  const ActionCell = (text, record): JSX.Element => {
    const isThisRowEditing = isRowEditing(record.id);
    return (
      <div className={styles.actionsCell}>
        <ActionEditButton
          editing={isThisRowEditing}
          onEditClick={(): void => {
            editing && resetFields();
            setEditing(record.id);
          }}
          onCancelClick={(): void => setEditing(undefined)}
          onSaveClick={handleSaveMood}
          saveLoading={patchLoading}
        />
        <Popconfirm title="Удалить?" onConfirm={(): Promise<ExecutionResult> => handleDeleteMood(record.id)}>
          <Button icon={'delete'} shape={'circle'} size={'small'} loading={deleteLoading} />
        </Popconfirm>
      </div>
    );
  };

  const columns = [
    {
      title: 'Настроение',
      dataIndex: 'name',
      editable: true,
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      render: (text, mood): string => format(new Date(mood.createdAt), DateTimeFormat),
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      render: ActionCell,
    },
  ].map(col =>
    col.editable
      ? {
          ...col,
          onCell: (record: Mood): object => ({
            name: col.dataIndex,
            title: col.title,
            editing: isRowEditing(record.id),
            record,
            getFieldDecorator: props.form.getFieldDecorator,
          }),
        }
      : col,
  );

  const components = {
    body: {
      cell: EditableTableCell,
    },
  };

  return (
    <Table
      components={components}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      pagination={{ pageSize: 50 }}
    />
  );
};

export default Form.create<FormComponentProps>()(MoodsTable);
