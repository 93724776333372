import React from 'react';

import { HeaderIconBlock } from './HeaderIconBlock';
import { MobileNav } from './HeaderNav';
import cx from 'classnames';
import {HeaderTopBar} from "./HeaderTopBar";

const styles = require('./HeaderMasterhead-styles.module.css');

const Logo = (): JSX.Element => (
  <a href="/" title="Bmstock" rel="home" className={styles.logoLink}>
    <img src="/img/MW_Logo_long.png" className={styles.logoImg} alt="Bmstock" />
  </a>
);

export const HeaderMasterhead = (): JSX.Element => {
  return (
    <div className={cx('flex-row d-flex justify-content-between container', styles.wrapper)} role="navigation">
      <div className={styles.logo}>
        <Logo />
      </div>

      <div className={cx('d-none d-md-block', styles.searchBlock)}>
        <ul className={styles.ul}>
          <li className={styles.li}>{/*<Search />*/}</li>
        </ul>
      </div>

      <div className={cx('d-md-none', styles.mobileMenu)}>
        <MobileNav />
      </div>

      <div className="d-none d-md-flex">
        <HeaderIconBlock />
         <HeaderTopBar />
      </div>


      {/*<div className={cx('d-md-none', styles.mobileIconBlockWrapper)}>*/}
      {/*  <HeaderIconBlockMobile />*/}
      {/*</div>*/}
    </div>
  );
};
