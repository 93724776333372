import React from 'react';
import { CabinetPageTemplate } from '../../../cabinet/CabinetPageTemplate';
import ComposerOnboardingWizard from '../../../cabinet/composer/onboarding/ComposerOnboardingWizard';

export const BecomeAComposerPage = (): JSX.Element => {
  return (
    <CabinetPageTemplate>
      <ComposerOnboardingWizard />
    </CabinetPageTemplate>
  );
};
