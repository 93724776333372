import React from 'react';
import { CabinetPageTemplate } from '../../../cabinet/CabinetPageTemplate';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Form } from '@ant-design/compatible';
import { message, Spin, Button } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { ACCOUNT_INN_QUERY, PATCH_INN_MUTATION } from '../../../../graphql';

import { formItemLayout } from '../../../form/options';
import { InputINN } from '../../../form/InputINN';

const styles = require('./ComposerINNPage-styles.module.css');

export const ComposerINNPage = (): JSX.Element => {
  const { data: innData, loading: innLoading } = useQuery(ACCOUNT_INN_QUERY);
  const [saveINN, { loading: innMutationLoading }] = useMutation(PATCH_INN_MUTATION, {
    refetchQueries: [{ query: ACCOUNT_INN_QUERY }],
    onCompleted: () => {
      {
        message.success('ИНН успешно сохранен!');
      }
    },
  });

  const inn = (innData && innData.client && innData.client.account.inn) || {};

  const InnForm = Form.create<FormComponentProps>({ name: 'innForm' })((props: FormComponentProps) => {
    const { getFieldDecorator, validateFields } = props.form;
    const handleSave = (): void => {
      validateFields(async (errors, values) => {
        if (!errors) {
          values = { ...values, id: inn.id };
          await saveINN({ variables: { input: values } });
        }
      });
    };

    return (
      <div>
        <div>
          {innLoading || innMutationLoading ? (
            <Spin />
          ) : (
            <Form {...formItemLayout} className={styles.form} labelAlign={'left'}>
              <InputINN getFieldDecorator={getFieldDecorator} name={'number'} initialValue={inn.number} />
            </Form>
          )}
        </div>
        <Button onClick={handleSave} type={'primary'}>
          Сохранить
        </Button>
      </div>
    );
  });

  return (
    <CabinetPageTemplate>
      <InnForm />
    </CabinetPageTemplate>
  );
};

export default ComposerINNPage;
