// @ts-nocheck
import React from 'react';
import { RegularPageTemplate } from '../RegularPageTemplate';
import { CartEmptyText } from './CartEmptyText';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { CartPageTracksFindQuery } from '../../__generated__/CartPageTracksFindQuery';
import { Typography } from 'antd';
import { TrackTable } from '../track/table/TrackTable';
import { Spinner } from '../Spinner';
import { TrackTableExpandedRowContent } from '../track/table/TrackTableExpandedRowContent';

export const CART_PAGE_TRACKS_FIND_QUERY = gql`
  query CartPageTracksFindQuery {
    client {
      cart {
        track {
          find {
            id
            name
            price
            bpm
            publisher {
              name
            }
            description
            authorName
            mainSampleId
            isFavorite
            inCart
            sameTracksCount
            getSameTracks {
                name
                id
                authorName
                samples {
                  id
                  name
                  description
                  duration
                }
            }
            genres {
              name
            }
            instruments {
              name
            }
            moods {
              name
            }
            samples {
              id
              name
              description
              duration
            }
          }
        }
      }
    }
  }
`;

export function CartPage(): JSX.Element {
  const { data, loading } = useQuery<CartPageTracksFindQuery>(CART_PAGE_TRACKS_FIND_QUERY);
  const tracksInCart = data?.client?.cart?.track?.find;

  return (
    <RegularPageTemplate>
      {loading ? (
        <Spinner />
      ) : tracksInCart?.length ? (
        <React.Fragment>
          <Typography.Title level={2}>Треков в корзине: {tracksInCart?.length}</Typography.Title>
          <TrackTable
            tracks={tracksInCart}
            cols={{ actions: { checkout: true, cart: true, favorite: true }, price: true, duration: true }}
            expandable={{
              expandedRowRender: TrackTableExpandedRowContent,
              expandRowByClick: true,
            }}
          />
        </React.Fragment>
      ) : (
        <CartEmptyText />
      )}
    </RegularPageTemplate>
  );
}
