import styles from '../../css/form.module.css';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {ROUTES} from '../../routes';
import cx from 'classnames';
import {Link, Redirect} from 'react-router-dom';
import React from 'react';
import {ValidationErrors} from './interfaces';
import {isEmail} from '../../utils';
import {FieldWrapper} from './FieldWrapper';
import {LOGIN} from '../../graphql';
import {PayloadStatuses} from '../../types';
import {IS_AUTHENTICATED, isAuthenticated} from '../../graphql/common.queries';
import {Spin} from 'antd';
import {useMutation, useQuery} from 'react-apollo';

const formValidationFunction = (values): ValidationErrors => {
    const errors: ValidationErrors = {};
    if (!values.login) {
        errors.login = 'Обязательно к заполнению';
    } else if (!isEmail(values.login)) {
        errors.login = 'Неверный формат email адреса';
    }
    return errors;
};

const GraphqlErrorMessages = ({errors}) =>
    errors.length &&
    errors.map(({message}, key) => (
        <p data-tag="login-error" key={key} className={cx(styles.errorMsg, styles.formErrorMessage)}>
            {message}
        </p>
    ));

export const isComposer = (data): boolean => {
    return !!data?.client?.account?.composer?.onboardingFinished;
};

export function LoginForm(): JSX.Element {
    const {data: isAuthenticatedData} = useQuery(IS_AUTHENTICATED, {fetchPolicy: "network-only"});
    //console.log("isAuthenticatedData ", isAuthenticatedData)

    const [login, {data: loginData, loading, error}] = useMutation(LOGIN, {
        refetchQueries: [{query: IS_AUTHENTICATED},],
        //awaitRefetchQueries: true,
    });

    return isComposer(isAuthenticatedData) ? (
        <Redirect to={ROUTES.CABINET.COMPOSER.NEW_TRACK}/>
    ) : isAuthenticated(isAuthenticatedData) ? (
        <Redirect to={ROUTES.CABINET.ORDERS}/>
    ) : (
        <div className={styles.formWrapper}>
            <h1 className={styles.formTitle}>Войти</h1>
            <Formik
                initialValues={{login: '', password: ''}}
                validate={formValidationFunction}
                onSubmit={(values, {setSubmitting}): void => {
                    setSubmitting(false);
                    login({variables: values}).then((res)=>{
                        //console.log("LOGIN RESULT", res);
                    });
                }}
            >
                {({errors, isSubmitting}): JSX.Element => (
                    <Form className={styles.form}>

                        {loginData && loginData.common.auth.login.status === PayloadStatuses.Error && (
                            <GraphqlErrorMessages errors={loginData.common.auth.login.errors}/>
                        )}
                        {/*{data && data.common.auth.login.status === PayloadStatuses.SUCCESS && (
                            <Redirect to={ROUTES.CABINET.ORDERS}/>
                        )}*/}

                        <FieldWrapper>
                            <label htmlFor="reg_email" className={styles.label}>
                                Email адрес
                            </label>
                            <Field type="email" name="login"
                                   className={cx(styles.input, {[styles.inputErrored]: errors.login})}/>
                            <ErrorMessage name="login" component="div" className={styles.errorMsg}/>
                        </FieldWrapper>

                        <FieldWrapper>
                            <label htmlFor="reg_password" className={styles.label}>
                                Пароль
                            </label>
                            <Field
                                type="password"
                                name="password"
                                autocomplite="on"
                                className={cx(styles.input, {[styles.inputErrored]: errors.password})}
                            />
                            <ErrorMessage name="password" component="div" className={styles.errorMsg}/>
                        </FieldWrapper>

                        <FieldWrapper>
                            <button type="submit" className={cx(styles.button, styles.buttonActive)}
                                    disabled={isSubmitting}>
                                {loading ? <Spin/> : 'Войти'}
                            </button>
                        </FieldWrapper>
                        <div className={styles.lastText}>
                            Нет аккаунта?{'  '}
                            <Link to={ROUTES.AUTH.SIGN_UP} className={styles.link}>
                                Зарегистрироваться
                            </Link>
                        </div>
                        <div className={styles.lastText}>
                            <Link to={ROUTES.AUTH.FORGOT__PASSWORD} className={styles.link}>
                                Забыли пароль?
                            </Link>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
