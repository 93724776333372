import React, { Suspense, lazy } from 'react';
import { RegularPageTemplate } from '../RegularPageTemplate';
import { Table, Layout, Typography, Col  } from 'antd';
const { Content } = Layout;
const { Title } = Typography;

import gql from 'graphql-tag';


export function ContactPage(): JSX.Element {
  const columns = [
    {
      title: ' ',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: text => <a>{text}</a>,
    },
    {
      title: ' ',
      dataIndex: 'address',
      key: 'address',
      width: 150,
    },
  ]

  const data = [
    {
      key: '1',
      name: 'Юридический адрес',
      address: 'Россия, г. Санкт-Петербург',
    },
    {
      key: '2',
      name: 'email',
      address: 'contact@bms.ru',
    },
    {
      key: '3',
      name: 'Телефон',
      address: '8(812) 123 45 67',
    },
  ];

  return (
    <RegularPageTemplate style={{ padding: 0 }}>
      <Content style={{
        margin: '24px 16px',
        padding: 24,
        background: '#fff',
      }}>
        <Col style={{ display:'flex', justifyContent: 'center', alignItems: 'center'}}> 
        <div style={{ display: 'inline-flex'}}>
       <Title>Контакты</Title>
       </div>
       </Col>
       <Table style={{
        maxWidth: 800 ,
        margin: '24px auto'  
      }}  pagination={false}  columns={columns} dataSource={data} />
      </Content>
    </RegularPageTemplate>
  );
}
