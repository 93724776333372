import React, { useCallback, useState } from 'react';
import { CabinetPageTemplate } from '../../cabinet/CabinetPageTemplate';
import { Form } from '@ant-design/compatible';
import { Button, Layout, Input, Divider, message } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';

import { GET_PROFILE_QUERY } from '../../../graphql';
import { Spinner } from '../../Spinner';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';


const styles = require('./ProfilePage-styles.module.css');


export const UPDATE_LOGIN_INFO_MUTATION = gql`
mutation UpdateLoginInfoMutation ($input: UpdateLoginInfoInput) {
  client {
    updateLoginInfo (input: $input) {
      errors {
        message
      }
    }
  }
}
`;



interface ProfileFormProps extends FormComponentProps {
  data: {
    client: {
      profile: {
        email: string;
        account: {
          composer: {
            id: string;
            nickname: string;
            showNickname: string;
          }
          mobileNumber: string;
          vk: string;
          facebook: string;
          inn: {
            id: string;
            number: string;
          }
          email: string;
        };
        passport: {
          id: string;
          firstName: string;
          secondName: string;
          thirdName: string;
          series: string;
          number: string;
          registrationAddress: string;
        };
      };
    };
  }
  newForm: any
  onChange: any
}



class ProfileForm extends React.Component<ProfileFormProps> {
  constructor(props) {
    super(props);
  }

  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    newFormChild: null
  };

  handleSubmit = () => {
    // this.props.newForm(newFields)
    const profile = this.props.data?.client?.profile;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.onChange(values)

      }
    });
  }

  handleChange = () => {
    const { getFieldsValue } = this.props.form;
    let newFields = getFieldsValue();
    this.props.form.validateFieldsAndScroll((err, values) => {
      console.log('Received values of form: ', values);
      if (!err) {
        this.props.newForm(newFields);
      }
    })
  };



  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });

  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('Пароли не совпадают!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  handleWebsiteChange = value => {
    let autoCompleteResult;
    if (!value) {
      autoCompleteResult = [];
    } else {
      autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
    }
    this.setState({ autoCompleteResult });
  };

  render() {

    const { getFieldDecorator } = this.props.form;
    const { getFieldsValue } = this.props.form;
    const { autoCompleteResult } = this.state;

    // let newForm = getFieldsValue()

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    // const profile = this.props.data.client.profile;
    const profile = this.props.data?.client?.profile;
    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit} onChange={this.handleChange} className={styles.profileForm}>


        <Form.Item label="E-mail">
          {getFieldDecorator('email', {
            initialValue: profile.account.email,
            rules: [
              {
                type: 'email',
                message: 'Введите корректный e-mail!',
              },
              {
                required: true,
                message: 'Пожалуйста, введите e-mail!',
              },
            ],
          })(<Input />)}

        </Form.Item>

        <Form.Item label="Мобильный телефон">
          {getFieldDecorator('phone', {
            initialValue: profile.account.mobileNumber,
            rules: [{ message: 'Пожалуйста, введите номер телефона!' }],
          })(<Input  style={{ width: '100%' }} />)}
        </Form.Item>

        <Form.Item label="Вконтакте">
          {getFieldDecorator('vk', {
            initialValue: profile.account.vk,
          })(<Input  style={{ width: '100%' }} />)}
        </Form.Item>

        <Form.Item label="Facebook">
          {getFieldDecorator('facebook', {
            initialValue: profile.account.facebook,
          })(<Input  style={{ width: '100%' }} />)}
        </Form.Item>


        <Divider />

          <Form.Item label="Фамилия">
                {getFieldDecorator('secondName', {
                  initialValue: profile.passport?.secondName,
                })(<Input />)}
                  </Form.Item>


          <Form.Item label="Имя">
            {getFieldDecorator('firstName', {
              initialValue: profile.passport?.firstName,
            })(<Input />)}
          </Form.Item>


          <Form.Item label="Отчество">
            {getFieldDecorator('thirdName', {
              initialValue: profile.passport?.thirdName,
            })(<Input />)}
          </Form.Item>

        <Divider />


        <Form.Item label="Пароль" hasFeedback>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: 'ВВедита ваш пароль',
              },
              {
                validator: this.validateToNextPassword,
              },
            ],
          })(<Input.Password />)}
        </Form.Item>


        <Divider />

        <Form.Item label="Новый пароль" >
          {getFieldDecorator('newPassword', {
            rules: [
              {
                message: 'Пожалуйста, введите новый пароль',
              },

            ],
          })(<Input.Password />)}
        </Form.Item>


        <Form.Item label="Подтвердите новый пароль" hasFeedback>
          {getFieldDecorator('confirm', {
            rules: [
              {
                message: 'Пожалуйста, подтвердите пароль',
              },
              {
                validator: this.compareToFirstPassword,
              },
            ],
          })(<Input.Password onBlur={this.handleConfirmBlur} />)}
        </Form.Item>


        <Button onClick={() => this.handleSubmit()} type={'primary'}>
          Сохранить
              </Button>

      </Form>
    );
  }
}

const WrappedProfileForm = Form.create<ProfileFormProps>({ name: 'profile' })(ProfileForm)

export const ProfilePage = (): JSX.Element => {

  const { data: querydata, loading } = useQuery(GET_PROFILE_QUERY);
  console.log(querydata);
  const [form, setForm] = useState(querydata);
  const [change, setChange] = useState();

  const [patchPassportPage] = useMutation(UPDATE_LOGIN_INFO_MUTATION, {
    refetchQueries: [{ query: GET_PROFILE_QUERY }],
    onCompleted: () => {
      console.log(form)
      message.success('Данные успешно сохранены!');
    },
  });

  const handleNewForm = (value) => {
    setForm(value)
  }
  const handleChange = (value) => {
    setChange(value)
    return patchPassportPage({
      variables: {
      input: {
      	    login: form.email,
	        newPassword: form.newPassword,
	        phone: form.phone,
            vk: form.vk,
            facebook: form.facebook,
	        firstName: form.firstName,
	        secondName: form.secondName,
	        thirdName: form.thirdName,
        }
	  }
    })
  }


  return (
    <CabinetPageTemplate>
      <Layout.Content
        style={{
          margin: '24px 16px',
          padding: 24,
          background: '#fff',
          minHeight: 280,
        }}
      >
        <div className="layout-center">
          <div style={{ background: '#fff', textAlign: 'center', }} className="container">
            {loading ? <Spinner /> : <WrappedProfileForm data={querydata} newForm={handleNewForm} onChange={handleChange} />}
          </div>
        </div>
      </Layout.Content>
    </CabinetPageTemplate>
  );
};

export default ProfilePage;
