import React from 'react';
import { CabinetPageTemplate } from '../cabinet/CabinetPageTemplate';
import InstrumentsTable from './InstrumentTable';
import CreateInstrumentForm from './CreateInstrumentForm';

export const InstrumentsAdminPage = (): JSX.Element => {
  return (
    <CabinetPageTemplate>
      <CreateInstrumentForm />
      <InstrumentsTable />
    </CabinetPageTemplate>
  );
};

export default InstrumentsAdminPage;
