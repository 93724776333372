// @ts-nocheck
import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Typography } from 'antd';
import { RegularPageTemplate } from '../RegularPageTemplate';
import { TrackTable } from '../track/table/TrackTable';
import { useLocation } from 'react-router';
import { TrackSiderFilter } from '../track/TrackSiderFilter';
import { CommonSearchQuery } from '../../__generated__/CommonSearchQuery';
import { SearchEmptyText } from './SearchEmptyText';
import { Spinner } from '../Spinner';
import { TrackTableExpandedRowContent } from "../track/table/TrackTableExpandedRowContent";

export const COMMON_SEARCH_QUERY = gql`
  query CommonSearchQuery($text: String) {
    common {
      search(text: $text) {
        id
        name
        price
        bpm
        publisher {
          name
        }
        description
        authorName
        mainSampleId
        isFavorite
        inCart
        sameTracksCount
        
        genres {
          name
        }
        instruments {
          name
        }
        moods {
          name
        }
        samples {
          id
          name
          description
          duration
        }
      }
    }
  }
`;

function SearchResultWrapper(props: React.HTMLProps<HTMLDivElement>): JSX.Element {
  return <div {...props}>{props.children}</div>;
}

export const CommonSearchPage = (): JSX.Element => {

  const query = new URLSearchParams(useLocation().search);

  const { loading, data } = useQuery<CommonSearchQuery>(COMMON_SEARCH_QUERY, {
    variables: {
      text: query.get('text'),
    },
  });

  const tracks = data?.common?.search || [];

  return (
    <RegularPageTemplate style={{ display: 'flex' }}>
      <TrackSiderFilter style={{ width: '220px', flexShrink: 0 }} />
      {loading ? (
        <Spinner />
      ) : query.get('text') && tracks.length ? (
        <SearchResultWrapper style={{ width: '100%' }}>
          <Typography.Title level={2}>Найдено треков: {tracks.length}</Typography.Title>
          <TrackTable
            loading={loading}
            tracks={tracks}
            cols={{
              sameTrack: true,
              duration: true,
              bpm: true,
              price: true,
              actions: {
                demo: true,
                favorite: true,
                cart: true,
              },
            }}
            style={{ width: '100%' }}
            expandable={{
              expandedRowRender: TrackTableExpandedRowContent,
              expandRowByClick: true,
            }}/>
        </SearchResultWrapper>
      ) : (
        <SearchEmptyText style={{ flexGrow: 1 }} />
      )}
    </RegularPageTemplate>
  );
};
