import React from 'react';
import {DownloadOutlined} from '@ant-design/icons';
import {Button, Tooltip} from 'antd';
import {getSampleSrc} from "../../../../utils";


export const TrackTableDownloadFilesButton = (props: { track: any, }): JSX.Element => {
    return (
        <Tooltip title="Скачать трек">
            <Button
                href={`/download/track/${props.track.id}/full`}
                // download={true}
                size={'small'}
                shape={'circle'}
                style={{
                    marginRight: '5px',
                }}
            >
                <DownloadOutlined/>
            </Button>
        </Tooltip>
    );
};
