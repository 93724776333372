import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import cx from 'classnames';
import { useQuery } from '@apollo/react-hooks';
import { IS_AUTHENTICATED, isAuthenticated } from '../../graphql/common.queries';
import { ROUTES } from '../../routes';

const styles = require('./HeaderTopBar-styles.module.css');

const navTopMenuItems = [
  { title: 'Стать композитором', link: ROUTES.COMMON.BECOME_COMPOSER_INFO },
  { title: 'Политика конфиденциальности', link: ROUTES.COMMON.PRIVACY_POLICY },
  { title: 'Пользовательское соглашение', link: ROUTES.COMMON.AGREEMENT },
];

const NotAuthenticatedMenuItems = [{ title: 'Регистрация', link: '/signup' }, { title: 'Войти', link: '/login' }];

function LogoutMenuItem(): JSX.Element {
  const [redirect, setRedirect] = useState(false);
  const onLogoutClicked = (): void => setRedirect(true);

  return redirect ? (
    <Redirect to={ROUTES.AUTH.LOGOUT} />
  ) : (
    <li className={styles.menuItem}>
      <a onClick={onLogoutClicked} className={styles.menuLink}>
        Выход
      </a>
    </li>
  );
}

function TopMenuItem(props: { title: string; link: string }): JSX.Element {
  return (
    <li className={styles.menuItem}>
      <Link to={props.link} className={styles.menuLink}>
        {props.title}
      </Link>
    </li>
  );
}

export function HeaderTopBar(): JSX.Element {
  const { data, loading } = useQuery(IS_AUTHENTICATED, {
      //fetchPolicy: "no-cache",
  });

  return (
    <div className={cx(styles.headerTop)}>
      <div className="container">
        <div className="d-none d-md-block">
          <ul className={styles.nav}>
            {/*{navTopMenuItems.map((item, i) => (*/}
            {/*  <TopMenuItem title={item.title} link={item.link} key={i} />*/}
            {/*))}*/}
            {loading || !isAuthenticated(data) ? (
              NotAuthenticatedMenuItems.map((item, i) => <TopMenuItem title={item.title} link={item.link} key={i} />)
            ) : (
              <>
                <TopMenuItem title="FAQ" link={ROUTES.COMMON.FAQ} />
                <TopMenuItem title="Контакты" link={ROUTES.COMMON.CONTACTS} />
                <TopMenuItem title="Мой кабинет" link={ROUTES.COMMON.CABINET} />
                <LogoutMenuItem />
              </>
            )}
          </ul>
        </div>
        {/*<div className="flex-col show-for-medium flex-grow">*/}
        {/*  <ul className="nav nav-center nav-small mobile-nav  nav-divided" />*/}
        {/*</div>*/}
      </div>
    </div>
  );
}
