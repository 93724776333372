import gql from "graphql-tag";

export const GENRES_FIND_ALL_QUERY = gql`
  query GenresFindAllQuery {
    common {
      genres {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

export const CREATE_GENRE_MUTATION = gql`
  mutation CreateGenre($input: CreateGenreInput) {
    admin {
      genre {
        create(input: $input) {
          status
          genre {
            name
          }
        }
      }
    }
  }
`;

export const DELETE_GENRE_MUTATION = gql`
  mutation DeleteGenre($id: String!) {
    admin {
      genre {
        delete(id: $id) {
          status
        }
      }
    }
  }
`;

export const PATCH_GENRE_MUTATION = gql`
  mutation PatchGenre($input: PatchGenreInput) {
    admin {
      genre {
        patch(input: $input) {
          status
          group {
            name
          }
        }
      }
    }
  }
`;
