import React from 'react';


const styles = require('./VolumeButton-styles.module.css');

export const VolumeButton = (props: { onClick(): void; muted: boolean; volume: number }): JSX.Element => {
  return (
    <button className={styles.actionButton} onClick={props.onClick}>
      <svg viewBox={'0 0 1000 1000'}>
        <g>
          <path d="M445,195L280, 340L165, 340a 20,20 0 0 0 -15,15L150 640a 20,20 0 0 0 15,15L280,655L445,804.4c15.1,15.1,41,4.4,41-17V212.1C486,190.7,460.1,180,445,195.1z" />
          {!props.muted && props.volume > 0 && (
            <path d="M540.2,648.7c30.1-30.4,51.6-70.1,58.5-112.5c7.1-43.6,1.4-86.9-17-127c-10.1-21.9-24.6-41.9-41.5-58.9 c-9.2-9.3-26.2-9.9-35.4,0c-9.2,10-9.8,25.4,0,35.4c3.5,3.5,6.8,7.1,10,10.8c1.4,1.7,2.8,3.4,4.2,5c2.2,2.6-3.8-5.2-0.8-1.1 c0.9,1.2,1.7,2.3,2.6,3.5c5.4,7.5,10.2,15.4,14.4,23.6c1,1.9,1.9,3.8,2.8,5.7c0.8,1.8,3.8,9.4,0.9,1.7c1.7,4.3,3.4,8.6,4.8,13 c2.9,8.7,5.1,17.7,6.7,26.8c0.2,1.2,1.3,8.3,0.6,3.5c-0.7-4.7,0.2,2.2,0.3,3.5c0.5,4.8,0.7,9.6,0.8,14.4c0.2,9-0.8,17.8-1.4,26.7 c0.6-8.7,0-0.5-0.3,1.5c-0.4,2.3-0.8,4.5-1.3,6.8c-0.9,4.5-2.1,9-3.3,13.4c-1.2,4-2.4,8-3.9,11.9c-0.8,2.2-1.6,4.3-2.5,6.5 c-1.5,3.8,0.8-1.8,0.9-2.1c-0.8,1.4-1.3,3-2,4.5c-4.4,9.4-9.5,18.4-15.4,27c-1.5,2.2-3,4.3-4.6,6.4c-2.4,3.3,1-1.4,1.3-1.6 c-1.2,1.1-2.2,2.7-3.2,3.9c-3.6,4.3-7.4,8.5-11.4,12.5c-9.2,9.3-10,26.2,0,35.4C514.8,657.8,530.3,658.6,540.2,648.7L540.2,648.7 z" />
          )}
          {!props.muted && props.volume > 0.35 && (
            <path d="M608.2,724.7c45.6-45.9,77.6-105.7,88.3-169.6c10.9-65,2.3-130.8-25.4-190.7c-15.3-33.2-37.1-63.6-62.9-89.5 c-9.2-9.3-26.2-9.9-35.4,0c-9.2,10-9.9,25.4,0,35.4c5.5,5.6,10.9,11.3,16,17.3c2.2,2.6,4.4,5.2,6.5,7.9c0.6,0.7,1.2,1.6,1.8,2.3 c-4.3-4.8-2.2-2.9-1.3-1.6c1.3,1.7,2.6,3.5,3.9,5.2c8.7,12.1,16.6,24.9,23.3,38.2c1.5,2.9,2.9,5.9,4.3,8.8 c0.8,1.7,1.6,3.4,2.3,5.1c0.4,0.9,0.7,1.7,1.1,2.6c1.7,4-1.8-4.3-0.6-1.5c2.7,6.7,5.2,13.4,7.5,20.3c4.5,13.6,7.9,27.5,10.4,41.6 c0.3,1.8,0.6,3.6,0.9,5.4c0.3,2,1,8.4,0.1,0.2c0.4,3.7,0.9,7.3,1.2,11c0.6,7.1,1,14.2,1.2,21.3c0.3,14.5-0.9,28.8-2.4,43.3 c0-0.2,0.9-6.7,0.3-2.5c-0.1,0.7-0.2,1.4-0.3,2c-0.3,1.8-0.6,3.6-0.9,5.4c-0.6,3.6-1.3,7.2-2.1,10.9c-1.5,7-3.2,13.9-5.2,20.8 c-2,7.1-4.4,14.2-6.9,21.1c-1.1,3.1-2.3,6.1-3.6,9.1c-1.5,3.8,0.8-1.8,0.9-2.1c-0.4,0.8-0.7,1.7-1.1,2.6 c-0.8,1.9-1.7,3.8-2.6,5.8c-7,15-15.2,29.4-24.6,43c-2.3,3.4-4.7,6.6-7.2,9.9c-2.4,3.3,1-1.3,1.3-1.6c-0.8,0.9-1.5,1.9-2.2,2.8 c-1.4,1.7-2.7,3.4-4.1,5.1c-5.7,6.8-11.6,13.3-17.9,19.5c-9.2,9.3-10,26.2,0,35.4C582.8,733.8,598.3,734.6,608.2,724.7 L608.2,724.7z" />
          )}
          {!props.muted && props.volume > 0.75 && (
            <path d="M688.2,778.7c56.9-57.2,96.4-131.7,109.7-211.4c13.3-79.9,2.6-161.2-31.4-234.9 c-19.1-41.4-46.2-79.3-78.3-111.6c-9.2-9.3-26.2-9.9-35.4,0c-9.2,10-9.9,25.5,0,35.4c6.9,6.9,13.6,14.1,20,21.6 c3.1,3.7,6.2,7.4,9.2,11.2c0.6,0.8,1.2,1.5,1.8,2.3c-4.3-5.4-1.9-2.4-0.8-1c1.6,2.1,3.2,4.2,4.7,6.4c11.1,15.4,21,31.6,29.6,48.5 c1.9,3.8,3.8,7.5,5.5,11.4c1,2.1,2,4.2,2.9,6.4c0.4,0.9,0.8,1.7,1.1,2.6c0.4,0.9,0.7,1.7,1.1,2.6c-1.5-3.6-1.9-4.5-1.2-2.8 c3.4,8.4,6.6,16.8,9.4,25.4c5.8,17.5,10.2,35.4,13.3,53.6c0.4,2.3,0.7,4.5,1.1,6.8c0.1,0.9,0.3,1.8,0.4,2.7 c0.7,5-0.7-5.3-0.2-1.9c0.6,4.8,1.2,9.6,1.6,14.3c0.8,9.1,1.3,18.3,1.5,27.4c0.3,17.9-0.8,35.7-3,53.5c-0.6,5.2,0.9-6.4,0.2-1.2 c-0.1,0.9-0.3,1.8-0.4,2.7c-0.3,2.3-0.7,4.5-1.1,6.8c-0.8,4.5-1.6,9-2.6,13.5c-1.9,9-4.1,18-6.7,26.8c-2.6,8.9-5.5,17.7-8.7,26.3 c-1.5,3.9-3,7.8-4.6,11.7c-1.9,4.8,2.4-5.6,0.4-0.8c-0.4,0.9-0.7,1.7-1.1,2.6c-1.1,2.6-2.3,5.1-3.5,7.7 c-8.8,18.8-19.1,36.9-31,54c-3,4.3-6.1,8.6-9.3,12.8c-3,4.1,3-3.7,0.8-1.1c-1,1.3-2.1,2.7-3.1,4c-1.5,1.9-3,3.8-4.6,5.6 c-7.2,8.6-14.8,16.9-22.8,24.9c-9.2,9.3-10,26.2,0,35.4C662.8,787.8,678.3,788.6,688.2,778.7L688.2,778.7z" />
          )}
          {props.muted && (
            <g id="Cross">
              <path d="M563.8,415.7c22.8,22.8,45.6,45.6,68.4,68.4c36.4,36.4,72.8,72.8,109.1,109.1c8.4,8.4,16.8,16.8,25.2,25.2 c9.3,9.3,26.2,9.9,35.4,0c9.2-10,9.9-25.5,0-35.4c-22.8-22.8-45.6-45.6-68.4-68.4c-36.4-36.4-72.8-72.8-109.1-109.1  c-8.4-8.4-16.8-16.8-25.2-25.2c-9.3-9.3-26.2-9.9-35.4,0C554.7,390.3,553.9,405.8,563.8,415.7L563.8,415.7z" />
              <path d="M767.3,381.1c-22.8,22.8-45.6,45.6-68.4,68.4c-36.4,36.4-72.8,72.8-109.1,109.1 c-8.4,8.4-16.8,16.8-25.2,25.2c-9.3,9.3-9.9,26.2,0,35.4c10,9.2,25.5,9.9,35.4,0c22.8-22.8,45.6-45.6,68.4-68.4  c36.4-36.4,72.8-72.8,109.1-109.1c8.4-8.4,16.8-16.8,25.2-25.2c9.3-9.3,9.9-26.2,0-35.4C792.7,371.9,777.2,371.2,767.3,381.1  L767.3,381.1z" />
            </g>
          )}
        </g>
      </svg>
    </button>
  );
};

export default VolumeButton;
