import React from 'react';

import { HeaderTopBar } from './HeaderTopBar';
import { HeaderMasterhead } from './HeaderMasterhead';
import { HeaderNav } from './HeaderNav';
import cx from 'classnames';

const styles = require('./Header-styles.module.css');

export function Header(props: { stuck: boolean }): JSX.Element {
  return (
    <header className={cx(styles.header, { [styles.stuckHeader]: props.stuck })}>
      <div className={cx(styles.headerWrapper, { [styles.stuck]: props.stuck })}>
       {/* {!props.stuck && <HeaderTopBar />}*/}
        <HeaderMasterhead />
        <HeaderNav />
        <div className="header-bg-container fill">
          <div className="header-bg-image fill" />
          <div className="header-bg-color fill" />
        </div>
      </div>
    </header>
  );
}
