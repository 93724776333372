import React, { useCallback } from 'react';
import { TablePlayButton } from '../../audioplayer/TablePlayButton';
import { getSampleSrc } from '../../../utils';
import { useAudioPlayer } from '../../audioplayer/useAudioPlayer';

export const TrackTablePlayButtonCell = (text, track): JSX.Element => {
  const { currentTrack, play, player, togglePlay } = useAudioPlayer();

  const handleClick = useCallback(() => {
    if (currentTrack.src !== getSampleSrc(track.mainSampleId)) {
      play({
        name: track.name,
        src: getSampleSrc(track.mainSampleId),
      });
    } else {
      togglePlay();
    }
  }, [currentTrack.src, track.mainSampleId]);

  return (
    <TablePlayButton
      onClick={handleClick}
      playing={currentTrack.src === getSampleSrc(track.mainSampleId) && !player.paused}
    />
  );
};
