import React from 'react';
import { useLocation } from 'react-router-dom';
import { RegularPageTemplate } from '../RegularPageTemplate';

const ConfirmOrder: React.FC = () => {
    const location = useLocation();


    const getOrderIdFromQuery = () => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get('orderId');
    };

    const orderId = getOrderIdFromQuery();

    return (
        <RegularPageTemplate style={{ padding: 0, paddingBottom: 20 }}>
            <div style={{ textAlign: 'center', padding: '20px' }}>
                <h1>Спасибо за заказ № {orderId}</h1>
            </div>
        </RegularPageTemplate>
    );
};

export default ConfirmOrder;