import React from 'react';

import cx from 'classnames';
import {ROUTES} from "../routes";
import {Link} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const styles = require('./Footer-styles.module.css');

function Tag(): JSX.Element {
  return (
    <a href="http://localhost:8000/product-tag/bag/" className={styles.tag} aria-label="bag (5 products)">
      bag
    </a>
  );
}

function NewsBadge(): JSX.Element {
  return (
    <li className={styles.lastNewsBadgeLi}>
      <div className={styles.badgeWrapper}>
        <div className={styles.badgeDateWrapper}>
          <div className={styles.badgeDate}>
            <div className={styles.badgeDateInner}>
              <span>03</span>
              <br />
              <span className={styles.badgeDateMonth}>Apr</span>
            </div>
          </div>
        </div>
        <div>
          <a href="http://localhost:8000/2019/04/03/hello-world/" className={styles.badgeTitle}>
            Hello world!
          </a>
          <a href="http://localhost:8000/2019/04/03/hello-world/#comments" className={styles.badgeComments}>
            <strong>1</strong> Comment
          </a>
        </div>
      </div>
    </li>
  );
}

function Widget(props): JSX.Element {
  return (
    <div className={styles.widgetWrapper} style={{...props.style}}>
	    {props.title ? <div className={styles.widgetTitle}>{props.title}</div> : '' }
     {/* <div className={styles.widgetTitleDivider} />*/}
      {props.children}
    </div>
  );
}

export function Footer(): JSX.Element {
  return (
    <div className={styles.wrapper}>
      <div className="container">

		<div className={'d-flex justify-content-between'}>
        <Widget title='' style={{display: "flex", alignItems: "center", justifyContent: "center"}} >
	        <img src="/img/MW_logo_transparent2.png" className={styles.logoImg} alt="Bmstock" />
        </Widget>

          <Widget title="КТО МЫ">
            <ul>
              <li>
                <Link className={styles.link} to={ROUTES.COMMON.CONTACTS}>Связаться с нами</Link>
              </li>
              <li>
                <Link className={styles.link} to={ROUTES.COMMON.BECOME_COMPOSER_INFO}>Стать композитором</Link>
              </li>
              <li>
                <Link className={styles.link} to={ROUTES.CABINET.COMPOSER.NEW}>Мы в социальных сетях</Link>
              </li>
            </ul>
          </Widget>
          <Widget title="МУЗЫКАЛЬНАЯ ПОДДЕРЖКА">
            <ul>
              <li>
                <Link className={styles.link} to={ROUTES.COMMON.AGREEMENT}>Пользовательское соглашение</Link>
              </li>
              <li>
                <Link className={styles.link} to={ROUTES.COMMON.PRIVACY_POLICY}>Политика конфиденциальности</Link>
              </li>
              <li>
               <Link  className={styles.link} to={ROUTES.COMMON.FAQ}>Часто задаваемые вопросы</Link>
              </li>
            </ul>
          </Widget>
          <Widget title="НАША МУЗЫКА">
            <ul>
              <li>
                <HashLink to={ROUTES.COMMON.MAIN + "#albums"}  className={styles.link} >Альбомы и направления</HashLink>
              </li>
              <li>
                <Link  className={styles.link} to={ROUTES.COMMON.TRACKS.MAIN}>Найти музыку</Link>
              </li>
            </ul>
          </Widget>
         {/* <Widget title="Signup for newsletter">
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
              laoreet.
            </p>
            <div role="form" className="wpcf7" id="wpcf7-f9-o2" lang="en-US" dir="ltr">
              <div className="screen-reader-response" />
              <form action="/#wpcf7-f9-o2" method="post" className="wpcf7-form" noValidate={true}>
                <div className="form-flat">
                  <span className="wpcf7-form-control-wrap your-email">
                    <input type="email" name="your-email" size={40}
                    className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                    aria-required="true" aria-invalid="false"
                    placeholder="Your Email (required)"/>
                  </span>
                  <p>
                    <input type="submit" value="Sign Up" className="wpcf7-form-control wpcf7-submit button" />
                    <span className="ajax-loader" />
                  </p>
                </div>
                <div className="wpcf7-response-output wpcf7-display-none" />
              </form>
            </div>
          </Widget>*/}
        </div>
      </div>
    </div>
  );
}
