import React, { FormEvent } from 'react';
import { Button, Input, Spin } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { CREATE_INSTRUMENT_MUTATION, INSTRUMENTS_FIND_ALL_QUERY } from './instrument.graphql';

export const CreateInstrumentForm = (): JSX.Element => {
  const [createInstrument, { loading }] = useMutation(CREATE_INSTRUMENT_MUTATION, {
    refetchQueries: [
      {
        query: INSTRUMENTS_FIND_ALL_QUERY,
      },
    ],
  });

  const AddInstrumentForm = Form.create<FormComponentProps>({
    name: 'newInstrumentForm',
  })((props: FormComponentProps) => {
    const { getFieldDecorator, validateFields } = props.form;

    const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
      e.preventDefault();
      validateFields((errors, values) => {
        if (!errors) {
          return createInstrument({
            variables: { input: values },
          });
        }
      });
    };

    return (
      <Form onSubmit={submitHandler} layout={'inline'}>
        <Form.Item>
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Пожалуйста, введите наименование инструмента!',
              },
            ],
          })(<Input placeholder="Новый инструмент" />)}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType={'submit'}>
            {loading ? <Spin /> : 'Добавить инструмент'}
          </Button>
        </Form.Item>
      </Form>
    );
  });

  return <AddInstrumentForm />;
};

export default CreateInstrumentForm;
