import React, { Ref } from 'react';
import { TRACK_STATUS } from './track.interfaces';
import { Select } from 'antd';
import { SelectValue } from 'antd/es/select';

const TrackStatusSelect = (props, ref: Ref<Select<SelectValue>>): JSX.Element => {
  return (
    <Select {...props} ref={ref}>
      <Select.Option value={TRACK_STATUS.NEW.value}>Новый</Select.Option>
      <Select.Option value={TRACK_STATUS.NOT_PUBLISHED.value}>Не опубликован</Select.Option>
      <Select.Option value={TRACK_STATUS.REJECTED.value}>Отклонён</Select.Option>
      <Select.Option value={TRACK_STATUS.PUBLISHED.value}>Опубликован</Select.Option>
    </Select>
  );
};

export const TrackStatusField = React.forwardRef(TrackStatusSelect);
