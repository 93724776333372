import { ROUTES } from "./routes";

export const isEmail = (value: string): boolean => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);

export const sortByDate = field => {
  return (a, b): number => {
    if (a[field] === b[field]) return 0;
    return a[field] < b[field] ? 1 : -1;
  };
};

export const sortAsc = field => {
  return (a, b): number => {
    if (a[field] === b[field]) return 0;
    return a[field] > b[field] ? 1 : -1;
  };
};

interface SourceObject<T> {
  [key: string]: SourceObject<T> | T | undefined;
}

export const pick = <T>(data: SourceObject<T>, path: string): T | undefined => {
  const pathKeys = [...path.split('.')];

  const get = (object: SourceObject<T>, keys: string[]): SourceObject<T> | T | undefined => {
    const key = keys.shift();

    if (!object || !key) return undefined;
    if (!keys.length) return object[key];
    if (keys.length && object[key]) return get(object[key] as SourceObject<T>, keys);
    else return undefined;
  };

  return (get(data, pathKeys) as T) || undefined;
};

export const getStringDuration = (duration: number): string => {
  const pad = (num: number): string => String(num).padStart(2, '0');
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);
  const seconds = duration - hours * 3600 - minutes * 60;
  return (hours ? hours + ':' : '') + (pad(minutes) + ':') + pad(seconds);
};

export const getSampleSrc = (id: string): string => ROUTES.COMMON.LISTEN.SAMPLE.replace('{id}', id);