export type UploadSample = {
  id?: string;
  name?: string;
  description?: string | null;
  uid?: string;
  percent?: number;
};

export type UploadMainSample = {
  id?: string;
  name?: string;
  description?: string | null;
  uid?: string;
  percent?: number;
};

export const TRACK_STATUS = {
  NEW: {
    value: 0,
    text: 'На проверке',
  },
  NOT_PUBLISHED: {
    value: 1,
    text: 'Не опубликован',
  },
  REJECTED: {
    value: 2,
    text: 'Отклонён',
  },
  PUBLISHED: {
    value: 3,
    text: 'Опубликован',
  },
  WITHDRAWED: {
    value: 4,
    text: 'Отозван',
  },
};
