import React from 'react';
import { UserOutlined, HeartFilled } from '@ant-design/icons';

import { Link } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { HeaderDesktopCartIcon } from './HeaderDesktopCartIcon';

const styles = require('./HeaderIconBlock-styles.module.css');

export function HeaderIconBlock(): JSX.Element {
  return (
    <ul className={styles.ul}>
      <li className={styles.li}>
        <Link to={ROUTES.CABINET.FAVORITES} className={styles.a}>
          <HeartFilled />
        </Link>
      </li>
      <li className={styles.devider} />
      <li className={styles.li}>
        <Link to={ROUTES.CABINET.MAIN} className={styles.a}>
          <UserOutlined />
        </Link>
        {/*<LoginFormPopup/>*/}
        {/*<LoginRegisterFormPopup/>*/}
      </li>
      <li className={styles.devider} />
      <li className={styles.li}>
        <HeaderDesktopCartIcon className={styles.a} />
        {/*<ul className="nav-dropdown nav-dropdown-simple">*/}
        {/*<li className="html widget_shopping_cart">*/}
        {/*<div className="widget_shopping_cart_content">*/}
        {/*<p className="woocommerce-mini-cart__empty-message">No products in the cart.</p>*/}
        {/*</div>*/}
        {/*</li>*/}
        {/*</ul>*/}
      </li>
    </ul>
  );
}

// export const HeaderIconBlockMobile = (): JSX.Element => (
//   <ul className={styles.mobileUl}>
//     <li className={styles.mobileLi}>
//       <a href="/" className={styles.mobileLink}>
//         <FlatsomeIcon name="heart" />
//       </a>
//     </li>
//     <li className={styles.mobileLi}>
//       <a href="/my-account/" className={styles.mobileLink} title="My account">
//         <FlatsomeIcon name="user" />
//       </a>
//     </li>
//     <li className={styles.mobileLi}>
//       <a href={ROUTES.COMMON.CART.MAIN} className={styles.mobileLink} title="Cart">
//         <FlatsomeIcon name="shopping-basket" />
//       </a>
//       {/*<div id="cart-popup" className="mfp-hide widget_shopping_cart">*/}
//       {/*<div className="cart-popup-inner inner-padding">*/}
//       {/*<div className="cart-popup-title text-center">*/}
//       {/*<h4 className="uppercase">Cart</h4>*/}
//       {/*<div className="is-divider"> </div>*/}
//       {/*</div>*/}
//       {/*<div className="widget_shopping_cart_content">*/}
//       {/*<p className="woocommerce-mini-cart__empty-message">No products in the cart.</p>*/}
//       {/*</div>*/}
//       {/*<div className="cart-sidebar-content relative"></div>*/}
//       {/*</div>*/}
//       {/*</div>*/}
//     </li>
//   </ul>
// );
