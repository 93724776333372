import React, {useEffect} from 'react';
import {TrackTableRowColumns} from './TrackTableRow';
import {getSampleSrc} from '../../../utils';
import {useAudioPlayer} from '../../audioplayer/useAudioPlayer';
import {Table} from 'antd';
import {TrackTablePlayButtonCell} from './TrackTablePlayButtonCell';
import {TrackTableNameCell} from './TrackTableNameCell';
import {ColumnProps, TableProps} from 'antd/es/table';
import {TrackTableCreatedAtCell, TrackDataSorter} from './TrackTableCreatedAtCell';
import {TrackTableStatusCell, TrackStatusSorter} from './TrackTableStatusCell';
import {TrackTableDurationCell} from './TrackTableDurationCell';
import {TrackTableBPMCell} from './TrackTableBPMCell';
import {TrackTablePublisherCell} from './TrackTablePublisherCell';
import {TrackTablePriceCell} from './TrackTablePriceCell';
import {getTrackTableActionsCell} from './actions/GetTrackTableActionsCell';
import {TrackTableSameTracksCell} from './TrackTableSameTracksCell';
import {TrackTableDateCell} from './TrackTableDateCell';
import {css} from "astroturf";

type Genre = {
    name: string;
};

type Direction = {
    name: string;
};

type Instrument = {
    name: string;
};

type Mood = {
    name: string;
};

type Publisher = {
    name: string;
};

export type TableTrack = {
    id: string;
    name: string;
    article?: string;
    authorName?: string | null;
    coauthor?: string | null;
    track?: any;
    publisher?: Publisher | null;
    price?: number | null;
    description?: string | null;
    mainSampleId: string;
    isFavorite?: boolean;
    inCart?: boolean;
    sameTracksCount?: number;
    getSameTracks?: any;
    genres?: Genre[];
    directions?: Direction[];
    instruments?: Instrument[];
    moods?: Mood[];
    bpm?: number | null;
    samples: Sample[];
    orderState?: string;
    orderId?: string;
    orderDate?: string;
    orderNumber?: string;
    saleDate?: string;
    createdAt?: string;
    updatedAt?: string;
    status?: number;
};

type Sample = {
    id: string;
    name: string;
    description?: string | null;
    duration: number;
};

type TrackTableProps = {
    loading?: boolean;
    tracks: TableTrack[];
    trackDetails?: boolean;
    cols?: TrackTableRowColumns;
};


export const TrackTable = (props: TrackTableProps & TableProps<TableTrack>): JSX.Element => {
    const {setPlayList} = useAudioPlayer();
    const {cols, tracks, ...tableProps} = props;
    /*const cols = {
      article: false,
      sameTrack: true,
      duration: true,
      bpm: true,
      price: true,
      actions: {
        demo: true,
        composerEdit: false,
        adminEdit: false,
        favorite: true,
        cart: true,
        withdraw: false,
      },
      status: false,
      checkoutButton: false,
      ...props.columns,
    };*/

    useEffect(() => {
        const newPlayList = props.tracks
            ? props.tracks.map(track => ({name: track.name, src: getSampleSrc(track.mainSampleId)}))
            : [];
        setPlayList(newPlayList);
    }, [props.tracks]);

    const getKey = (track): string =>
        track.id + (track.orderDate ? track.orderDate : track.saleDate ? track.saleDate : '');

    const columns = [
        {
            title: 'Играть',
            dataIndex: 'play',
            render: TrackTablePlayButtonCell,
        },
        {
            title: 'Название',
            dataIndex: 'name',
            render: TrackTableNameCell,
        },
    ] as ColumnProps<TableTrack>[];

    if (cols?.orderDate) columns.push({title: 'Дата покупки', dataIndex: 'orderDate', render: TrackTableDateCell});
    if (cols?.saleDate) columns.push({title: 'Дата продажи', dataIndex: 'saleDate', render: TrackTableDateCell});
    if (cols?.orderNumber) columns.push({title: '№ заказа', dataIndex: 'orderNumber'});
    /*if (cols?.sameTrack) columns.push({ title: 'Похожие', dataIndex: 'sameTracks', render: TrackTableSameTracksCell });*/
    if (cols?.article) columns.push({title: 'Артикул', dataIndex: 'article'});
    if (cols?.createdAt)
        columns.push({
            title: 'Дата загрузки',
            defaultSortOrder: 'descend',
            sorter: TrackDataSorter(),
            dataIndex: 'createdAt',
            render: TrackTableCreatedAtCell
        });

    if (cols?.status) columns.push({
        title: 'Статус',
        sorter: TrackStatusSorter(),
        dataIndex: 'status',
        render: TrackTableStatusCell
    });
    /*if (cols?.duration) columns.push({ title: 'Время', dataIndex: 'duration', render: TrackTableDurationCell });*/
    /*if (cols?.bpm) columns.push({ title: 'Темп', dataIndex: 'bpm', render: TrackTableBPMCell });*/
    if (cols?.publisher) columns.push({title: 'Издатель', dataIndex: 'publisher', render: TrackTablePublisherCell});
    if (cols?.price) columns.push({title: 'Цена', dataIndex: 'price', render: TrackTablePriceCell});
    if (cols?.actions)
        columns.push({title: 'Действия', dataIndex: 'actions', render: getTrackTableActionsCell(cols.actions)});

    /*
    sameTracksCount?: number;
    genres?: Genre[];
    instruments?: Instrument[];
    moods?: Mood[];
    bpm?: number | null;
    samples: Sample[];
    orderState?: string;
    orderDate?: string;
    orderNumber?: string;
    saleDate?: string;
    createdAt?: string;
    updatedAt?: string;
    status?: number;
  };
     */

    return <Table dataSource={tracks} columns={columns} {...tableProps} rowKey={'id'}/>;
/*
    return (
      <table className={styles.trackListTable} style={props.style}>
        <tbody>
          {props.tracks.map(track => (
            <TrackTableRow
              track={track}
              key={getKey(track)}
              columns={columns}
              trackDetails={props.trackDetails || false}
            />
          ))}
        </tbody>
      </table>
    );*/
};
