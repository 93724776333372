import React, {HTMLAttributes, useCallback} from 'react';
import {Button, Tooltip} from 'antd';
import {ShoppingCartOutlined, DeleteOutlined} from '@ant-design/icons';
import gql from 'graphql-tag';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {TrackToggleCartButtonAddTrackMutation} from '../../../../__generated__/TrackToggleCartButtonAddTrackMutation';
import {
    TrackToggleCartButtonRemoveTrackMutation
} from '../../../../__generated__/TrackToggleCartButtonRemoveTrackMutation';
import {CART_COUNT_QUERY} from '../../../header/HeaderDesktopCartIcon';
import {CART_PAGE_TRACKS_FIND_QUERY} from '../../../cart/CartPage';
import {IS_AUTHENTICATED, isAuthenticated} from '../../../../graphql/common.queries';
import {ROUTES} from "../../../../routes";
import {useHistory} from "react-router-dom";

export function ShoppingCartIconFilled(props: HTMLAttributes<HTMLAnchorElement>): JSX.Element {
    return (
        <i className={'anticon'} {...props}>
            <svg width="1em" height="1em" viewBox="0 0 898 807" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M861.888 571.9H266.387L296.287 511L793.087 510.1C809.887 510.1 824.287 498.1 827.287 481.5L896.087 96.4C897.887 86.3 895.187 75.9 888.587 68C885.325 64.112 881.257 60.9802 876.664 58.8217C872.07 56.6632 867.063 55.5298 861.987 55.5L229.988 53.4L224.588 28C221.188 11.8 206.588 0 189.988 0H35.4875C26.1254 0 17.1467 3.71909 10.5266 10.3391C3.9066 16.9592 0.1875 25.9379 0.1875 35.3C0.1875 44.6621 3.9066 53.6408 10.5266 60.2609C17.1467 66.8809 26.1254 70.6 35.4875 70.6H161.387L184.988 182.8L243.088 464.1L168.288 586.2C164.403 591.443 162.063 597.668 161.533 604.172C161.003 610.675 162.303 617.197 165.288 623C171.288 634.9 183.387 642.4 196.787 642.4H259.587C246.199 660.182 238.968 681.841 238.988 704.1C238.988 760.7 284.987 806.7 341.587 806.7C398.187 806.7 444.188 760.7 444.188 704.1C444.188 681.8 436.787 660.1 423.587 642.4H584.688C571.299 660.182 564.068 681.841 564.087 704.1C564.087 760.7 610.088 806.7 666.688 806.7C723.287 806.7 769.287 760.7 769.287 704.1C769.287 681.8 761.887 660.1 748.688 642.4H861.987C881.387 642.4 897.287 626.6 897.287 607.1C897.229 597.748 893.475 588.798 886.843 582.203C880.211 575.609 871.24 571.905 861.888 571.9ZM244.688 123L820.487 124.9L764.087 440.7L311.787 441.5L244.688 123ZM341.587 735.7C324.187 735.7 309.987 721.5 309.987 704.1C309.987 686.7 324.187 672.5 341.587 672.5C358.987 672.5 373.188 686.7 373.188 704.1C373.188 712.481 369.858 720.518 363.932 726.445C358.006 732.371 349.968 735.7 341.587 735.7ZM666.688 735.7C649.288 735.7 635.087 721.5 635.087 704.1C635.087 686.7 649.288 672.5 666.688 672.5C684.087 672.5 698.287 686.7 698.287 704.1C698.287 712.481 694.958 720.518 689.032 726.445C683.106 732.371 675.068 735.7 666.688 735.7Z"
                    fill="currentColor"
                />
                <path d="M244.688 123L820.487 124.9L764.087 440.7L311.787 441.5L244.688 123Z" fill="currentColor"/>
            </svg>
        </i>
    );
}

const ADD_TRACK = gql`
  mutation TrackToggleCartButtonAddTrackMutation($trackId: String!) {
    client {
      cart {
        addTrack(trackId: $trackId) {
          id
          inCart
        }
      }
    }
  }
`;

const REMOVE_TRACK = gql`
  mutation TrackToggleCartButtonRemoveTrackMutation($trackId: String!) {
    client {
      cart {
        removeTrack(trackId: $trackId) {
          id
          inCart
          name
        }
      }
    }
  }
`;

export function TrackToggleCartButton(props: {
    trackid: string;
    incart: boolean;
    style?: React.CSSProperties;
}): JSX.Element {
    const options = {refetchQueries: [{query: CART_COUNT_QUERY}, {query: CART_PAGE_TRACKS_FIND_QUERY}]};
    const [addTrack] = useMutation<TrackToggleCartButtonAddTrackMutation>(ADD_TRACK, options);
    const [removeTrack] = useMutation<TrackToggleCartButtonRemoveTrackMutation>(REMOVE_TRACK, options);
    const {data, loading} = useQuery(IS_AUTHENTICATED);
    const history = useHistory();

    const handleClick = useCallback((event) => {
        event.stopPropagation();
        const options = {variables: {trackId: props.trackid}};
        return props.incart ? removeTrack(options) : addTrack(options);
    }, [props.incart]);

    const handleAuthClick = useCallback((event) => {
        event.stopPropagation();
        history.push(ROUTES.AUTH.LOGIN);
    }, []);

    return isAuthenticated(data) ? (
        <Tooltip title={props.incart ? "Убрать из корзины" : "Добавить в корзину"}>
            <Button size={'small'} shape={'circle'} disabled={false} onClick={handleClick} style={props.style}>
                {props.incart ? <DeleteOutlined/> : <ShoppingCartOutlined/>}
            </Button>
        </Tooltip>
    ) : (
        <Tooltip title="Потребуется авторизация">
            <Button size={'small'} shape={'circle'} disabled={false} onClick={handleAuthClick} style={props.style}>
                <ShoppingCartOutlined/>
            </Button>
        </Tooltip>
    );
}
