import React from 'react';
import { TrackSiderFilterWrapper } from './TrackSiderFilterWrapper';
import { TrackSiderFilterForm } from './TrackSiderFilterForm';
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes";
import { Button } from "antd";

export function TrackSiderFilter(props: React.HTMLProps<HTMLDivElement>): JSX.Element {
  return (
    <TrackSiderFilterWrapper {...props}>
      <TrackSiderFilterForm />
      <Link to={ROUTES.COMMON.TRACKS.MAIN}>
        <Button type={"primary"} block={true}>Сброс</Button>
      </Link>
    </TrackSiderFilterWrapper>
  );
}
