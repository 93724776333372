import React from 'react';


const style = require('./TrackSiderFilterWrapper-style.module.css');

export function TrackSiderFilterWrapper(props: React.HTMLProps<HTMLDivElement>): JSX.Element {
  return (
    <div className={style.trackSiderFilterWrapper} {...props}>
      {props.children}
    </div>
  );
}
