import React from 'react';
import {getSampleSrc} from '../../../../utils';
import {DownloadOutlined} from '@ant-design/icons';
import {Button, Tooltip} from 'antd';

export const TrackTableDownloadDemoButton = (props: { sampleId: string }): JSX.Element => {
	return (
		<Tooltip title="Скачать демо">
			<Button
				 href={getSampleSrc(props.sampleId)}
				//href={`/listen/sample/${props.sampleId}`}
				download={true}
				size={'small'}
				shape={'circle'}
				onClick={e => {
					e.stopPropagation();
				}}
			>
				<DownloadOutlined/>
			</Button>
		</Tooltip>
	);
};
