import gql from 'graphql-tag';
import {IsAuthenticatedQuery} from '../__generated__/IsAuthenticatedQuery';

export const IS_AUTHENTICATED = gql`
  query IsAuthenticatedQuery {
    common {
      isAuthenticated
    }
     client {
      account {
      id
      newNotification
      composer {
          id
          onboardingFinished
        }
      }
    }
  }
`;

export const GET_ACCOUNT_QUERY = gql`
  query CabinetSideMenuGetAccountQuery {
    client {
      account {
        composer {
          id
          onboardingFinished
        }
        newNotification
        isAdmin
      }
    }
  }
`

export const PRIVACY_POLICY = gql`
    query privacyPolicy {
        common {
	      privacyPolicy {
	          id
	          text
	          title
	      }
	    }
	  }
	`;

export const IS_COMPOSER = gql`
  query isComposerOnboardingFinished {
    client {
      account {
        composer {
          id
          onboardingFinished
        }
      }
    }
  }
`;

export const isAuthenticated = (data: IsAuthenticatedQuery): boolean => {
    return !!data?.common?.isAuthenticated;
};
