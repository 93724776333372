import React from 'react';
import { CabinetPageTemplate } from '../cabinet/CabinetPageTemplate';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { ComposerGetPaymentQuery } from '../../__generated__/ComposerGetPaymentQuery';
import { ComposerManageCardsButton } from './ComposerManageCardsButton';
import { Spinner } from '../Spinner';
import { Result } from 'antd';

const COMPOSER_GET_PAYMENT_TOOLS_QUERY = gql`
  query ComposerGetPaymentQuery {
    client {
      composer {
        payment {
          get {
            PaymentTools {
              PaymentToolId
              PaymentTypeId
              Mask
            }
          }
        }
      }
    }
  }
`;

export function ComposerWithdrawPage(): JSX.Element {
  const { data, loading } = useQuery<ComposerGetPaymentQuery>(COMPOSER_GET_PAYMENT_TOOLS_QUERY);

  const paymentTools = data?.client?.composer?.payment?.get?.PaymentTools;
  //console.log('data, loading',data,loading);
  return (
    <CabinetPageTemplate>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {paymentTools?.length ? (
            <Result
              status={'success'}
              title={`Карта ${paymentTools[0].Mask} привязана`}
              extra={<ComposerManageCardsButton buttonText={'Изменить карту'} />}
            />
          ) : (
            <Result
              status={'warning'}
              title={'У Вас не привязана карта для получения вознаграждения'}
              extra={<ComposerManageCardsButton buttonText={'Привязать'} />}
            />
          )}
        </div>
      )}
    </CabinetPageTemplate>
  );
}
