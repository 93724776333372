import styles from '../../css/form.module.css';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {ROUTES} from '../../routes';
import cx from 'classnames';
import {Link, Redirect} from 'react-router-dom';
import React from 'react';
import {ValidationErrors} from './interfaces';
import {isEmail} from '../../utils';
import {FieldWrapper} from './FieldWrapper';
import {FORGOT_PASSWORD, LOGIN} from '../../graphql';
import {PayloadStatuses} from '../../types';
import {GET_ACCOUNT_QUERY, IS_AUTHENTICATED, isAuthenticated} from '../../graphql/common.queries';
import {Spin} from 'antd';
import {useMutation, useQuery} from 'react-apollo';
import {isComposer} from "./LoginForm";

const formValidationFunction = (values): ValidationErrors => {
    const errors: ValidationErrors = {};
    if (!values.login) {
        errors.login = 'Обязательно к заполнению';
    } else if (!isEmail(values.login)) {
        errors.login = 'Неверный формат email адреса';
    }
    return errors;
};

const GraphqlErrorMessages = ({errors}) =>
    errors.length && errors.map(({message}, key) => (
        <p data-tag="login-error" key={key} className={cx(styles.errorMsg, styles.formErrorMessage)}>
            {message}
        </p>
    ));

// export const isComposer = (data): boolean => {
//     return !!data?.client?.account?.composer?.onboardingFinished;
// };

export function ForgotForm(): JSX.Element {
    const {data: isAuthenticatedData} = useQuery(GET_ACCOUNT_QUERY, {fetchPolicy: "network-only"});

    //console.log("isAuthenticatedData ", isAuthenticatedData)

    // init GraphQL mutation routine
    const [forgotPassword, {data, error, loading}] = useMutation(FORGOT_PASSWORD, {
        //refetchQueries: [{query: IS_AUTHENTICATED},],
        //awaitRefetchQueries: true,
    });

    /*if ( data && data.common.auth.forgot.status === PayloadStatuses.SUCCESS ) {
    }*/

    return isComposer(isAuthenticatedData) ? (
        <Redirect to={ROUTES.CABINET.COMPOSER.NEW_TRACK}/>
    ) : isAuthenticated(isAuthenticatedData) ? (
        <Redirect to={ROUTES.CABINET.ORDERS}/>
    ) : (
        <div className={styles.formWrapper}>
            <h1 className={styles.formTitle}>Забыли пароль?</h1>
            <Formik
                initialValues={{login: ''}}
                validate={formValidationFunction}
                onSubmit={(values, {setSubmitting}): void => {
                    setSubmitting(false);

                    // post mutation to GraphQL server
                    //console.log('posting values', values);
                    forgotPassword({variables: values});
                }}
            >
                {({errors, isSubmitting}): JSX.Element => (
                    <Form className={styles.form}>
                        {data && data.common.auth.forgot.status === PayloadStatuses.Error && (
                            <GraphqlErrorMessages errors={data.common.auth.forgot.errors}/>
                        )}
                        {data && data.common.auth.forgot.status === PayloadStatuses.SUCCESS && (
                            <p className={cx(styles.errorMsg, styles.formErrorMessage)}>
                                Новый пароль сгенерирован и отправлен на Вашу почту.<br />
                                <Link to="/login">Продолжить</Link>
                            </p>
                        )}
                        <FieldWrapper>
                            <label htmlFor="reg_email" className={styles.label}>
                                Email адрес
                            </label>
                            <Field type="email" name="login"
                                   className={cx(styles.input, {[styles.inputErrored]: errors.login})}/>
                            <ErrorMessage name="login" component="div" className={styles.errorMsg}/>
                        </FieldWrapper>

                        <FieldWrapper>
                            <button type="submit" className={cx(styles.button, styles.buttonActive)}
                                    disabled={isSubmitting}>
                                {loading ? <Spin/> : 'Отправить новый пароль'}
                            </button>
                        </FieldWrapper>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
