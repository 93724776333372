import React, { Suspense, lazy } from 'react';
import { RegularPageTemplate } from '../RegularPageTemplate';
import { Collapse } from 'antd';
const { Panel } = Collapse;
import { useQuery } from '@apollo/react-hooks';

import gql from 'graphql-tag';


export function FaqPage(): JSX.Element {
    function callback(key) {
        console.log(key);
    }

    const COMMON_FAQ_FIND_ALL_QUERY = gql`
     query faq {
        common {
	      faq {
	          id
	          text
	          title
	          sort
	      }
	    }
	  }
	`;

    const {data, loading} = useQuery(COMMON_FAQ_FIND_ALL_QUERY);
    const texts = data?.common?.faq;


    return (
        <RegularPageTemplate style={{padding: 0, paddingBottom: 20}}>
            <div className={'container'}>
                <h2> FAQ </h2>
                {!texts ? <p>...Loading </p> : <Collapse >
                    {texts.sort((a, b) => a.sort - b.sort).map((el, i): JSX.Element => (
                            <Panel header={el.title} key={el.sort}>
                                <p>{el.text}</p>
                            </Panel>
                        )
                    )}
                </Collapse>
                }
            </div>
        </RegularPageTemplate>
    );
}