import React from 'react';
import { PlusOutlined } from '@ant-design/icons';


const styles = require('./UploadSampleButton-styles.module.css');

export const UploadSampleButton = (): JSX.Element => {
  return (
    <div className={styles.sampleUploadButton}>
      <input type="file" accept="" style={{ display: 'none' }} />
      <div>
        <PlusOutlined />
        <p className="ant-upload-text">Добавить версию трека</p>
      </div>
    </div>
  );
};
