// @ts-nocheck
import React, {FormEvent, useState} from 'react';
import axios from "axios"
import {Button, Input, Spin, Upload, message, Checkbox} from 'antd';
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {useMutation} from '@apollo/react-hooks';
import {Form} from '@ant-design/compatible';
import {FormComponentProps} from '@ant-design/compatible/es/form';
import {CREATE_DIRECTION_MUTATION} from './direction.graphql';
import {DirectionCreateMutation} from '../../__generated__/DirectionCreateMutation';
import {DIRECTIONS_TABLE_DIRACTIONS_FIND_ALL_QUERY} from './DirectionsTable';
import {CabinetPageTemplate} from "../cabinet/CabinetPageTemplate";
import {ROUTES} from "../../routes";
import {UploadProps} from "antd/es/upload";
import {PlusOutlined} from '@ant-design/icons';

export const CreateDirectionForm = (): JSX.Element => {
	/*const [createDirection, {loading}] = useMutation<DirectionCreateMutation>(CREATE_DIRECTION_MUTATION, {
		refetchQueries: [
			{
				query: DIRECTIONS_TABLE_DIRACTIONS_FIND_ALL_QUERY,
			},
		],
	});*/

	const [file, setFile] = useState<any | undefined>();
	const [active, setActive] = useState<boolean>(false);


	const AddDirectionForm = Form.create<FormComponentProps>({
		name: 'newDirectionForm',
	})((props: FormComponentProps) => {
		const {form} = props;
		const {getFieldDecorator, validateFields} = form;

		const handleFileChange = (e) => {
			if(!e.target.files) return
			setFile(e.target.files[0])
		};

		const submitHandler = (e: FormEvent<HTMLFormElement>): any => {
			e.preventDefault();
			let data = new FormData();

			validateFields((errors, values) => {
				data.append("direction", JSON.stringify({
					"sort": values.sort,
					"name": values.name,
					"active": active,
				}));
				if (file) data.append("file", file, file.name)
				if (!errors) {
					return saveDirection(data);
					/*return createDirection({
						variables: {input: values},
					});*/
					/*if (response.status !== 200) {
						throw new Error(`Request failed: ${response.status}`);
					}*/
				}
			});
		};

		const saveDirection = (data) => {
			axios.post(ROUTES.CABINET.ADMIN.DIRECTIONS_UPLOAD_IMAGE,
				data, {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				}
			).then((res) => {
				setFile(null);
				console.log(res);
				message.success('Сохранено');

			}).catch((e) => {
				console.log("error ", e);
				message.error('Произошла ошибка, попробуйте еще раз');
			});
			/*if (response.status !== 200) {
				throw new Error(`Request failed: ${response.status}`);
			}*/
		};

		return (
			<>
				<Form onSubmit={submitHandler} layout={'inline'}>

					<Form.Item>
					<input  onChange={handleFileChange}  type="file" accept="image/*" name="" id="" />
					</Form.Item>

					<Form.Item  label="Активность">
						{getFieldDecorator('active',)(
							<Checkbox
							style={{display: "inline"}}
							checked={active}
							onChange={(event: CheckboxChangeEvent): void => {
								setActive(event.target.checked);
							}}
						/>)}
					</Form.Item>

					<Form.Item>
						{getFieldDecorator('name', {
							rules: [
								{
									required: true,
									message: 'Пожалуйста, введите наименование направления!',
								},
							],
						})(<Input placeholder="Новое направление"/>)}
					</Form.Item>
					<Form.Item>
						{getFieldDecorator('sort', {
							rules: [
								{
									required: true,
									message: 'Укажите порядок',
								},
							],
						})(<Input placeholder="Сортировка"/>)}
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType={'submit'}>
							{'Добавить направление'}
						</Button>
					</Form.Item>
				</Form>
			</>
		);
	});

	return <AddDirectionForm/>;
};

export default CreateDirectionForm;
