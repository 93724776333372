import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { Header } from './header/Header';
import { Footer } from './Footer';
import { BackToTopArrow } from './BackToTopArrow';
import { AbsoluteFooter } from './AbsoluteFooter';

import { AudioPlayer } from './audioplayer';

const styles = require('./RegularPageTemplate-styles.module.css');

export const RegularPageTemplate = (props: React.HTMLProps<HTMLDivElement>): JSX.Element => {
  const [stuckHeader, setStuckHeader] = useState(false);
  const [backToTopArrowActive, setBackToTopArrowActive] = useState(false);

  function onTopReachedHandler(): void {
    setStuckHeader(false);
  }

  function onHeaderScrolledDownHandler(): void {
    setStuckHeader(true);
  }

  function onMediumEnter(): void {
    setBackToTopArrowActive(false);
  }

  function onMediumLeave(props: Waypoint.CallbackArgs): void {
    setBackToTopArrowActive(props.currentPosition === 'above');
  }

  return (
    <React.Fragment>
      <Waypoint onEnter={onTopReachedHandler} topOffset={'-30px'} />
      <Header stuck={stuckHeader} />
      <main className={styles.main} {...props}>
        <Waypoint onLeave={onHeaderScrolledDownHandler} />
        <Waypoint onEnter={onMediumEnter} onLeave={onMediumLeave} />

        {props.children}

        <BackToTopArrow active={backToTopArrowActive} />
      </main>
      <Footer />
      <AbsoluteFooter />
      <AudioPlayer />
    </React.Fragment>
  );
};
