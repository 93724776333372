import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { DateTimeFormat } from '../../config';
import { Form } from '@ant-design/compatible';
import { Table, Button, Popconfirm, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { format } from 'date-fns';

import { ActionEditButton } from '../table/ActionEditButton';
import { EditableTableCell } from '../table/EditableTableCell';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { sortByDate } from '../../utils';
import { ExecutionResult } from 'graphql';
import gql from 'graphql-tag';
import { PublishersTableFindQuery } from '../../__generated__/PublishersTableFindQuery';
import { PublisherDeleteMutation } from '../../__generated__/PublisherDeleteMutation';
import { PublisherPatchMutation } from '../../__generated__/PublisherPatchMutation';

const styles = require('./PublishersTable-styles.module.css');

export const PUBLISHERS_TABLE_FIND_QUERY = gql`
  query PublishersTableFindQuery {
    admin {
      publisher {
        find {
          id
          name
          createdAt
        }
      }
    }
  }
`;

const PUBLISHER_PATCH_MUTATION = gql`
  mutation PublisherPatchMutation($input: PublisherPatchInput) {
    admin {
      publisher {
        patch(input: $input) {
          status
          publisher {
            id
            name
          }
        }
      }
    }
  }
`;

const PUBLISHER_DELETE_MUTATION = gql`
  mutation PublisherDeleteMutation($id: String!) {
    admin {
      publisher {
        delete(id: $id) {
          status
        }
      }
    }
  }
`;

export const PublishersTable = Form.create<FormComponentProps>()(
  (props: FormComponentProps): JSX.Element => {
    const [editing, setEditing] = useState<string | undefined>(undefined);
    const { loading, data } = useQuery<PublishersTableFindQuery>(PUBLISHERS_TABLE_FIND_QUERY);
    const [publisherDelete, { loading: deleteLoading }] = useMutation<PublisherDeleteMutation>(
      PUBLISHER_DELETE_MUTATION,
      {
        refetchQueries: [{ query: PUBLISHERS_TABLE_FIND_QUERY }],
        onCompleted: () => {
          message.success('Издатель успешно удалён');
        },
      },
    );
    const [publisherPatch, { loading: patchLoading }] = useMutation<PublisherPatchMutation>(PUBLISHER_PATCH_MUTATION, {
      onCompleted: () => {
        message.success(`Издатель успешно обновлён`);
        setEditing(undefined);
      },
    });

    const { form } = props;
    const { resetFields, getFieldDecorator } = form;

    const isRowEditing = (id: string): boolean => editing === id;

    const handlePublisherSave = (): void => {
      props.form.validateFields((errors, values) => {
        return !errors && publisherPatch({ variables: { input: { ...values, id: editing } } });
      });
    };

    const handlePublisherDelete = (id: string): Promise<ExecutionResult> => publisherDelete({ variables: { id } });
    const dataSource = data?.admin?.publisher?.find?.sort(sortByDate('createdAt'));

    const ActionCell = (text, record): JSX.Element => {
      return (
        <div className={styles.actionsCell}>
          <ActionEditButton
            editing={isRowEditing(record.id)}
            onEditClick={(): void => {
              editing && resetFields();
              setEditing(record.id);
            }}
            onCancelClick={(): void => setEditing(undefined)}
            onSaveClick={handlePublisherSave}
            saveLoading={patchLoading}
          />
          <Popconfirm title="Удалить?" onConfirm={(): Promise<ExecutionResult> => handlePublisherDelete(record.id)}>
            <Button icon={<DeleteOutlined />} shape={'circle'} size={'small'} loading={deleteLoading} />
          </Popconfirm>
        </div>
      );
    };

    const columns = [
      {
        title: 'Издатель',
        dataIndex: 'name',
        editable: true,
      },
      {
        title: 'Дата создания',
        dataIndex: 'createdAt',
        render: (text, record): string => format(new Date(record.createdAt), DateTimeFormat),
      },
      {
        title: 'Действия',
        dataIndex: 'actions',
        render: ActionCell,
      },
    ].map(col =>
      col.editable
        ? {
            ...col,
            onCell: (record): object => ({
              name: col.dataIndex,
              title: col.title,
              editing: isRowEditing(record.id),
              record,
              getFieldDecorator,
            }),
          }
        : col,
    );

    const components = {
      body: {
        cell: EditableTableCell,
      },
    };

    return (
      <Table
        components={components}
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize: 50 }}
        rowKey={'id'}
      />
    );
  },
);
