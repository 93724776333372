import React from 'react';
import { CabinetPageTemplate } from '../cabinet/CabinetPageTemplate';
import GenresTable from './GenresTable';
import CreateGenreForm from './CreateGenreForm';

export const GenresAdminPage = (): JSX.Element => {
  return (
    <CabinetPageTemplate>
      <CreateGenreForm />
      <GenresTable />
    </CabinetPageTemplate>
  );
};

export default GenresAdminPage;
