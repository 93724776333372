import React, { HTMLAttributes } from 'react';
import { Badge } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { HeaderDesktopCartIconQuery } from '../../__generated__/HeaderDesktopCartIconQuery';
import { ROUTES } from '../../routes';
import { ShoppingCartIconFilled } from '../track/table/actions/TrackToggleCartButton';
import { NavLink } from 'react-router-dom';

export const CART_COUNT_QUERY = gql`
  query HeaderDesktopCartIconQuery {
    common {
      cart {
        count
      }
    }
  }
`;

export const HeaderDesktopCartIcon = (props: HTMLAttributes<HTMLAnchorElement>): JSX.Element => {
  const { data } = useQuery<HeaderDesktopCartIconQuery>(CART_COUNT_QUERY);
  const style = { fontSize: '20px' };

  return (
    <NavLink to={ROUTES.COMMON.CART.MAIN} exact={true} title="Cart" {...props}>
      <Badge count={data?.common?.cart?.count || 0}>
        {data?.common?.cart?.count ? <ShoppingCartIconFilled style={style} /> : <ShoppingCartOutlined style={style} />}
      </Badge>
    </NavLink>
  );
};
