import React, { useState, useEffect } from 'react';
import { CabinetPageTemplate } from '../cabinet/CabinetPageTemplate';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { AdminCarouselImagesFindQuery } from '../../__generated__/AdminCarouselImagesFindQuery';
import {AdminImagesTable } from "./AdminImagesTable";
import { Result, Form, Button, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { UploadProps } from 'antd/es/upload';
import { ROUTES } from '../../routes';
import {UploadImage} from "./carousel.interfaces"
import {ADMIN_CAROUSEL_IMAGES_FIND_QUERY} from '../../graphql/admin.queries'




interface SamplesFieldProps {
  files?: UploadImage[];
  onChange?(file: UploadImage[]): void;
}




export function AdminCarouselManagePage(): JSX.Element {
  const [fileList, setFileList] = useState<any | undefined>();

  const {data, loading} = useQuery<AdminCarouselImagesFindQuery>(ADMIN_CAROUSEL_IMAGES_FIND_QUERY);


  // useEffect(() => {
  //   if(data && data?.admin?.carouselImages?.find) {
  //     setFileList(images?.map((i) => {
  //       Object.assign(i, { uid: i?.id});
  //       Object.assign(i, { name: i?.filename})
  //     }));
  //   }
  // }, [data])

  function handleChange({fileList}): void {
    setFileList(fileList);
  }


  const uploadProps = {
    action: ROUTES.CABINET.ADMIN.UPLOAD_IMAGE,
    listType: 'picture',
    showUploadList: true,
    multiple: true,
    withCredentials: true,
    fileList,
  } as UploadProps;

  return (
      <CabinetPageTemplate>
        {/*loading ? (
        <Spinner/>
        ) : (*/}
        <Upload
            className={'AdminCarouselManagePage'}
            {...uploadProps}
            // fileList={state.fileList}
            // onPreview={this.handlePreview}
            onChange={handleChange}>
          <div>
            <PlusOutlined/>
            <div className="ant-upload-text">Загрузить</div>
          </div>
        </Upload>

        <AdminImagesTable />


      </CabinetPageTemplate>
  );

}