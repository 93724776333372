import React, { FormEvent } from 'react';
import { Button, Input, Spin } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { CREATE_GENRE_MUTATION, GENRES_FIND_ALL_QUERY } from './genre.graphql';

export const CreateGenreForm = (): JSX.Element => {
  const [createGenre, { loading }] = useMutation(CREATE_GENRE_MUTATION, {
    refetchQueries: [
      {
        query: GENRES_FIND_ALL_QUERY,
      },
    ],
  });

  const AddGenreForm = Form.create<FormComponentProps>({
    name: 'newGenreForm',
  })((props: FormComponentProps) => {
    const { getFieldDecorator, validateFields } = props.form;

    const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
      e.preventDefault();
      validateFields((errors, values) => {
        if (!errors) {
          return createGenre({
            variables: { input: values },
          });
        }
      });
    };

    return (
      <Form onSubmit={submitHandler} layout={'inline'}>
        <Form.Item>
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Пожалуйста, введите наименование жанра!',
              },
            ],
          })(<Input placeholder="Новый жанр" />)}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType={'submit'}>
            {loading ? <Spin /> : 'Добавить жанр'}
          </Button>
        </Form.Item>
      </Form>
    );
  });

  return <AddGenreForm />;
};

export default CreateGenreForm;
