import React from 'react';
import {Link} from 'react-router-dom';
import {ROUTES} from '../../../../routes';
import {Button, Tooltip} from 'antd';
import {EditOutlined} from '@ant-design/icons';

export const TrackTableAdminEditButton = (props: { trackId: string }): JSX.Element => {
	return (
		<Tooltip title="Редактировать">
			<Link to={ROUTES.CABINET.ADMIN.TRACKS.EDIT.replace(':id', props.trackId)}>
				<Button style={{
					marginRight: '5px',
				}} icon={<EditOutlined/>} size={'small'} shape={'circle'}/>
			</Link>
		</Tooltip>
	);
};
