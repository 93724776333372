import React from 'react';
import { Layout, Result, Button } from 'antd';
const { Content } = Layout;
import { CabinetPageTemplate } from '../../cabinet/CabinetPageTemplate';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { useQuery } from '@apollo/react-hooks';
import gql from "graphql-tag";
import { Spinner } from "../../Spinner";
import { CabinetSideMenuGetAccountQuery } from '../../../__generated__/CabinetSideMenuGetAccountQuery';
import { GET_ACCOUNT_QUERY } from '../../../graphql/common.queries';


export function CabinetMainPage(): JSX.Element {
  const { data, loading } = useQuery<CabinetSideMenuGetAccountQuery>(GET_ACCOUNT_QUERY);

  return (
    <CabinetPageTemplate>
      {loading ? (
        <Spinner />
      ) : data?.client?.account?.composer?.onboardingFinished ? (
        <></>
      ) : (
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            background: '#fff',
            minHeight: 280,
          }}
        >
          <div style={{ background: '#fff', textAlign: 'center' }}>
            <Result
              status="success"
              title="Вы вошли в замечательный кабинет!"
              subTitle="Теперь Вы можете начать покупать или продавать музыку."
              extra={[
                <Button type="primary" key="console">
                  Покупать
                </Button>,
                <Button key="buy">
                  <Link to={ROUTES.CABINET.COMPOSER.NEW}>Стать композитором</Link>
                </Button>,
              ]}
            />
          </div>
        </Content>
      )}
    </CabinetPageTemplate>
  );
}

