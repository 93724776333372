import { CONFIG, WALLET_ONE } from './config';

export const ROUTES = {
  COMMON: {
    MAIN: '/',
    CONTACTS: '/contacts',
    FAQ: '/faq',
    UNUSED: '/unused',
    AGREEMENT: '/agreement',
    BECOME_COMPOSER_INFO: '/become-composer-info',
    PRIVACY_POLICY: '/privacy-policy',
    CABINET: '/cabinet',
    SEARCH: '/search',
    LISTEN: {
      SAMPLE: CONFIG.HOST + '/listen/sample/{id}',
    },
    CONFIRM : '/confirm-order',
    ERROR_ORDER : '/error-order',
    TRACKS: {
      MAIN: '/tracks',
      LISTEN: CONFIG.HOST + '/listen/track/{id}',
      ADD_TO_CART: '/tracks/:id/add_to_cart',
      ADD_TO_FAVORITES: '/tracks/:id/add_to_favorites',
      DOWNLOAD_FILES: '/download/track/{id}/full',
    },
    CART: {
      MAIN: '/cart',
    },
  },
  AUTH: {
    LOGIN: '/login',
    SIGN_UP: '/signup',
    FORGOT__PASSWORD: '/forgotpassword',
    LOGOUT: '/logout',
    SIGN_UP_COMPOSER: '/signup-composer',
  },
  CABINET: {
    MAIN: '/cabinet',
    PROFILE: '/cabinet/profile',
    FAVORITES: '/cabinet/favorites',
    ORDERS: '/cabinet/orders',
    COMPOSER: {
      ABOUT: '/cabinet/composer/about',
      INN: '/cabinet/composer/inn',
      REQUISITES: '/cabinet/composer/requisites',
      NEW_TRACK: '/cabinet/composer/track/new',
      NEW: '/cabinet/composer/new',
      PASSPORT: '/cabinet/composer/passport',
      PAYMENT: '/cabinet/composer/payment',
      UPLOAD_SAMPLE: CONFIG.HOST + '/cabinet/composer/sample/upload',
      TRACKS: {
        NEW: '/cabinet/composer/track/new',
        LIST: '/cabinet/composer/track',
        EDIT: '/cabinet/composer/track/:id/edit',
      },
      WITHDRAW: {
        MAIN: '/cabinet/composer/withdraw',
        MANAGE_CARDS: WALLET_ONE.BENEFICIARY,
      },
      SALES: '/cabinet/composer/sales',
    },
    ADMIN: {
      MAIN: '/cabinet/admin',
      ACCOUNTS: {
        MAIN: '/cabinet/admin/accounts',
        EDIT: '/cabinet/admin/accounts/:id/edit',
      },
      TRACKS: {
        LIST: '/cabinet/admin/tracks/',
        NEW: '/cabinet/admin/new-tracks/',
        EDIT: '/cabinet/admin/tracks/:id/edit',
      },
      GENRES: '/cabinet/admin/genres',
      INSTRUMENTS: '/cabinet/admin/instruments',
      DIRECTIONS: '/cabinet/admin/directions',
      MOODS: '/cabinet/admin/moods',
      PUBLISHERS: '/cabinet/admin/publishers',
      CAROUSEL: '/cabinet/admin/carousel',
      FAQ: '/cabinet/admin/faq',
      AGREEMENT: '/cabinet/admin/agreement',
      PRIVACY_POLICY: '/cabinet/admin/privacy-policy',
      BECOME_COMPOSER_INFO: '/cabinet/admin/become-composer-info',
      UPLOAD_IMAGE: CONFIG.HOST + '/cabinet/admin/carousel/upload',
      DIRECTIONS_UPLOAD_IMAGE: CONFIG.HOST + '/cabinet/admin/directions/upload',
    },
  },
  TEST: '/test',
};
