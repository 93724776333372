import React from 'react';
import { Tag } from 'antd';
import { TRACK_STATUS } from '../track.interfaces';

export const TrackTableStatusCell = (status?: number): JSX.Element => {
  switch (status) {
    case TRACK_STATUS.NEW.value:
      return <Tag color={'orange'}>{TRACK_STATUS.NEW.text}</Tag>;
    case TRACK_STATUS.NOT_PUBLISHED.value:
      return <Tag color={'magenta'}>{TRACK_STATUS.NOT_PUBLISHED.text}</Tag>;
    case TRACK_STATUS.PUBLISHED.value:
      return <Tag color={'green'}>{TRACK_STATUS.PUBLISHED.text}</Tag>;
    case TRACK_STATUS.REJECTED.value:
      return <Tag color={'red'}>{TRACK_STATUS.REJECTED.text}</Tag>;
    case TRACK_STATUS.WITHDRAWED.value:
      return <Tag color={'blue'}>{TRACK_STATUS.WITHDRAWED.text}</Tag>;
    default:
      return <span>Отсутствует</span>;
  }
};

export const TrackStatusSorter =  (status?: number) => {
   return (a, b) => a.status - b.status
}

