import {Table, Result, Popconfirm, message} from 'antd';
import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { AdminCarouselImagesFindQuery } from '../../__generated__/AdminCarouselImagesFindQuery';
import { format } from 'date-fns';
import { DateTimeFormat } from '../../../src/config';
import { ExecutionResult } from 'graphql';
import {ADMIN_CAROUSEL_IMAGES_FIND_QUERY} from '../../graphql/admin.queries'



const CAROUSEL_IMAGE_DELETE_MUTATION = gql`
  mutation CarouselImageDeleteMutation ($id: String!) {
    admin {
      carouselImages {
        delete(id: $id) {
          status
        }
      }
    }
  }
`;



export function AdminImagesTable(): JSX.Element {

	const [deleteImage] = useMutation(CAROUSEL_IMAGE_DELETE_MUTATION,  {
		refetchQueries: [{ query: ADMIN_CAROUSEL_IMAGES_FIND_QUERY }],
		onCompleted: () => {
			message.success('Изображение удалено');
		},
	});

	function handleDelete(i): Promise<ExecutionResult> {
		console.log(i.key)
		return deleteImage ( {variables: {
				id: i.key
			}})
	};

	const { data, loading } = useQuery<AdminCarouselImagesFindQuery>(ADMIN_CAROUSEL_IMAGES_FIND_QUERY);
	const images = [{}] ;
	const text = 'Вы уверены, что хотите удалить изображение?';

	data?.admin?.carouselImages?.find?.map((img: any) => {
		images.push({
			key: img.id,
			preview: img.url,
			filename: img.filename,
			uploadedAt: format(new Date(img.createdAt), DateTimeFormat) ,
		})
		return images;
	});
	images.shift()

	console.log("Картиночки", images)
	console.log("Картиночки images?.length", images?.length)


	const columns = [
		{ title: 'Предпросмотр', dataIndex: 'preview', key: 'preview', render:  (i: any, rec ) => { return <img style={{width: "150px"}} src={'/' + i} /> } },
		{ title: 'Имя файла', dataIndex: 'filename', key: 'filename' },
		{ title: 'Дата загрузки', dataIndex: 'uploadedAt', key: 'uploadedAt',  },
		{
			title: 'Action',
			dataIndex: '',
			key: 'x',
			render: (i) => <Popconfirm title={text} onConfirm= {() => { handleDelete(i) }}> <a>Delete</a> </Popconfirm>
		}
	];


	return (
		<Table
			columns={columns}
			dataSource={images}
		/>
	);

}