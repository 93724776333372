import gql from "graphql-tag";

export const TRACK_FIND_BY_ID = gql`
  query AdminTrackEditPageFindByIdQuery($id: String!) {
    common {
      track {
        findById(id: $id) {
          id
          name
          author
          coauthor
          price
          publisherId
          genres {
            name
          }
          instruments {
            name
          }
          moods {
            name
          }
          directions {
            name
          }
          bpm
          description
          status
          samples {
            id
            name
            description
          }
        }
      }
    }
  }
`;

export const ADMIN_CAROUSEL_IMAGES_FIND_QUERY = gql`
  query AdminCarouselImagesFindQuery {
    admin {
      carouselImages {
        find {
          id
          filename
          url
          active
          createdAt
        }
      }
    }
  }
`;

export const ADMIN_TRACKS_PAGE_TABLE_FIND_ALL_QUERY = gql`
  fragment AminNewTracksPageTableTrackFragment on Track {                              
                                                                                     
               id                                                                    
               name                                                                  
               authorName                                                            
               article                                                               
               bpm                                                                   
               price                                                                 
               mainSampleId                                                          
               description                                                           
               status                                                                
               publisher {                                                           
                 name                                                                
               }                                                                     
               createdAt                                                             
               samples {                                                             
                 id                                                                  
                 name                                                                
                 duration                                                            
               }                                                                     
               mainSampleId                                                          
             }                                                                       
                                                                                     
query AdminTracksPageTableFindAllQuery($filter: TrackAdminFindFilterInput) {      
  admin {                                                                            
    track {                                                                          
      find(filter: $filter) {                                                        
        ...AminNewTracksPageTableTrackFragment                                       
      }                                                                              
    }                                                                                
  }                                                                                  
}                                                                                    
`;

export const ADMIN_NEW_TRACKS_PAGE_TABLE_FIND_ALL_QUERY = gql`
  fragment AdminNewTracksPageTableTrackFragment on Track {
       
                 id
                 name
                 authorName
                 article
                 bpm
                 price
                 mainSampleId
                 description
                 status
                 publisher {
                   name
                 }
                 createdAt
                 samples {
                   id
                   name
                   duration
                 }
                 mainSampleId
               }
  
  query AdminNewTracksPageTableFindAllQuery($filter: TrackAdminFindFilterInput) {
    admin {
      track {
        find(filter: $filter) {
          ...AdminNewTracksPageTableTrackFragment
        }
      }
    }
  }
`;