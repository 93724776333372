import React from 'react';
import { CabinetPageTemplate } from '../cabinet/CabinetPageTemplate';
import MoodsTable from './MoodsTable';
import CreateMoodForm from './CreateMoodForm';

export const MoodsAdminPage = (): JSX.Element => {
  return (
    <CabinetPageTemplate>
      <CreateMoodForm />
      <MoodsTable />
    </CabinetPageTemplate>
  );
};

export default MoodsAdminPage;
