import React, { FormEvent, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';

import cx from 'classnames';
import { ROUTES } from '../../routes';
import { useHistory, useLocation } from 'react-router';

const styles = require('./HeaderSearch-styles.module.css');

export function HeaderSearch(): JSX.Element {
  const [text, setText] = useState<string>('');
  const { push } = useHistory();
  const search = new URLSearchParams(useLocation().search);
  function handleTextChange(event: FormEvent<HTMLInputElement>): void {
    setText(event.currentTarget.value);
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    if (text.trim().length > 0) {
      search.set('text', text);
      push(`${ROUTES.COMMON.SEARCH}?${search.toString()}`);
    }
  }

  return (
    <form role="search" method="get" action={ROUTES.COMMON.SEARCH} onSubmit={handleSubmit}>
      <div className="d-flex">
        <div className={styles.searchWrapper}>
          <input
            type="search"
            className={cx('search-field mb-0', styles.search)}
            name="text"
            placeholder="Search…"
            autoComplete="off"
            value={text}
            onChange={handleTextChange}
          />
        </div>
        <div className={styles.btnWrapper}>
          <button type="submit" className={styles.btn}>
            <SearchOutlined />
          </button>
        </div>
      </div>

      <div className="live-search-results text-left z-top">
        <div
          className="autocomplete-suggestions"
          style={{ position: 'absolute', display: 'none', maxHeight: '300px', zIndex: 9999 }}
        />
      </div>
    </form>
  );
}
