import React from 'react';
import { Form, Select, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { INSTRUMENTS_FIND_ALL_QUERY } from './instrument.graphql';
import { InstrumentsFindAllQuery } from '../../__generated__/InstrumentsFindAllQuery';

export function InstrumentsField(): JSX.Element {
  const { loading, data } = useQuery<InstrumentsFindAllQuery>(INSTRUMENTS_FIND_ALL_QUERY);

  const valueList = data?.common?.instruments || [];

  return (
    <Form.Item
      name={'instruments'}
      label={
        <span>
          Инструмент&nbsp;
          <Tooltip title="Выберете из списка или добавьте свой">
            <QuestionCircleOutlined />
          </Tooltip>
        </span>
      }
      rules={[
        ({ setFieldsValue }) => ({
          validator: (rule, value): Promise<void> => {
            if (value && value.length > 3) {
              setFieldsValue({ instruments: value.slice(0, 3) });
              return Promise.reject('Пожалуйста, укажите не более трёх инструментов!');
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <Select mode={'tags'} placeholder={'Фортепьяно'} maxTagCount={3} loading={loading}>
        {valueList.map((instrument: { name: string }) => (
          <Select.Option value={instrument.name} key={'instrument' + instrument.name}>
            {instrument.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}
