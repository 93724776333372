import React, { Ref } from 'react';
import { Select } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { PublisherFieldFindAllQuery } from '../../__generated__/PublisherFieldFindAllQuery';
import { SelectValue } from 'antd/es/select';

export const PUBLISHER_FIELD_FIND_ALL_QUERY = gql`
  query PublisherFieldFindAllQuery {
    admin {
      publisher {
        find {
          id
          name
        }
      }
    }
  }
`;

function PublisherSelect(props, ref: Ref<Select<SelectValue>>): JSX.Element {
  const { loading, data } = useQuery<PublisherFieldFindAllQuery>(PUBLISHER_FIELD_FIND_ALL_QUERY);
  const valueList = data?.admin?.publisher?.find || [];

  return (
    <Select {...props} loading={loading} ref={ref}>
      <Select.Option key={'no-publisher'} value={''}>
        Без издателя
      </Select.Option>
      {valueList.map(val => (
        <Select.Option value={val.id} key={val.id}>
          {val.name}
        </Select.Option>
      ))}
    </Select>
  );
}

export const PublisherSelectField = React.forwardRef(PublisherSelect);
