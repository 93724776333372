// адрес и порт бэкенда (API) - там, где слушает NodeJS - должен быть тот же, что у клиента!
// см. также proxy в package.json – эти адреса должны совпадать!
//export const HOST = 'http://localhost:4000';
//export const HOST = 'https://new.bmstock.ru';
//export const HOST = 'http://spider.playnext.net:49090';
//export const HOST = 'http://localhost:3000';
//export const HOST = 'http://bmstock.ms.dev.playnext.net';
//export const HOST = 'http://localhost:3001';
//export const HOST = 'https://bmstock.ru'; // живой хост! не использует порт! (см. настройку nginx)
export const HOST = window.location.origin;

export const CONFIG = {
  HOST,
  GQL_GATEWAY: `${HOST}/graphql`,
};
console.log("HOST: ", HOST)

export const WALLET_ONE = {
  GATEWAY: 'https://api.dev.walletone.com/p2p/',
  BENEFICIARY: 'https://api.dev.walletone.com/p2p/v2/beneficiary',
};

export const DateTimeFormat = 'dd.MM.yyyy HH:mm:ss';
