import React, { FormEvent } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Form } from '@ant-design/compatible';
import { Input, Spin, Button } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import gql from "graphql-tag";
import { MOODS_TABLE_MOODS_FIND_ALL_QUERY } from './MoodsTable';

const CREATE_MOOD_MUTATION = gql`
  mutation CreateMoodMutation($input: CreateMoodInput) {
    admin {
      mood {
        create(input: $input) {
          status
          mood {
            name
          }
        }
      }
    }
  }
`;

export const CreateMoodForm = (): JSX.Element => {
  const [createMood, { loading }] = useMutation(CREATE_MOOD_MUTATION, {
    refetchQueries: [
      {
        query: MOODS_TABLE_MOODS_FIND_ALL_QUERY,
      },
    ],
  });

  const AddMoodForm = Form.create<FormComponentProps>({
    name: 'newMoodForm',
  })((props: FormComponentProps) => {
    const { form } = props;

    const { getFieldDecorator, validateFields } = form;

    const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
      e.preventDefault();
      validateFields((errors, values) => {
        if (!errors) {
          return createMood({
            variables: { input: values },
          });
        }
      });
    };

    return (
      <Form onSubmit={submitHandler} layout={'inline'}>
        <Form.Item>
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Пожалуйста, введите наименование настроения!',
              },
            ],
          })(<Input placeholder="Новое настроение" />)}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType={'submit'}>
            {loading ? <Spin /> : 'Добавить настроение'}
          </Button>
        </Form.Item>
      </Form>
    );
  });

  return <AddMoodForm />;
};

export default CreateMoodForm;
