import React from 'react';
import { Form, Select, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { GENRES_FIND_ALL_QUERY } from './genre.graphql';
import { GenresFindAllQuery } from '../../__generated__/GenresFindAllQuery';

export function GenresField(): JSX.Element {
  const { data, loading } = useQuery<GenresFindAllQuery>(GENRES_FIND_ALL_QUERY);
  const valueList = data?.common?.genres || [];

  return (
    <Form.Item
      name={'genres'}
      label={
        <span>
          Жанр&nbsp;
          <Tooltip title="Выберете из списка или добавьте свой">
            <QuestionCircleOutlined />
          </Tooltip>
        </span>
      }
      rules={[
        ({ setFieldsValue }) => ({
          validator: (rule, value): Promise<void> => {
            if (value && value.length > 3) {
              setFieldsValue({ genres: value.slice(0, 3) });
              return Promise.reject('Пожалуйста, укажите не более трёх жанров!');
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <Select mode={'tags'} placeholder={'Соул'} loading={loading}>
        {valueList.map((genre: { name: string }) => (
          <Select.Option value={genre.name} key={'genre' + genre.name}>
            {genre.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}
