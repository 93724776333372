import React from 'react';
import {RegularPageTemplate} from '../RegularPageTemplate';
import {Layout} from 'antd';
import {CabinetSideMenu} from './CabinetSideMenu';
import {IS_AUTHENTICATED, isAuthenticated,} from '../../graphql/common.queries';
import {Redirect} from 'react-router';
import {ROUTES} from '../../routes';
import {useQuery} from '@apollo/react-hooks';
import {Spinner} from '../Spinner';
import gql from "graphql-tag";
import {CabinetSideMenuGetAccountQuery} from '../../__generated__/CabinetSideMenuGetAccountQuery';
import {GET_ACCOUNT_QUERY} from '../../graphql/common.queries';


export function ComposerPageTemplate(props: React.HTMLProps<HTMLDivElement>): JSX.Element {
    const {loading, data} = useQuery<CabinetSideMenuGetAccountQuery>(GET_ACCOUNT_QUERY);
    const {loading: loadingAuth, data: dataAuth} = useQuery(IS_AUTHENTICATED, {fetchPolicy: "network-only"});
    console.log("auth", dataAuth?.common?.isAuthenticated)
    console.log("data Composer", dataAuth?.client?.account?.composer?.id)

    return loadingAuth || loading ? (<Spinner/>) :
        (dataAuth?.client?.account?.composer?.id) ? (
            <RegularPageTemplate style={{padding: 0}}>
                <div className={'container'}>
                    <Layout>
                        <CabinetSideMenu/>
                        <Layout.Content
                            style={{
                                padding: '24px 0 24px 24px',
                                background: '#fff',
                                minHeight: 280,
                            }}
                        >
                            {props.children}
                        </Layout.Content>
                    </Layout>
                </div>
            </RegularPageTemplate>
        ) : (<Redirect to={ROUTES.CABINET.COMPOSER.NEW}/>);
}

// : (!dataAuth?.common?.isAuthenticated && !dataAuth?.client?.account?.composer?.id) ?