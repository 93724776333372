import gql from "graphql-tag";



export const BECOME_A_COMPOSER_ABOUT_STEP_MUTATION = gql`
  mutation BecomeAComposerAboutStep($input: BecomeAComposerAboutStepInput) {
    client {
      composer {
        onboarding {
          about(input: $input) {
            status
            errors {
              message
            }
          }
        }
      }
    }
  }
`;

export const PATCH_INN_MUTATION = gql`
  mutation PatchINNMutation($input: INNPatchITC) {
    client {
      composer {
        patchINN(input: $input) {
          status
          errors {
            message
          }
        }
      }
    }
  }
`;

export const COMPOSER_ONBOARDING_DOCUMENT_STEP_MUTATION = gql`
  mutation ComposerOnboardingDocumentsStep($input: BecomeAComposerDocumentsStepInput) {
    client {
      composer {
        onboarding {
          documents(input: $input) {
            status
            errors {
              message
            }
          }
        }
      }
    }
  }
`;

export const TRACK_DELETE_MUTATION = gql`
  mutation TrackDeleteMutation($id: String!) {
    admin {
      track {
        delete(id: $id) {
          status
        }
      }
    }
  }
`;
