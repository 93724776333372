import React from 'react';

import {getSampleSrc, getStringDuration} from '../../../utils';
import {TableTrack, TrackTable} from './TrackTable';
import {TablePlayButton} from '../../audioplayer';
import {useAudioPlayer} from '../../audioplayer/useAudioPlayer';
import {useHistory, useLocation} from "react-router";
import {TrackTableSameTracksCell} from "./TrackTableSameTracksCell";
import gql from 'graphql-tag';
import {useQuery} from '@apollo/react-hooks';
import {TrackTableDownloadDemoButton} from "./actions/TrackTableDownloadDemoButton";
import {TrackToggleFavoritesButton} from "./actions/TrackToggleFavoritesButton";
import {TrackToggleCartButton} from "./actions/TrackToggleCartButton";
import {RegularPageTemplate} from "../../RegularPageTemplate";


const styles = require('./TrackTableExpandedRowContent-styles.module.css');

const COMMON_FIND_BY_ID = gql`
 query findById($id: String!) {
    common {
      track {
        findById(id: $id) {
          id
          name
          author
          authorName
          isFavorite
          inCart
          genres {
            name
          }
          directions {
            name
          }
          instruments {
            name
          }
          moods {
            name
          }
          bpm
          description
          samples {
            id
            name
            description
          }
        }
      }
    }
  }
`;


export const TrackTableExpandedRowContent = (track: TableTrack): JSX.Element => {
	const {currentTrack, play, player} = useAudioPlayer();
	const {push} = useHistory();
	const query = new URLSearchParams(useLocation().search);

	// const { loading: findTrackLoading, data: findTrackData } = useQuery(COMMON_FIND_BY_ID, {
	//   variables: {
	//     id: track.id
	//   },
	// });
	// console.log("findTrackData", findTrackData);

	function handleSelectChange(values, name): void {
		let keys: Array<string> = [];
		for (let key of query.keys()) {
			console.log('key', key);
			keys.push(key)
		}
		for (let key of keys) {
			query.delete(key);
		}
		query.set(name, values);
		push(`/tracks?${query}`);
	}


	return (
		<div className={styles.wrapper}>
			<dl className={styles.details}>
				<dt>Наименование</dt>
				<dd>{track.name}</dd>
				<dt>Артикул</dt>
				<dd>{track.article}</dd>
				<dt>Автор</dt>
				{/*<dd>{track.authorName}</dd>*/}
				<dd>
					<a className={styles.trackExpandEl}
					   onClick={(): void => handleSelectChange(track.authorName, "authorName")}>
						{track.authorName}
					</a>
				</dd>

				{track.coauthor && (
					<React.Fragment>
						<dt>Соавтор</dt>
						<dd>
							{(track?.coauthor?.replaceAll('"', "").split(',').join(", ")
							)}
						</dd>
					</React.Fragment>
				)}

				{track.publisher && (
					<React.Fragment>
						<dt>Издатель</dt>
						<dd>{track.publisher?.name}</dd>
					</React.Fragment>
				)}

				<dt>Жанры</dt>
				<dd>
					{/*{track?.genres?.map(genre => genre.name).join(', ') || ''}*/}
					{track?.genres?.map((genre, index, genres) =>
						<a className={styles.trackExpandEl}
						   onClick={(): void => handleSelectChange(genre.name, 'genres')}>
							{genre.name}
							{index < genres.length - 1 && <span>, </span>}
						</a>
					)}
				</dd>

				<dt>Направления</dt>
				<dd>
					{/*{track?.genres?.map(genre => genre.name).join(', ') || ''}*/}
					{track?.directions?.map((direction, index, directions) =>
						<a className={styles.trackExpandEl}
						   onClick={(): void => handleSelectChange(direction.name, 'directions')}>
							{direction.name}
							{index < directions.length - 1 && <span>, </span>}
						</a>
					)}
				</dd>

				<dt>Инструмент</dt>
				<dd>
					{/*<dd>{track?.instruments?.map(instrument => instrument.name).join(', ') || ''}</dd>*/}
					{track?.instruments?.map((instrument, index, instruments) =>
						<a className={styles.trackExpandEl}
						   onClick={(): void => handleSelectChange(instrument.name, 'instruments')}>
							{instrument.name}
							{index < instruments.length - 1 && <span>, </span>}
						</a>)
					}
				</dd>

				<dt>Настроение</dt>

				<dd>
					{/*{track?.moods?.map(mood => mood.name).join(', ') || ''}*/}
					{track?.moods?.map((mood, index, moods) =>
						<a  key={index}
							className={styles.trackExpandEl}
						   onClick={(): void => handleSelectChange(mood.name, 'moods')}>
							{mood.name}
							{index < moods.length - 1 && <span>, </span>}
						</a>
					)}
				</dd>
				<dt>Темп</dt>
				<dd>{track.bpm}</dd>
				<dt>Время</dt>
				<dd>{getStringDuration(track.samples[0]?.duration)}</dd>


			</dl>
			<div className={styles.samplesWrapper}>
				<table  className={styles.samplesTable} style={{marginLeft: 5}}>
					<tbody>
					{track.samples.map((sample, index) => (
						<tr key={sample.id} className={styles.sampleRow}>
							<td>
								<TablePlayButton
									onClick={(): void => {
										play({
											name: sample.name,
											src: getSampleSrc(sample.id),
										});
									}}
									playing={currentTrack.src === getSampleSrc(sample.id) && !player.paused}
									style={{fontSize: '1.5rem'}}
								/>
							</td>
							<td>
								{track.name} {index > 0 && ` - ${index + 1}`}
							</td>
							<td>{getStringDuration(sample.duration)}</td>
							<td style={{minWidth: "120px"}}>{sample.description}</td>
						</tr>
					))}
					</tbody>
				</table>
			</div>

			{(track.getSameTracks && track.getSameTracks.length > 0) && (
				<React.Fragment>
					<div  style={{marginTop: '20px', paddingTop: "20px", width: '100%', marginLeft: "0"}}>
						<div style={{margin: 'auto', marginBottom: '10px', marginLeft: '65px', fontWeight: 500 }}>Похожие треки</div>
						<TrackTable
							className={styles.sameTracksTable}
							tracks={track.getSameTracks}
							pagination={false}
							cols={{
								sameTrack: false,
								duration: true,
								bpm: true,
								price: true,
								actions: {
									demo: true,
									favorite: true,
									cart: true,
								},
							}}
							style={{ width: '100%' }}
							expandable={{
								expandedRowRender: TrackTableExpandedRowContent,
								expandRowByClick: true,
							}}
						/>
					</div>
					{/*<div className={styles.samplesWrapper}*/}
					{/*     style={{marginTop: '20px', paddingTop: "20px", borderTop: '0.5px solid #d9d9d9'}}>*/}
					{/*		<div style={{marginLeft: '15px', marginBottom: '10px', fontWeight: 500 }}>Похожие треки</div>*/}
					{/*	<table className={styles.samplesTable} style={{width: '75%'}}>*/}
					{/*		<tbody>*/}
					{/*		{track?.getSameTracks?.map((el, i) => <React.Fragment>*/}
					{/*				<tr key={el.name} style={{marginBottom: '5px'}}>*/}
					{/*					<td >*/}
					{/*						{(el.samples && el.samples.length > 0) && (*/}
					{/*							<React.Fragment>*/}
					{/*								<TablePlayButton*/}
					{/*									onClick={(): void => {*/}
					{/*										play({*/}
					{/*											name: el.name,*/}
					{/*											src: getSampleSrc(el.samples[0].id),*/}
					{/*										});*/}
					{/*									}}*/}
					{/*									playing={currentTrack.src === getSampleSrc(el.samples[0].id) && !player.paused}*/}
					{/*								/>*/}
					{/*							</React.Fragment>*/}
					{/*						)}*/}
					{/*					</td>*/}
					{/*					<td>*/}
					{/*						{el.name}*/}
					{/*					</td>*/}
					{/*					<td>*/}
					{/*						{ el.samples.length > 0 && !!el.samples[0].duration ?  getStringDuration(el.samples[0].duration) : "" }*/}
					{/*					</td>*/}
					{/*					<td>*/}
					{/*						{el.authorName}*/}
					{/*					</td>*/}

					{/*					<td>*/}
					{/*						<TrackTableDownloadDemoButton sampleId={el.samples[0].id} />*/}
					{/*						<TrackToggleFavoritesButton trackid={el.id} isfavorite={el.isFavorite || false} />*/}
					{/*						<TrackToggleCartButton trackid={el.id} incart={el.inCart || false} />*/}
					{/*					</td>*/}


					{/*				</tr>*/}
					{/*			</React.Fragment>*/}
					{/*		)}*/}
					{/*		</tbody>*/}
					{/*	</table>*/}
					{/*</div>*/}
				</React.Fragment>
			)}
		</div>)
};
