import React from 'react';
import { Button, Typography } from 'antd';

import { ROUTES } from '../../routes';
import { NavLink } from 'react-router-dom';

const style = require('./SearchEmptyText-style.module.css');

export function SearchEmptyText(props: React.HTMLProps<HTMLDivElement>): JSX.Element {
  return (
    <div className={style['wrapper']} {...props}>
      <Typography.Title level={3}>По заданным параметрам треков не найдено</Typography.Title>
      <Typography.Paragraph>Попробуйте воспользоваться фильтром</Typography.Paragraph>
      <NavLink to={ROUTES.COMMON.TRACKS.MAIN}>
        <Button type={'primary'}>Продолжить поиск</Button>
      </NavLink>
    </div>
  );
}
