import React from 'react';
import { CabinetPageTemplate } from '../cabinet/CabinetPageTemplate';
import { Form, Input, Typography, Button, message, Spin, Checkbox } from 'antd';
import { spaceBetweenFormLayout } from '../form/options';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router';
import gql from 'graphql-tag';
import { AdminAccountEditPageFindQuery } from '../../__generated__/AdminAccountEditPageFindQuery';
import { Divider } from 'antd/es';
import { AdminAccountEditPageMutation } from '../../__generated__/AdminAccountEditPageMutation';
import { ACCOUNT_ADMIN_PAGE_FIND_ALL_QUERY } from './AdminAccountTablePage';
import { ExecutionResult } from 'graphql';

const ADMIN_ACCOUNT_EDIT_PAGE_FIND_QUERY = gql`
  query AdminAccountEditPageFindQuery($id: String!) {
    admin {
      account {
        findById(id: $id) {
          id
          passport {
            secondName
            firstName
            thirdName
            series
            number
            registrationAddress
          }
          composer {
            id
            nickname
            showNickname
            howlong
            instruments
            styles
            liveInstruments
            readyToOrder
            readyToRemake
          }
          email
          mobileNumber
          vk
          facebook
          inn {
            id
            number
          }
        }
      }
    }
  }
`;

const ADMIN_ACCOUNT_EDIT_PAGE_MUTATION = gql`
  mutation AdminAccountEditPageMutation($input: AdminAccountPatchInput!) {
    admin {
      account {
        patch(input: $input)
      }
    }
  }
`;

function AccountEditForm(): JSX.Element {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();
  const { data: findAccountData, loading: findAccountLoading } = useQuery(
    ADMIN_ACCOUNT_EDIT_PAGE_FIND_QUERY,
    {
      variables: { id },
    },
  );

  const [patchAccount, { loading }] = useMutation<AdminAccountEditPageMutation>(ADMIN_ACCOUNT_EDIT_PAGE_MUTATION, {
    refetchQueries: [
      {
        query: ACCOUNT_ADMIN_PAGE_FIND_ALL_QUERY,
      },
      {
        query: ADMIN_ACCOUNT_EDIT_PAGE_FIND_QUERY,
        variables: {
          id,
        },
      },
    ],
    onCompleted: data => {
      if (data.admin?.account?.patch === 'success') {
        message.success('Пользователь сохранён успешно!');
        history.goBack();
      } else {
        message.error('Произошла ошибка во время сохранения.');
      }
    },
  });

  // const [patchTrack, { loading: patchTrackLoading }] = useMutation<ComposerTrackPatchMutation>(
  //   COMPOSER_TRACK_PATCH_MUTATION,
  //   {
  //     refetchQueries: [
  //       {
  //         query: COMPOSER_TRACKS_FIND_ALL_QUERY,
  //       },
  //       {
  //         query: COMPOSER_TRACK_EDIT_FIND_BY_ID,
  //         variables: { id },
  //       },
  //     ],
  //     onCompleted: data => {
  //       if (data.client?.composer?.track?.patch?.status === PayloadStatuses.SUCCESS)
  //         history.push(ROUTES.CABINET.COMPOSER.TRACKS.LIST);
  //     },
  //   },
  // );

  const account = findAccountData?.admin?.account?.findById;

  const handleSubmit = (values): Promise<ExecutionResult> => {
    const input = {
      id,
      ...values,
    };

    return patchAccount({
      variables: {
        input,
      },
    });
  };

  return findAccountLoading || !account ? (
    <Spin />
  ) : (
      <div  className="container">
    <Form
      {...spaceBetweenFormLayout}
      form={form}
      initialValues={{
        secondName: account.passport?.secondName,
        firstName: account.passport?.firstName,
        thirdName: account.passport?.thirdName,
        email: account.email,
        phone: account.mobileNumber,
        facebook: account.facebook,
        vk: account.vk,
        series: account.passport?.series,
        number: account.passport?.number,
        registrationAddress: account.passport?.registrationAddress,
        nickname: account.composer?.nickname,
        showNickname: account.composer?.showNickname || false,
        howlong: account.composer?.howlong,
        instruments: account.composer?.instruments,
        styles: account.composer?.styles,
        liveInstruments: account.composer?.liveInstruments,
        readyToOrder: account.composer?.readyToOrder || false,
        readyToRemake: account.composer?.readyToRemake || false,
        inn: account.inn?.number,
      }}
      onFinish={handleSubmit}
      scrollToFirstError={true}
    >
      <Typography.Title level={3} style={{ textAlign: 'left', marginBottom: 20 }}>
        Редактировать аккаунт
      </Typography.Title>

      <Form.Item
        label={'Электронная почта'}
        name={'email'}
        // rules={[{ required: true, message: 'Пожалуйста, введите адрес электронной почты!' }]}
      >
        <Input placeholder={'myname@yandex.ru'} />
      </Form.Item>

      <Form.Item
        label={'Телефон'}
        name={'phone'}
        // rules={[{ required: true, message: 'Пожалуйста, введите адрес электронной почты!' }]}
      >
        <Input placeholder={'+7-987-654-32-10'} />
      </Form.Item>

      <Divider />

      <Form.Item
        label="Фамилия"
        name={'secondName'}
        // rules={[{ required: true, message: 'Пожалуйста, введите свою фамилию!' }]}
      >
        <Input placeholder={'Иванов'} />
      </Form.Item>

      <Form.Item
        label="Имя"
        name={'firstName'}
        // rules={[{ required: true, message: 'Пожалуйста, введите своё имя!' }]}
      >
        <Input placeholder={'Иван'} />
      </Form.Item>

      <Form.Item
        label="Отчество"
        name={'thirdName'}
      >
        <Input placeholder={'Иванович'} />
      </Form.Item>


      <Form.Item
          label="Вконтакте"
          name={'vk'}
      >
        <Input type={'text'} placeholder="vk.com"  />
      </Form.Item>

      <Form.Item
          label="Facebook"
          name={'facebook'}
      >
        <Input type={'text'} placeholder="facebook.com"  />
      </Form.Item>

     {/* <Form.Item
        label={'Паспорт серия'}
        name={'series'}
        // rules={[{ required: true, message: 'Пожалуйста, введите серию паспорта!' }]}
      >
        <Input placeholder={'1134'} />
      </Form.Item>

      <Form.Item
        label={'Паспорт номер'}
        name={'number'}
        // rules={[{ required: true, message: 'Пожалуйста, введите номер паспорта!' }]}
      >
        <Input placeholder={'543377'} />
      </Form.Item>

      <Form.Item
        label="Адрес регистрации"
        name={'registrationAddress'}
        // rules={[{ required: true, message: 'Пожалуйста, адрес регистрации по паспорту!' }]}
      >
        <Input placeholder={'г. Санкт-Петербург, ул. Комсомольская, д. 5'} />
      </Form.Item>
      <Form.Item label={'ИНН'} name={'inn'}>
        <Input />
      </Form.Item>*/}

      {account.composer?.id && (
        <React.Fragment>
          <Divider />

          <Form.Item label="Творческий псевдоним" name={'nickname'}>
            <Input placeholder={'Ваш псевдоним'} />
          </Form.Item>

          <Form.Item label="Отображать псевдоним вместо ФИО" name={'showNickname'} valuePropName="checked">
            <Checkbox />
          </Form.Item>

          <Form.Item label="С какого года сочиняет музыку" name={'howlong'}>
            <Input type={'number'} placeholder="2010" min={1900} max={new Date().getFullYear()} />
          </Form.Item>

          <Form.Item label="Какими музыкальными инструментами владеет" name={'instruments'}>
            <Input placeholder="Гитара" />
          </Form.Item>

          <Form.Item label="Любимые стили написания музыки" name={'styles'}>
            <Input placeholder="Фанк" />
          </Form.Item>

          <Form.Item label="Используемые «живые инструменты»" name={'liveInstruments'}>
            <Input placeholder="Труба" />
          </Form.Item>

          <Form.Item label="Готов ли писать музыку «на заказ»" name={'readyToOrder'} valuePropName="checked">
            <Checkbox />
          </Form.Item>

          <Form.Item label="Готовы ли Вы переделать готовый трек" name={'readyToRemake'} valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </React.Fragment>
      )}

      <Form.Item>
        <Button type={'primary'} htmlType={'submit'} loading={loading}>
          Сохранить
        </Button>
      </Form.Item>
    </Form>
      </div>
  );
}

export function AdminAccountEditPage(): JSX.Element {
  return (
    <CabinetPageTemplate>
      <AccountEditForm />
    </CabinetPageTemplate>
  );
}
