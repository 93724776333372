import styles from '../../css/form.module.css';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { ROUTES } from '../../routes';
import cx from 'classnames';
import { Link, Redirect } from 'react-router-dom';
import React, {useState} from 'react';
import {Checkbox, message} from 'antd';
import {CheckboxChangeEvent} from "antd/es/checkbox";
import { ValidationErrors } from './interfaces';
import { FieldWrapper } from './FieldWrapper';
import { isEmail } from '../../utils';
import { SIGN_UP } from '../../graphql';
import { PayloadStatuses } from '../../types';
import { useMutation } from '@apollo/react-hooks';
import { ExecutionResult } from "graphql";
import { IS_AUTHENTICATED } from "../../graphql/common.queries";
import { NavLink } from 'react-router-dom';

export function RegisterForm(props): JSX.Element {
  const [agreedToPolicy, setAgreedToPolicy] = useState<boolean>(false);
  const [signUp, { data }] = useMutation(SIGN_UP, {
    refetchQueries: [{ query: IS_AUTHENTICATED }],
    awaitRefetchQueries: true,
  });

  return data && data.common.auth.signUp.status === PayloadStatuses.SUCCESS ? (
    <Redirect to={ROUTES.COMMON.CABINET} />
  ) : (
    <div className={styles.formWrapper}>
      <h1 className={styles.formTitle}>{ props.title }</h1>
      <Formik
        initialValues={{ login: '', password: '', passwordConfirm: '', agreedToPolicy: '' }}
        validate={(values): ValidationErrors => {
          const errors: ValidationErrors = {};
          if (!values.login) {
            errors.login = 'Обязательно к заполнению';
          } else if (!isEmail(values.login)) {
            errors.login = 'Неверный формат email адреса';
          }

          if (!values.password.length) {
            errors.password = 'Пароль не должен быть пустым!';
          }

          if (values.password !== values.passwordConfirm) {
            errors.passwordConfirm = 'Пароли не совпадают!';
          }

          if(!agreedToPolicy) {
            errors.agreedToPolicy = 'Обязательно к заполнению';
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
            if(!agreedToPolicy) {
                message.error("Пользовательское соглашение");
                return
            }
          setSubmitting(true);
          return signUp({ variables: { input: { login: values.login, password: values.password } } });
        }}
      >
        {({ errors, isSubmitting, touched, isValid }): JSX.Element => (
          <Form className={styles.form}>
            <FieldWrapper>
              <label htmlFor="reg_email" className={styles.label}>
                Email адрес
              </label>
              <Field type="email" name="login" className={cx(styles.input, { [styles.inputErrored]: errors.login })} />
              <ErrorMessage name="login" component="div" className={styles.errorMsg} />
            </FieldWrapper>

            <FieldWrapper>
              <label htmlFor="reg_password" className={styles.label}>
                Пароль
              </label>
              <Field
                type="password"
                name="password"
                className={cx(styles.input, { [styles.inputErrored]: errors.password && touched['password'] })}
              />
              <ErrorMessage name="password" component="div" className={styles.errorMsg} />
            </FieldWrapper>

            <FieldWrapper>
              <label htmlFor="reg_password_confirm" className={styles.label}>
                Пароль еще раз
              </label>
              <Field
                type="password"
                name="passwordConfirm"
                className={cx(styles.input, { [styles.inputErrored]: errors.passwordConfirm })}
              />
              <ErrorMessage name="passwordConfirm" component="div" className={styles.errorMsg} />
            </FieldWrapper>

		    <FieldWrapper>
			<div style={{display: "flex", alignItems: "center", }}>
		       <Field
		            type="checkbox"
		            name="agreedToPolicy"
		            onChange={(event: CheckboxChangeEvent): void => {
		            setAgreedToPolicy(event.target.checked) }}
		            />
		      <NavLink to={ROUTES.COMMON.AGREEMENT}>
		          <span className={styles.privacyPolicy}>Пользовательское соглашение</span>
		      </NavLink>
		      <ErrorMessage name="agreedToPolicy" component="div" className={styles.errorMsg} />
			</div>
		    </FieldWrapper>

            <FieldWrapper>
              <button
                type="submit"
                className={cx(styles.button, { [styles.buttonActive]: isValid })}
                disabled={isSubmitting}
              >
                Зарегистрироваться
              </button>
            </FieldWrapper>
            <div className={styles.lastText}>
              Уже зарегистрированы?{' '}
              <Link to={ROUTES.AUTH.LOGIN} className={styles.link}>
                Войти
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
