import React from 'react';
import { CabinetPageTemplate } from '../cabinet/CabinetPageTemplate';
import { PublishersTable } from './PublishersTable';
import { PublisherCreateForm } from './PublisherCreateForm';

export const PublishersAdminPage = (): JSX.Element => {
  return (
    <CabinetPageTemplate>
      <PublisherCreateForm />
      <PublishersTable />
    </CabinetPageTemplate>
  );
};
