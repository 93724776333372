import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { AccountDeleteMutation } from '../../__generated__/AccountDeleteMutation';
import { ExecutionResult } from 'graphql';
import { ACCOUNT_ADMIN_PAGE_FIND_ALL_QUERY } from './AdminAccountTablePage';

const ACCOUNT_DELETE_MUTATION = gql`
  mutation AccountDeleteMutation($accountId: String!) {
    admin {
      account {
        delete(accountId: $accountId)
      }
    }
  }
`;

export const AccountTableDeleteButton = (props: { accountId: string }): JSX.Element => {
  const [deleteAccount, { loading }] = useMutation<AccountDeleteMutation>(ACCOUNT_DELETE_MUTATION, {
    onCompleted: data => {
      console.log(data);
      if (data.admin?.account?.delete === 'ok') message.success('Пользователь успешно удалён');
      else message.error('Во время удаления пользователя произошла ошибка');
    },
    refetchQueries: [{ query: ACCOUNT_ADMIN_PAGE_FIND_ALL_QUERY }],
  });

  function handleConfirm(): Promise<ExecutionResult> {
    return deleteAccount({ variables: { accountId: props.accountId } });
  }

  return (
    <Popconfirm title={'Вы точно хотите удалить пользователя?'} onConfirm={handleConfirm}>
      <Button icon={<DeleteOutlined />} size={'small'} shape={'circle'} loading={loading} />
    </Popconfirm>
  );
};
