import React from 'react';

import cx from 'classnames';
import {ROUTES} from "../routes";
import {Link} from "react-router-dom";

const styles = require('./AbsoluteFooter-styles.module.css');


export function AbsoluteFooter(): JSX.Element {
  const year =  new Date().getFullYear();
  return (
    <div className={styles.wrapper}>
      <div className="container">
        <div className="row justify-content-between">
          <div className={cx('col-xs-12 col-md-6 align-self-center order-md-2',)}>
            <img src="/img/payment.png" className={styles.paymentImage} />
          </div>

          <div className="col-xs-12 col-md-6 order-md-1 d-flex align-items-center">
              <div> © MusicWork {year} г. </div>
          </div>
        </div>
      </div>
    </div>
  );
}
