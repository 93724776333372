// @ts-nocheck
import React, {useState} from 'react';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {CabinetPageTemplate} from '../cabinet/CabinetPageTemplate';
import {ComposerPageTemplate} from '../cabinet/ComposerPageTemplate';
import {Form, Input, Typography, Button, InputNumber, message, Space, Checkbox} from 'antd';

import {formItemLayout} from '../form/options';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {ROUTES} from '../../routes';
import {UploadSample} from './track.interfaces';
import {PayloadStatuses} from '../../types';
import {useHistory} from 'react-router';
import {SamplesField} from './SamplesField';
import {MainSampleField} from './MainSampleField';
import {InstrumentsField} from '../instrument/InstrumentsField';
import {GenresField} from '../genre/GenresField';
import {MoodsField} from '../mood/MoodsField';
import {COMPOSER_TRACKS_FIND_ALL_QUERY} from './ComposerTracksTablePage';
import gql from 'graphql-tag';
import {ComposerCreateTrackMutation} from '../../__generated__/ComposerCreateTrackMutation';
import {Spinner} from '../Spinner';
import {ComposerAddTrackPageAutherNameQuery} from '../../__generated__/ComposerAddTrackPageAutherNameQuery';
import {ExecutionResult} from 'graphql';
import {MessageType} from 'antd/es/message';

const styles = require('./ComposerAddTrackPage-styles.module.css');

const COMPOSER_ADD_TRACK_PAGE_AUTHOR_NAME_QUERY = gql`
  query ComposerAddTrackPageAuthorNameQuery {
    client {
      account {
        composer {
          id
          authorName
        }
         passport {
           firstName
           secondName
         }
      }
    }
  }
`;

export const CREATE_TRACK_MUTATION = gql`
  mutation ComposerCreateTrackMutation($input: CreateTrackInput) {
    client {
      composer {
        createTrack(input: $input) {
          status
        }
      }
    }
  }
`;


function AddTrackForm(): JSX.Element {
    const [form] = Form.useForm();
    const {push} = useHistory();
    const {data: authorNameData} = useQuery<ComposerAddTrackPageAutherNameQuery>(
        COMPOSER_ADD_TRACK_PAGE_AUTHOR_NAME_QUERY,
    );
    console.log(authorNameData)
    const [uploadSampleList, setUploadSampleList] = useState<UploadSample[]>([]);
    console.log("uploadSampleList", uploadSampleList);
    const [createTrack, {loading: createTrackLoading}] = useMutation<ComposerCreateTrackMutation>(
        CREATE_TRACK_MUTATION,
        {
            refetchQueries: [
                {
                    query: COMPOSER_TRACKS_FIND_ALL_QUERY,
                },
            ],
            onCompleted: (data): void => {
                if (data?.client?.composer?.createTrack?.status === PayloadStatuses.SUCCESS)
                    push(ROUTES.CABINET.COMPOSER.TRACKS.LIST);
            },
        },
    );

    const handleSubmit = (values): any => {
        console.log(values);
        values.author = values.secondName + " " + values.firstName;
        delete values.secondName;
        delete values.firstName;


        if (uploadSampleList.length === 0) return message.error('Пожалуйста, загрузите хотя бы один трек!');
        const input = {
            ...values,
            coauthor: values.coauthor + "",
            samples: uploadSampleList.reduce((samples: UploadSample[], sample) => {
                return sample.id ? samples.concat({id: sample.id, description: sample.description}) : samples;
            }, []),
        };
        return createTrack({
            variables: {
                input,
            },
        });
    };

    return createTrackLoading ? (
        <Spinner/>
    ) : (
        <Form
            {...formItemLayout}
            className={styles.addTrackForm}
            form={form}
            onFinish={handleSubmit}
            scrollToFirstError={true}
        >
            <Typography.Title level={3} className={styles.title}>
                Загрузить новый трек
            </Typography.Title>
            <Form.Item
                label="Название трека"
                name={'name'}
                rules={[{required: true, message: 'Пожалуйста, введите название трека!'}]}
            >
                <Input placeholder={'Вечерний вальс'}/>
            </Form.Item>
            <Form.Item
                label={'Имя'}
                name={'firstName'}
                rules={[{required: true, message: 'Пожалуйста, введите имя автора!'}]}
            >
                <Input placeholder={authorNameData?.client?.account?.passport?.firstName || 'Имя автора'}/>
            </Form.Item>
            <Form.Item
                label={'Фамилия'}
                name={'secondName'}
                rules={[{required: true, message: 'Пожалуйста, введите фамилию автора!'}]}
            >
                <Input placeholder={authorNameData?.client?.account?.passport?.secondName || 'Имя автора'}/>
            </Form.Item>
            <Form.Item
                label={'Соавтор'}
                name={'coauthor'}>
                <Form.List
                    name={"coauthor"}>

                    {(fields, {add, remove}) => (
                        <>
                            {(fields.map((field, index) => (
                                <div key={index} style={{display: "flex", width: '100%'}}>
                                    <Form.Item
                                        style={{minWidth: '90%'}}
                                        {...field}
                                    >
                                        <Input style={{minWidth: '90%'}}/>

                                    </Form.Item>
                                    <MinusCircleOutlined style={{marginTop: '10px'}}
                                                         onClick={() => remove(field.name)}/>
                                </div>
                            )))}

                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                    Добавить соавтора
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form.Item>
            <GenresField/>
            <InstrumentsField/>
            <MoodsField/>
            <Form.Item label="BPM" name={'bpm'}>
                <InputNumber min={0} placeholder={'120'}/>
            </Form.Item>
            {/*<Form.Item label={'Описание'} name={'description'}>*/}
            {/*  <Input.TextArea*/}
            {/*    autoSize={{ minRows: 5 }}*/}
            {/*    placeholder={'Спокойная музыка для проигрывания в кафе и ресторанах'}*/}
            {/*  />*/}
            {/*</Form.Item>*/}
            <Form.Item label="Основной трек">
                <MainSampleField
                    samples={uploadSampleList}
                    onChange={(samples): void => {
                        console.log("samples", [...samples])
                        setUploadSampleList([...samples]);
                    }}
                />
            </Form.Item>

            <Form.Item label="Версии трека">
                <SamplesField
                    samples={uploadSampleList}
                    onChange={(samples): void => {
                        setUploadSampleList([...samples]);
                    }}
                />
            </Form.Item>

            {/* <Form.Item label="Принимаю условия" name={'acceptTerms'} valuePropName="checked">
          <Checkbox />
        </Form.Item> */}

            <Button style={{margin: "auto", display: "block", width: 200}} type={'primary'} htmlType={'submit'}>
                Загрузить
            </Button>

        </Form>
    );
}

export function ComposerAddTrackPage(): JSX.Element {
    return (
        <ComposerPageTemplate>
            <AddTrackForm/>
        </ComposerPageTemplate>
    );
}
