import React, {useState} from 'react';
import { Form } from '@ant-design/compatible';
import {Checkbox, Divider, Input, Select, Spin} from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  BECOME_A_COMPOSER_ABOUT_STEP_MUTATION,
  COMPOSER_ONBOARDING_ABOUT_STEP_QUERY,
  COMPOSER_ONBOARDING_DOCUMENTS_STEP_QUERY
} from '../../../../graphql';
import { ComposerOnboardingStepActions } from './ComposerOnboardingStepActions';
import styles from './onboarding.module.css';
import {CheckboxChangeEvent} from "antd/es/checkbox";

const { Item } = Form;

export function ComposerOnboardingAboutStep(props: { next(): void }): JSX.Element {
  const { data, loading } = useQuery(COMPOSER_ONBOARDING_DOCUMENTS_STEP_QUERY);
  const { data: aboutStepData, loading: aboutStepLoading } = useQuery(COMPOSER_ONBOARDING_ABOUT_STEP_QUERY);
  const [saveAboutStep, { loading: aboutStepMutationLoading }] = useMutation(BECOME_A_COMPOSER_ABOUT_STEP_MUTATION, {
    refetchQueries: [{ query: COMPOSER_ONBOARDING_ABOUT_STEP_QUERY }],
  });

  const next = props.next;
  const account = (data && data.client && data.client.account) || {};
  const [showNickname, setShowNickname] = useState<boolean>(false);
  const composerData = aboutStepData?.client?.profile.composer || {};

  const AboutForm = Form.create<FormComponentProps>({ name: 'aboutForm' })((props: FormComponentProps) => {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
      },

      wrapperCol: {
        xs: { span: 24 },
      },
    };
    const { getFieldDecorator, validateFields } = props.form;

    const handleNextStep = (): void => {
      validateFields(async (errors, values) => {
        values = {
          ...values,
          readyToOrder: values.readyToOrder === 'Да',
          readyToRemake: values.readyToRemake === 'Да',
        }
          if (!errors) {
          await saveAboutStep({ variables: { input: values } });
          next();
        }
      });
    };

    return (
      <div>
        <div className={styles.stepsContentWrapper}>
          {aboutStepLoading || aboutStepMutationLoading ? (
            <Spin />
          ) : (
            <Form {...formItemLayout} className={styles.wizardForm} labelAlign={'left'}>
              <Item label="Мобильный телефон">
                {getFieldDecorator('mobileNumber', {
                  initialValue: account.mobileNumber,
                  rules: [
                    {
                      required: true,
                      message: 'Пожалуйста, введите номер телефона!',
                    },
                  ],
                })(<Input placeholder={'9816648274'} type={'tel'} />)}
              </Item>
              <Divider />
              <Item label="Фамилия">
                {getFieldDecorator('secondName', {
                  initialValue: account.passport && account.passport.secondName,
                  rules: [{ required: true, message: 'Пожалуйста, введите свою фамилию!' }],
                })(<Input placeholder={'Иванов'} />)}
              </Item>
              <Item label="Имя">
                {getFieldDecorator('firstName', {
                  initialValue: account.passport && account.passport.firstName,
                  rules: [{ required: true, message: 'Пожалуйста, введите своё имя!' }],
                })(<Input placeholder={'Иван'} />)}
              </Item>
              <Item label="Отчество">
                {getFieldDecorator('thirdName', {
                  initialValue: account.passport && account.passport.thirdName,
                  rules: [{ required: true, message: 'Пожалуйста, введите своё отчество!' }],
                })(<Input placeholder={'Иванович'} />)}
              </Item>

              <Item label="Творческий псевдоним">
                {getFieldDecorator('nickname', {
                  initialValue: composerData.nickname,
                })(<Input placeholder={'Richie Black'} />)}
              </Item>

              <Item label="Отображать псевдоним вместо ФИО" >
                <Checkbox
                    style={{display: "inline"}}
                    checked={showNickname}
                    onChange={(event: CheckboxChangeEvent): void => {
                      setShowNickname(event.target.checked);
                    }}
                />
              </Item>

              <Item label="VK">
                {getFieldDecorator('vk', {
                  initialValue: composerData.vk,
                })(<Input placeholder={'vk.com'} />)}
              </Item>

              <Item label="Facebook">
                {getFieldDecorator('facebook', {
                  initialValue: composerData.facebook,
                })(<Input placeholder={'facebook.com'} />)}
              </Item>

              <Item label="С какого года Вы сочиняете музыку?">
                {getFieldDecorator('howlong', {
                  initialValue: composerData.howlong,
                })(<Input placeholder="С 1992 года." />)}
              </Item>

              <Item label="Какими музыкальными инструментами Вы владеете?">
                {getFieldDecorator('instruments', {
                  initialValue: composerData.instruments,
                })(<Input placeholder="Клавесин и гитара." />)}
              </Item>

              <Item label="Ваши любимые стили написания музыки?">
                {getFieldDecorator('styles', {
                  initialValue: composerData.styles,
                })(<Input placeholder="Люблю сочинять музыку в стиле модерн." />)}
              </Item>

              <Item label="Готовы ли Вы писать музыку «на заказ»?">
                {getFieldDecorator('readyToOrder', {
                  initialValue: composerData.readyToOrder ? 'Да' : 'Нет',
                })(
                    <Select>
                      <Select.Option value={'Да'}>Да</Select.Option>
                      <Select.Option value={'Нет'}>Нет</Select.Option>
                    </Select>,
                )}
              </Item>

              <Item label="Готовы ли Вы переделать готовый трек по просьбе заказчика?">
                {getFieldDecorator('readyToRemake', {
                  initialValue: composerData.readyToRemake ? 'Да' : 'Нет',
                })(
                    <Select>
                      <Select.Option value={'Да'}>Да</Select.Option>
                      <Select.Option value={'Нет'}>Нет</Select.Option>
                    </Select>,
                )}
              </Item>

            </Form>
          )}
        </div>
        <ComposerOnboardingStepActions next={handleNextStep} />
      </div>
    );
  });

  return <AboutForm />;
}
