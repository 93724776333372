import React, {useCallback} from 'react';
import {Button, Tooltip} from 'antd';
import {HeartFilled, HeartOutlined} from '@ant-design/icons';
import gql from 'graphql-tag';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {ExecutionResult} from 'graphql';
import {TrackToggleFavorites} from '../../../../__generated__/TrackToggleFavorites';
import {CLIENT_FAVORITE_TRACKS_FIND_QUERY} from '../../../favorites/FavoriteTracksPage';
import {ButtonProps} from 'antd/es/button';
import {useHistory} from "react-router-dom";
import {IS_AUTHENTICATED, isAuthenticated} from "../../../../graphql/common.queries";
import {ROUTES} from "../../../../routes";

const TRACK_TOGGLE_FAVORITES = gql`
  mutation TrackToggleFavorites($id: String!) {
    client {
      track {
        favorite {
          toggle(id: $id) {
            id
            isFavorite
          }
        }
      }
    }
  }
`;

type IsFavoriteButtonProps = {
    trackid: string;
    isfavorite: boolean;
};

export function TrackToggleFavoritesButton(props: ButtonProps & IsFavoriteButtonProps): JSX.Element {
    const {data} = useQuery(IS_AUTHENTICATED);
    const [toggle, {loading}] = useMutation<TrackToggleFavorites>(TRACK_TOGGLE_FAVORITES, {
        refetchQueries: [{query: CLIENT_FAVORITE_TRACKS_FIND_QUERY}],
    });

    const handleClick = (event): Promise<ExecutionResult<TrackToggleFavorites>> => {
        event.stopPropagation();
        return toggle({variables: {id: props.trackid}});
    };

    const history = useHistory();
    const handleAuthClick = useCallback((event) => {
        event.stopPropagation();
        history.push(ROUTES.AUTH.LOGIN);
    }, []);

    const style = props.isfavorite ? {...props.style, color: 'var(--main-bg-color)'} : props.style;

    return isAuthenticated(data) ? (
        <Tooltip title={props.isfavorite ? "Убрать из избранного" : "Добавить в избранное"}>
            <Button
                size={'small'}
                shape={'circle'}
                disabled={loading}
                onClick={handleClick}
                style={{...style}}
            >
                {props.isfavorite ? <HeartFilled/> : <HeartOutlined/>}
            </Button>
        </Tooltip>
    ) : (
        <Tooltip title="Потребуется авторизация">
            <Button
                size={'small'}
                shape={'circle'}
                disabled={loading}
                onClick={handleAuthClick}
                style={{...style}}
            >
                {props.isfavorite ? <HeartFilled/> : <HeartOutlined/>}
            </Button>
        </Tooltip>
    );
}
