import React from 'react';

import grid from '../css/form.module.css';
import { UpOutlined } from '@ant-design/icons';
import cx from 'classnames';

const styles = require('./BackToTopArrow-styles.module.css');

export function BackToTopArrow(props: { active: boolean }): JSX.Element {
  function onClickHandler(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  const className = cx(styles.btn, grid.hideFromMedium, { [styles.active]: props.active });

  return (
    <button className={className} onClick={onClickHandler}>
      <UpOutlined />
    </button>
  );
}
