// @ts-nocheck
import React, { useEffect } from 'react';
import { CabinetPageTemplate } from '../cabinet/CabinetPageTemplate';
import { useQuery } from '@apollo/react-hooks';
import { useAudioPlayer } from '../audioplayer/useAudioPlayer';
import gql from 'graphql-tag';
import { ComposerTracksTableFindAllQuery } from '../../__generated__/ComposerTracksTableFindAllQuery';
import { getSampleSrc } from '../../utils';
import { TrackTable } from './table/TrackTable';
import { TrackTableRowColumns } from './table/TrackTableRow';

export const COMPOSER_TRACKS_FIND_ALL_QUERY = gql`
  query ComposerTracksTableFindAllQuery {
    client {
      composer {
        track {
          find {
            id
            name
            authorName
            bpm
            price
            mainSampleId
            description
            createdAt
            samples {
              id
              name
              description
              duration
            }
            status
          }
        }
      }
    }
  }
`;

export function ComposerTracksTablePage(): JSX.Element {
  const { setPlayList, playList } = useAudioPlayer();
  const { loading, data } = useQuery<ComposerTracksTableFindAllQuery>(COMPOSER_TRACKS_FIND_ALL_QUERY);

  const tracks = data?.client?.composer?.track?.find || [];

  useEffect(() => {
    const newPlayList = tracks
      ? tracks.map(track => ({ name: track.name, src: getSampleSrc(track.mainSampleId) }))
      : [];
    if (JSON.stringify(newPlayList) !== JSON.stringify(playList)) {
      setPlayList(newPlayList);
    }
  }, [playList, setPlayList, tracks]);

  const columns: TrackTableRowColumns = {
    createdAt: true,
    price: true,
    actions: {
      composerEdit: true,
      withdraw: true,
    },
    status: true,
  };

  return (
    <CabinetPageTemplate>
      <TrackTable tracks={tracks} loading={loading} cols={columns} />
    </CabinetPageTemplate>
  );
}
