import React from 'react';
import { format } from 'date-fns';
import { DateTimeFormat } from '../../../config';

export const TrackTableCreatedAtCell = (text, track): React.ReactElement => (
  <span>{format(new Date(track.createdAt), DateTimeFormat)}</span>
);

export const TrackDataSorter =  (createdAt?: number) => {
	return (a, b) => Number(new Date(a.createdAt)) - Number(new Date(b.createdAt))
}
