import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { MainPage } from './pages/MainPage';
import { FaqPage } from './pages/FaqPage';
import { ContactPage } from './pages/ContactPage';
import { AgreementPage } from './pages/AgreementPAge';
import { BecomeComposerInfoPage } from './pages/BecomeComposerInfoPage';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { RegisterPage } from './auth/RegisterPage';
import { RegisterComposer } from './auth/RegisterComposer';
import { LoginPage } from './auth/LoginPage';
import { ForgotPage } from './auth/ForgotPage';
import { CabinetMainPage } from './pages/cabinet/CabinetMainPage';
import { Spinner } from './Spinner';
import { Logout } from './auth/Logout';
import ConfirmOrder from './pages/ConfirmOrder';
import ErrorOrder from "./pages/ErrorOrder";
import { ROUTES } from '../routes';
import { ProfilePage } from './pages/cabinet/ProfilePage';
import { BecomeAComposerPage } from './pages/cabinet/composer';
import { AdminAccountTablePage } from './account/AdminAccountTablePage';
import { InstrumentsAdminPage } from './instrument';
import { GenresAdminPage } from './genre';
import { DirectionsAdminPage } from './direction';
import { MoodsAdminPage } from './mood';
import { AudioPlayerProvider } from './audioplayer/AudioPlayerContext';
import { ComposerAboutPage, ComposerPassportPage, ComposerRequisitesPage } from './composer';
import { PublishersAdminPage } from './publisher/PublishersAdminPage';
import { CartPage } from './cart/CartPage';
import '../css/ThemeVariables';
import { ClientFavoriteTracksPage } from './favorites/FavoriteTracksPage';
import { CommonSearchPage } from './search/SearchPage';
import { CustomerOrdersPage } from './order/CustomerOrdersPage';
import { NewTracksTablePage } from './track/NewTracksTablePage';
import { CommonTracksTablePage } from './track/CommonTracksTablePage';
import { AdminTracksTablePage } from './track/AdminTracksTablePage';
import { ComposerTracksTablePage } from './track/ComposerTracksTablePage';
import { AdminTrackEditPage } from './track/AdminTrackEditPage';
import { ComposerAddTrackPage } from './track/ComposerAddTrackPage';
import { ComposerTrackEditPage } from './track/ComposerTrackEditPage';
import { ComposerWithdrawPage } from './composer/ComposerWithdrawPage';
import { ComposerSalesPage } from './composer/ComposerSalesPage';
import { AdminAccountEditPage } from './account/AdminAccountEditPage';
import { AdminCarouselManagePage } from './carousel/AdminCarouselManagePage';
import { AdminAddEditFAQPage } from './faq/AdminAddEditFAQPage';
import { AdminEditAgreementPage } from './faq/AdminEditAgreementPage';
import { AdminEditBecomeComposerInfoPage } from './faq/AdminEditBecomeComposerInfoPage';
import { AdminEditPrivacyPolicyInfoPage } from './faq/AdminEditPrivacyPolicyInfoPage';

export function App(): JSX.Element {
  return (
    <BrowserRouter>
      <AudioPlayerProvider>
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route exact path={ROUTES.COMMON.MAIN} component={MainPage} />
            <Route exact path={ROUTES.COMMON.FAQ} component={FaqPage} />
            <Route exact path={ROUTES.COMMON.CONFIRM} component={ConfirmOrder} />
            <Route exact path={ROUTES.COMMON.ERROR_ORDER} component={ErrorOrder} />
            <Route exact path={ROUTES.COMMON.CONTACTS} component={ContactPage} />
            <Route exact path={ROUTES.COMMON.AGREEMENT} component={AgreementPage} />
            <Route exact path={ROUTES.COMMON.BECOME_COMPOSER_INFO} component={BecomeComposerInfoPage} />
            <Route exact path={ROUTES.COMMON.PRIVACY_POLICY} component={PrivacyPolicy} />
            <Route exact path={ROUTES.COMMON.CABINET} component={CabinetMainPage} />
            <Route exact path={ROUTES.COMMON.CART.MAIN} component={CartPage} />

            <Route exact path={ROUTES.AUTH.SIGN_UP} component={RegisterPage} />
            <Route exact path={ROUTES.AUTH.SIGN_UP_COMPOSER} component={RegisterComposer} />
            <Route exact path={ROUTES.AUTH.FORGOT__PASSWORD} component={ForgotPage} />
            <Route exact path={ROUTES.AUTH.LOGIN} component={LoginPage} />
            <Route exact path={ROUTES.AUTH.LOGOUT} component={Logout} />

            <Route exact path={ROUTES.COMMON.TRACKS.MAIN} component={CommonTracksTablePage} />
            <Route exact path={ROUTES.COMMON.SEARCH} component={CommonSearchPage} />

            <Route exact path={ROUTES.CABINET.PROFILE} component={ProfilePage} />
            <Route exact path={ROUTES.CABINET.FAVORITES} component={ClientFavoriteTracksPage} />
            <Route exact path={ROUTES.CABINET.ORDERS} component={CustomerOrdersPage} />

            <Route exact path={ROUTES.CABINET.COMPOSER.ABOUT} component={ComposerAboutPage} />
            <Route exact path={ROUTES.CABINET.COMPOSER.REQUISITES} component={ComposerRequisitesPage} />
            <Route exact path={ROUTES.CABINET.COMPOSER.PASSPORT} component={ComposerPassportPage} />
            <Route exact path={ROUTES.CABINET.COMPOSER.NEW_TRACK} component={ComposerAddTrackPage} />
            <Route exact path={ROUTES.CABINET.COMPOSER.NEW} component={BecomeAComposerPage} />
            <Route exact path={ROUTES.CABINET.COMPOSER.TRACKS.LIST} component={ComposerTracksTablePage} />
            <Route exact path={ROUTES.CABINET.COMPOSER.TRACKS.EDIT} component={ComposerTrackEditPage} />
            <Route exact path={ROUTES.CABINET.COMPOSER.WITHDRAW.MAIN} component={ComposerWithdrawPage} />
            <Route exact path={ROUTES.CABINET.COMPOSER.SALES} component={ComposerSalesPage} />

            <Route exact path={ROUTES.CABINET.ADMIN.TRACKS.LIST} component={AdminTracksTablePage} />
            <Route exact path={ROUTES.CABINET.ADMIN.TRACKS.NEW} component={NewTracksTablePage} />
            <Route exact path={ROUTES.CABINET.ADMIN.TRACKS.EDIT} component={AdminTrackEditPage} />
            <Route exact path={ROUTES.CABINET.ADMIN.ACCOUNTS.MAIN} component={AdminAccountTablePage} />
            <Route exact path={ROUTES.CABINET.ADMIN.ACCOUNTS.EDIT} component={AdminAccountEditPage} />
            <Route exact path={ROUTES.CABINET.ADMIN.GENRES} component={GenresAdminPage} />
            <Route exact path={ROUTES.CABINET.ADMIN.INSTRUMENTS} component={InstrumentsAdminPage} />
            <Route exact path={ROUTES.CABINET.ADMIN.DIRECTIONS} component={DirectionsAdminPage} />
            <Route exact path={ROUTES.CABINET.ADMIN.MOODS} component={MoodsAdminPage} />
            <Route exact path={ROUTES.CABINET.ADMIN.PUBLISHERS} component={PublishersAdminPage} />
            <Route exact path={ROUTES.CABINET.ADMIN.CAROUSEL} component={AdminCarouselManagePage} />
            <Route exact path={ROUTES.CABINET.ADMIN.FAQ} component={AdminAddEditFAQPage} />
            <Route exact path={ROUTES.CABINET.ADMIN.AGREEMENT} component={AdminEditAgreementPage} />
            <Route exact path={ROUTES.CABINET.ADMIN.BECOME_COMPOSER_INFO} component={AdminEditBecomeComposerInfoPage} />
            <Route exact path={ROUTES.CABINET.ADMIN.PRIVACY_POLICY} component={AdminEditPrivacyPolicyInfoPage} />
          </Switch>
        </Suspense>
      </AudioPlayerProvider>
    </BrowserRouter>
  );
}
