import React from 'react';
import { Button } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import styles from './onboarding.module.css';

interface StepActionsProps {
  next?(): void;
  prev?(): void;
  final?(): void;
}

export function ComposerOnboardingStepActions(props: StepActionsProps): JSX.Element {
  return (
    <div className={styles.stepsAction}>
      {props.prev && (
        <Button onClick={props.prev} className={styles.stepActionButton}>
          <ArrowLeftOutlined /> Назад
        </Button>
      )}
      {props.next && (
        <Button type="primary" onClick={props.next} className={styles.stepActionButton}>
          Продолжить <ArrowRightOutlined />
        </Button>
      )}
      {props.final && (
        <Button type="primary" onClick={props.final} className={styles.stepActionButton}>
          Завершить!
        </Button>
      )}
    </div>
  );
}
