import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { DateTimeFormat } from '../../config';
import { format } from 'date-fns';
import { DELETE_GENRE_MUTATION, GENRES_FIND_ALL_QUERY, PATCH_GENRE_MUTATION } from './genre.graphql';

import { ActionEditButton } from '../table/ActionEditButton';
import { EditableTableCell } from '../table/EditableTableCell';
import { Genre } from './index';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { Form } from '@ant-design/compatible';
import { pick, sortByDate } from '../../utils';
import { Table, Button, Popconfirm, message } from 'antd';
import { ExecutionResult } from 'graphql';

const styles = require('./GenresTable-styles.module.css');

export const GenresTable = (props: FormComponentProps): JSX.Element => {
  const [editing, setEditing] = useState<string | undefined>(undefined);
  const { loading, data } = useQuery(GENRES_FIND_ALL_QUERY);
  const [deleteGroup, { loading: deleteLoading }] = useMutation(DELETE_GENRE_MUTATION, {
    refetchQueries: [{ query: GENRES_FIND_ALL_QUERY }],
    onCompleted: () => {
      message.success('Жанр успешно удалён');
    },
  });
  const [patchGenre, { loading: patchLoading }] = useMutation(PATCH_GENRE_MUTATION, {
    refetchQueries: [{ query: GENRES_FIND_ALL_QUERY }],
    onCompleted: () => {
      message.success(`Жанр успешно обновлён`);
      setEditing(undefined);
    },
  });

  const { form } = props;
  const { resetFields } = form;
  const genres = pick<Genre[]>(data, 'common.genres') || [];

  const isRowEditing = (id: string): boolean => {
    return editing === id;
  };

  const handleSaveGroup = (): void => {
    props.form.validateFields((errors, values) => {
      if (!errors) {
        return patchGenre({ variables: { input: { ...values, id: editing } } });
      }
    });
  };

  const handleDeleteGroup = (id: string): Promise<ExecutionResult> => {
    return deleteGroup({ variables: { id } });
  };

  const dataSource = genres.map(genre => ({ ...genre, key: genre.id })).sort(sortByDate('createdAt'));

  const ActionCell = (text, record): JSX.Element => {
    const isThisRowEditing = isRowEditing(record.id);
    return (
      <div className={styles.actionsCell}>
        <ActionEditButton
          editing={isThisRowEditing}
          onEditClick={(): void => {
            editing && resetFields();
            setEditing(record.id);
          }}
          onCancelClick={(): void => setEditing(undefined)}
          onSaveClick={handleSaveGroup}
          saveLoading={patchLoading}
        />
        <Popconfirm title="Удалить?" onConfirm={(): Promise<ExecutionResult> => handleDeleteGroup(record.id)}>
          <Button icon={'delete'} shape={'circle'} size={'small'} loading={deleteLoading} />
        </Popconfirm>
      </div>
    );
  };

  const columns = [
    {
      title: 'Жанр',
      dataIndex: 'name',
      editable: true,
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      render: (text, genre): string => format(new Date(genre.createdAt), DateTimeFormat),
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      render: ActionCell,
    },
  ].map(col =>
    col.editable
      ? {
          ...col,
          onCell: (record: Genre): object => ({
            name: col.dataIndex,
            title: col.title,
            editing: isRowEditing(record.id),
            record,
            getFieldDecorator: props.form.getFieldDecorator,
          }),
        }
      : col,
  );

  const components = {
    body: {
      cell: EditableTableCell,
    },
  };

  return (
    <Table
      components={components}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      pagination={{ pageSize: 50 }}
    />
  );
};

export default Form.create<FormComponentProps>()(GenresTable);
