import React from 'react';
import { CabinetPageTemplate } from '../cabinet/CabinetPageTemplate';
import DirectionsTable from './DirectionsTable';
import CreateDirectionForm from './CreateDirectionForm';

export const DirectionsAdminPage = (): JSX.Element => {
  return (
    <CabinetPageTemplate>
      <CreateDirectionForm />
      <DirectionsTable />
    </CabinetPageTemplate>
  );
};

export default DirectionsAdminPage;
