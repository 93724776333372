import React from 'react';
import { Button, Typography } from 'antd';

import { ROUTES } from '../../routes';
import { NavLink } from 'react-router-dom';

const style = require('./CartEmptyText-style.module.css');

export function CartEmptyText(): JSX.Element {
  return (
    <div className={style['empty-cart-wrapper']}>
      <Typography.Title level={2}>Корзина пуста</Typography.Title>
      <Typography.Paragraph>
        Вы можете начать свой выбор со страницы <NavLink to={ROUTES.COMMON.TRACKS.MAIN}>Купить музыку</NavLink> или
        воспользоваться поиском.
      </Typography.Paragraph>
      <NavLink to={ROUTES.COMMON.TRACKS.MAIN}>
        <Button type={'primary'}>Найти музыку</Button>
      </NavLink>
    </div>
  );
}
