import React from 'react';
import { Input } from 'antd';
import { Form } from '@ant-design/compatible';
import { GetFieldDecoratorOptions } from '@ant-design/compatible/es/form/Form';

interface Record {
  [key: string]: string;
}

interface EditableCellProps {
  children: JSX.Element;
  record: Record;
  name: string;
  title: string;
  editing: boolean;
  required: boolean;
  inputType: string;
  getFieldDecorator(id: string, options?: GetFieldDecoratorOptions): (node: React.ReactNode) => React.ReactNode;
}

export function EditableTableCell(props: EditableCellProps): JSX.Element {
  return (
    <td>
      {props.editing ? (
        <Form.Item style={{ margin: 0 }}>
          {props.getFieldDecorator(props.name, {
            rules: [
              {
                required: props.required,
                message: `Введите, пожалуйста, ${props.title}!`,
              },
            ],
            initialValue: props.record[props.name],
          })(<Input type={props.inputType} />)}
        </Form.Item>
      ) : (
        props.children
      )}
    </td>
  );
}
