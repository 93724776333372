import React from 'react';
import { TRACK_STATUS } from '../../track.interfaces';
import { TrackTableWithdrawTrackButton } from './TrackTableWithdrawTrackButton';
import { TrackTableReturnTrackButton } from './TrackTableReturnTrackButton';

export const TrackTableWithdrawTrack = (props: { status?: number; trackId: string }): JSX.Element => {
  return props?.status === TRACK_STATUS.WITHDRAWED.value ? (
    <TrackTableReturnTrackButton trackId={props.trackId} />
  ) : (
    <TrackTableWithdrawTrackButton trackId={props.trackId} />
  );
};
