import React, { Ref } from 'react';
import { Select } from 'antd';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { DirectionFieldFindAll } from '../../__generated__/DirectionFieldFindAll';
import { SelectValue } from 'antd/es/select';

const DIRECTION_FIELD_FIND_ALL = gql`
  query DirectionFieldFindAll {
    common {
      directions {
        find {
          id
          name
        }
      }
    }
  }
`;

const DirectionField = (props, ref: Ref<Select<SelectValue>>): JSX.Element => {
  const { data, loading } = useQuery<DirectionFieldFindAll>(DIRECTION_FIELD_FIND_ALL);

  const directions = data?.common?.directions?.find || [];

  return (
    <Select loading={loading} {...props} ref={ref}>
      {directions.map(direction => (
        <Select.Option key={direction.id} value={direction.name}>
          {direction.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export const DirectionsSelect = React.forwardRef(DirectionField);
