import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { DateTimeFormat } from '../../config';
import { format } from 'date-fns';
import {
  DELETE_INSTRUMENT_MUTATION,
  INSTRUMENTS_FIND_ALL_QUERY,
  PATCH_INSTRUMENT_MUTATION,
} from './instrument.graphql';

import { Button, Popconfirm, message, Table } from 'antd';
import { ActionEditButton } from '../table/ActionEditButton';
import { EditableTableCell } from '../table/EditableTableCell';
import { Instrument } from './index';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { Form } from '@ant-design/compatible';
import { pick, sortByDate } from '../../utils';
import { ExecutionResult } from 'graphql';

const styles = require('./InstrumentTable-styles.module.css');

export const InstrumentTable = (props: FormComponentProps): JSX.Element => {
  const [editing, setEditing] = useState<string | undefined>(undefined);
  const { loading, data } = useQuery(INSTRUMENTS_FIND_ALL_QUERY);
  const [deleteInstrument, { loading: deleteLoading }] = useMutation(DELETE_INSTRUMENT_MUTATION, {
    refetchQueries: [{ query: INSTRUMENTS_FIND_ALL_QUERY }],
    onCompleted: () => {
      message.success('Инструмент успешно удалён');
    },
  });
  const [patchInstrument, { loading: patchLoading }] = useMutation(PATCH_INSTRUMENT_MUTATION, {
    refetchQueries: [{ query: INSTRUMENTS_FIND_ALL_QUERY }],
    onCompleted: () => {
      message.success(`Инструмент успешно обновлён`);
      setEditing(undefined);
    },
  });

  const { form } = props;
  const { resetFields } = form;

  const instruments = pick<Instrument[]>(data, 'common.instruments') || [];

  const isRowEditing = (id: string): boolean => {
    return editing === id;
  };

  const handleSaveInstrument = (): void => {
    props.form.validateFields((errors, values) => {
      if (!errors) {
        return patchInstrument({ variables: { input: { ...values, id: editing } } });
      }
    });
  };

  const handleDeleteInstrument = (id: string): Promise<ExecutionResult> => {
    return deleteInstrument({ variables: { id } });
  };

  const dataSource = instruments
    .map(instrument => ({ ...instrument, key: instrument.id }))
    .sort(sortByDate('createdAt'));

  const ActionCell = (text, record): JSX.Element => {
    const isThisRowEditing = isRowEditing(record.id);
    return (
      <div className={styles.actionsCell}>
        <ActionEditButton
          editing={isThisRowEditing}
          onEditClick={(): void => {
            editing && resetFields();
            setEditing(record.id);
          }}
          onCancelClick={(): void => setEditing(undefined)}
          onSaveClick={handleSaveInstrument}
          saveLoading={patchLoading}
        />
        <Popconfirm title="Удалить?" onConfirm={(): Promise<ExecutionResult> => handleDeleteInstrument(record.id)}>
          <Button icon={'delete'} shape={'circle'} size={'small'} loading={deleteLoading} />
        </Popconfirm>
      </div>
    );
  };

  const columns = [
    {
      title: 'Инструмент',
      dataIndex: 'name',
      editable: true,
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      render: (text, instrument): string => format(new Date(instrument.createdAt), DateTimeFormat),
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      render: ActionCell,
    },
  ].map(col =>
    col.editable
      ? {
          ...col,
          onCell: (record: Instrument): object => ({
            name: col.dataIndex,
            title: col.title,
            editing: isRowEditing(record.id),
            record,
            getFieldDecorator: props.form.getFieldDecorator,
          }),
        }
      : col,
  );

  const components = {
    body: {
      cell: EditableTableCell,
    },
  };

  return (
    <Table
      components={components}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      pagination={{ pageSize: 50 }}
    />
  );
};

export default Form.create<FormComponentProps>()(InstrumentTable);
