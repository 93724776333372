import React from 'react';


interface Props {
  size?: number;
  spinnerColor?: string;
  spinnerWidth?: number;
}

const styles = require('./Spinner-styles.module.css');

export function Spinner({ spinnerColor = '#1e73be', spinnerWidth = 3, size = 40 }: Props): JSX.Element {
  return (
    <div>
      <div
        className={styles.spinner}
        style={{
          width: size,
          height: size,
          borderColor: spinnerColor,
          borderWidth: spinnerWidth,
        }}
      />
    </div>
  );
}
