import React from 'react';
import { CabinetPageTemplate } from '../cabinet/CabinetPageTemplate';
import { useQuery } from '@apollo/react-hooks';
import { format } from 'date-fns';
import { Table } from 'antd';
import { DateTimeFormat } from '../../config';
import { AccountAdminPageFindAllQuery } from '../../__generated__/AccountAdminPageFindAllQuery';
import gql from 'graphql-tag';
import { AccountTableEditButton } from './AccountTableEditButton';
import { AccountTableDeleteButton } from './AccountTableDeleteButton';

export const ACCOUNT_ADMIN_PAGE_FIND_ALL_QUERY = gql`
  query AccountAdminPageFindAllQuery {
    admin {
      account {
        find {
          id
          fullName
          email
          createdAt
        }
      }
    }
  }
`;

export const AdminAccountTablePage = (): JSX.Element => {
  const { loading, data } = useQuery<AccountAdminPageFindAllQuery>(ACCOUNT_ADMIN_PAGE_FIND_ALL_QUERY);

  const accounts = data?.admin?.account?.find || [];

  const dataSource = accounts.map(account => {
    return { ...account, key: account.id };
  });

  const ActionsCell = (text, account): JSX.Element => (
    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
      <AccountTableEditButton accountId={account.id} />
      <AccountTableDeleteButton accountId={account.id} />
    </div>
  );

  const DateCell = (text, account): JSX.Element => {
    return <>{format(new Date(account.createdAt), DateTimeFormat)}</>;
  };

  const columns = [
    {
      title: 'Имя пользователя',
      dataIndex: 'fullName',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
    },
    {
      title: 'Дата регистрации',
      dataIndex: 'createdAt',
      render: DateCell,
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      render: ActionsCell,
    },
  ];

  return (
    <CabinetPageTemplate>
      <Table loading={loading} dataSource={dataSource} columns={columns} />
    </CabinetPageTemplate>
  );
};
