import React from 'react';
import { RegularPageTemplate } from '../RegularPageTemplate';
import { ForgotForm } from './index';

export function ForgotPage(): JSX.Element {
    return (
        <RegularPageTemplate>
            <div className="row">
                <div className="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                    <ForgotForm />
                </div>
            </div>
        </RegularPageTemplate>
    );
}
