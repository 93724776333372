// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { CabinetPageTemplate } from '../cabinet/CabinetPageTemplate';
import {Form, Input, Typography, Button, message, Spin, InputNumber, Space} from 'antd';

import { formItemLayout } from '../form/options';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ROUTES } from '../../routes';
import {UploadMainSample, UploadSample} from './track.interfaces';
import { PayloadStatuses } from '../../types';
import { useParams, useHistory } from 'react-router';
import { SamplesField } from './SamplesField';
import { GenresField } from '../genre/GenresField';
import { InstrumentsField } from '../instrument/InstrumentsField';
import { MoodsField } from '../mood/MoodsField';
import { COMPOSER_TRACKS_FIND_ALL_QUERY } from './ComposerTracksTablePage';
import { MessageType } from 'antd/es/message';
import { ExecutionResult } from 'graphql';
import gql from 'graphql-tag';
import { ComposerTrackEditFindById } from '../../__generated__/ComposerTrackEditFindById';
import { ComposerTrackPatchMutation } from '../../__generated__/ComposerTrackPatchMutation';
import { MainSampleField } from './MainSampleField';

const styles = require('./ComposerTrackEditPage-styles.module.css');

const COMPOSER_TRACK_EDIT_FIND_BY_ID = gql`
  query ComposerTrackEditFindById($id: String!) {
    client {
      composer {
        track {
          findById(id: $id) {
            id
            name
            author
            authorName
            coauthor
            genres {
              name
            }
            instruments {
              name
            }
            moods {
              name
            }
            bpm
            description
            samples {
              id
              name
              description
            }
          }
        }
      }
    }
  }
`;

export const COMPOSER_TRACK_PATCH_MUTATION = gql`
  mutation ComposerTrackPatchMutation($input: TrackComposerPatchInput) {
    client {
      composer {
        track {
          patch(input: $input) {
            status
          }
        }
      }
    }
  }
`;

function TrackEditForm(): JSX.Element {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();
  const [uploadSampleList, setUploadSampleList] = useState<UploadSample[]>([]);
  const { data: findTrackData, loading: findTrackLoading } = useQuery<ComposerTrackEditFindById>(
    COMPOSER_TRACK_EDIT_FIND_BY_ID,
    {
      variables: { id },
    },
  );

  const [patchTrack, { loading: patchTrackLoading }] = useMutation<ComposerTrackPatchMutation>(
    COMPOSER_TRACK_PATCH_MUTATION,
    {
      refetchQueries: [
        {
          query: COMPOSER_TRACKS_FIND_ALL_QUERY,
        },
        {
          query: COMPOSER_TRACK_EDIT_FIND_BY_ID,
          variables: { id },
        },
      ],
      onCompleted: data => {
        if (data.client?.composer?.track?.patch?.status === PayloadStatuses.SUCCESS)
          history.push(ROUTES.CABINET.COMPOSER.TRACKS.LIST);
      },
    },
  );

  const track = findTrackData?.client?.composer?.track?.findById;


  useEffect(() => {
    if (track && track.samples) setUploadSampleList(track.samples);
  }, [track]);

  console.log("Track coauthor ",track?.coauthor , track?.coauthor?.substring(1).substring(0, track?.coauthor.length-2).replaceAll('"', "").split(','), "type ", typeof(track?.coauthor), );
  const handleSubmit = (values): MessageType | Promise<ExecutionResult> => {
    if (uploadSampleList.length === 0) return message.error('Пожалуйста, загрузите хотя бы один семпл!');
    const input = {
      id: track?.id,
      ...values,
      coauthor: values.coauthor+"",
      samples: uploadSampleList.reduce((samples: UploadSample[], sample) => {
        return sample.id ? samples.concat({ id: sample.id, description: sample.description }) : samples;
      }, []),
    };

    return patchTrack({
      variables: {
        input,
      },
    });
  };

  return findTrackLoading || patchTrackLoading || !track ? (
    <Spin />
  ) : (
      <div className="layout-center">
        <div className="container">
    <Form
      {...formItemLayout}
      className={styles.addTrackForm}
      form={form}
      style={{transform: "translateX(-200px)"}}
      initialValues={{
        name: track.name,
        author: track?.author,
        genres: track.genres.map(genre => genre.name),
        instruments: track.instruments.map(instrument => instrument.name),
        moods: track.moods.map(mood => mood.name),
        bpm: track.bpm,
        coauthor: track?.coauthor?.replaceAll('"', "").split(','),
        description: track.description,
      }}
      onFinish={handleSubmit}
      scrollToFirstError={true}
    >
      <Typography.Title level={3} className={styles.title}>
        Редактировать трек
      </Typography.Title>
      <Form.Item
        label="Название трека"
        name={'name'}
        rules={[{ required: true, message: 'Пожалуйста, введите название трека!' }]}
      >
        <Input placeholder={'Вечерний вальс'} />
      </Form.Item>
      <Form.Item label={'Автор'}
                 name={'author'}
                 rules={[{ required: true, message: 'Пожалуйста, введите имя и фамилию автора!' }]}
      >
        <Input placeholder={track?.authorName} />
      </Form.Item>
      <Form.Item
          label={'Соавтор'}
          name={'coauthor'}>
        <Form.List
            style={{width: '100%'}}
            initialValue={track?.coauthor?.substring(1).substring(0, track?.coauthor.length-2).replaceAll('"', "").split(',')}
            name="coauthor">
          {(fields, { add, remove }) => (
              <>
                {(fields.map((field, index) => (
                    <div key={index}  style={{display: "flex",  width: '100%'}} >
                      <Form.Item
                          style={{ minWidth: '90%'}}
                          {...field}
                      >
                        <Input style={{ minWidth: '90%'}} />

                      </Form.Item>
                      <MinusCircleOutlined style={{marginTop: '10px'}} onClick={() => remove(field.name)} />
                    </div>
                )))}

                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Добавить соавтора
                  </Button>
                </Form.Item>
              </>
          )}
        </Form.List>
      </Form.Item>
      <GenresField />
      <InstrumentsField />
      <MoodsField />
      <Form.Item label="BPM" name={'bpm'}>
        <InputNumber min={0} placeholder={'120'} />
      </Form.Item>
      <Form.Item label="Основной трек">
        <MainSampleField
            samples={uploadSampleList}
            onChange={(samples): void => {
              setUploadSampleList([samples[0]]);
            }}
        />
      </Form.Item>
      <Form.Item label="Версии трека">
        <SamplesField
          samples={uploadSampleList}
          onChange={(samples): void => {
            setUploadSampleList([...samples]);
          }}
        />
      </Form.Item>
      <Form.Item>
        <Button type={'primary'} htmlType={'submit'}>
          Сохранить
        </Button>
      </Form.Item>
    </Form>
        </div>
      </div>
  );
}

export function ComposerTrackEditPage(): JSX.Element {
  return (
    <CabinetPageTemplate>
      <TrackEditForm />
    </CabinetPageTemplate>
  );
}
