import React from 'react';
import { RegularPageTemplate } from '../RegularPageTemplate';
import { LoginForm } from './index';

export function LoginPage(): JSX.Element {
  return (
    <RegularPageTemplate>
      <div className="row">
        <div className="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
          <LoginForm />
        </div>
      </div>
    </RegularPageTemplate>
  );
}
