import React, { useState } from 'react';
import { Button, message, Steps, Row, Col, Result, Typography } from 'antd';
import { UserOutlined, SolutionOutlined, CreditCardOutlined, FileDoneOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../routes';
import { ComposerOnboardingAboutStep } from './ComposerOnboardingAboutStep';
import styles from './onboarding.module.css';
import { ComposerOnboardingDocumentsStep } from './ComposerOnboardingDocumentsStep';
import { ComposerOnboardingStepActions } from './ComposerOnboardingStepActions';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { ExecutionResult } from 'graphql';
import { ComposerFinishOnboardingMutation } from '../../../../__generated__/ComposerFinishOnboardingMutation';
import {IS_COMPOSER} from "../../../../graphql/common.queries";
const { Title } = Typography;

const { Step } = Steps;

interface BecomeAComposerWizardStep {
  title: string;
  content: JSX.Element;
  icon: JSX.Element;
}

const WizardForm = (): JSX.Element => {
  const [current, setCurrent] = useState(0);

  const COMPOSER_FINISH_ONBOARDING_MUTATION = gql`
    mutation ComposerFinishOnboardingMutation {
      client {
        composer {
          finishOnboarding {
            status
          }
        }
      }
    }
  `;

  const [finishOnboarding] = useMutation<ComposerFinishOnboardingMutation>(COMPOSER_FINISH_ONBOARDING_MUTATION, {
    refetchQueries: [
      {
        query: IS_COMPOSER
      },
    ],
    onCompleted: () => {
      setCurrent(current + 1);
    },
  });

  const prev = (): void => {
    setCurrent(current - 1);
  };
  const next = (): void => {
    setCurrent(current + 1);
  };

  const toFinalStepAction = (): Promise<ExecutionResult<ComposerFinishOnboardingMutation>> => {
    return finishOnboarding();
  };

  const RequisitesStep = (): JSX.Element => {
    return (
      <div>
        <div className={styles.stepsContentWrapper}>
          <Result
            status={'info'}
            title={'Реквизиты OneWallet'}
            subTitle={'Здесь будет переход на сайт для привязки карты для вывода'}
          />
        </div>
        <ComposerOnboardingStepActions prev={prev} next={toFinalStepAction} />
      </div>
    );
  };

  const FinalStep = (): JSX.Element => {
    message.success('Регистрация завершена!');
    return (
      <div>
        <div className={styles.stepsContentWrapper}>
          <Result
            status="success"
            title="Поздравляем!"
            subTitle=" Вы успешно загеристрировались как композитор! Загрузите свой первый трек в систему!"
            extra={[
              <Button type="primary" key="console">
                <Link to={ROUTES.CABINET.COMPOSER.NEW_TRACK}>Загрузить трек</Link>
              </Button>,
              <Button key="buy">
                <Link to={ROUTES.CABINET.MAIN}>На главную</Link>
              </Button>,
            ]}
          />
        </div>
      </div>
    );
  };

  const steps: BecomeAComposerWizardStep[] = [
    {
      title: 'О себе',
      content: <ComposerOnboardingAboutStep next={next} />,
      icon: <UserOutlined />,
    },
/*    {
      title: 'Документы',
      content: <ComposerOnboardingDocumentsStep prev={prev} next={next} />,
      icon: <SolutionOutlined />,
    },*/
    {
      title: 'Реквизиты',
      content: <RequisitesStep />,
      icon: <CreditCardOutlined />,
    },
    {
      title: 'Готово!',
      content: <FinalStep />,
      icon: <FileDoneOutlined />,
    },
  ];

  return (
    <Row justify={'center'} style={{ display: 'flex', transform: "translateX(-50px)" }} className="container ">
      <Col xs={24} >
        <Title  style={{marginTop: 20, width: '100%', textAlign: 'center'}} level={3} >Чтобы присоединиться к нашей команде, пожалуйста, заполните анкету</Title>
      </Col>
      <Col xs={24} sm={20} md={18} lg={16}>
        <Steps current={current} size={'small'}>
          {steps.map((s: BecomeAComposerWizardStep) => (
            <Step title={s.title} icon={s.icon} key={s.title} />
          ))}
        </Steps>
        {steps[current].content}
      </Col>
    </Row>
  );
};

const ComposerOnboardingWizard = (): JSX.Element => {
  return <WizardForm />;
};

export default ComposerOnboardingWizard;
