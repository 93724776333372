import React from 'react';
import { FileDoneOutlined } from '@ant-design/icons';
import {Button, message, Tooltip} from 'antd';
import gql from 'graphql-tag';
import {useMutation} from "@apollo/react-hooks";
import {ComposerTrackWithdrawMutation} from "../../../../__generated__/ComposerTrackWithdrawMutation";




/*const [withdraw, { loading }] = useMutation<ComposerTrackWithdrawMutation>(TRACK_DELETE_MUTATION, {
	onCompleted: data => {
		if ( ) {
			message.success('Трек успешно удален');
		}
	},
});*/

export const TrackCertificateButton = (props: { track: any }): JSX.Element => {
	return (
		<Tooltip title="Скачать сертификат">
			<Button
				href={`/download/order/${props.track.orderId}/certificate`}
				style={{
					marginRight: '5px',
				}}
				target={'blank'}
				download={true}
				size={'small'}
				shape={'circle'}
			>
				<FileDoneOutlined />
			</Button>
		</Tooltip>
	);
};