import React, { FormEvent } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Form } from '@ant-design/compatible';
import { Input, Spin, Button } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import gql from 'graphql-tag';
import { PublisherCreateMutation } from '../../__generated__/PublisherCreateMutation';
import { PUBLISHERS_TABLE_FIND_QUERY } from "./PublishersTable";

const PUBLISHER_CREATE_MUTATION = gql`
  mutation PublisherCreateMutation($input: PublisherCreateInput) {
    admin {
      publisher {
        create(input: $input) {
          status
          publisher {
            id
            name
          }
        }
      }
    }
  }
`;

export function PublisherCreateForm(): JSX.Element {
  const [publisherCreate, { loading }] = useMutation<PublisherCreateMutation>(PUBLISHER_CREATE_MUTATION, {
    refetchQueries: [
      {
        query: PUBLISHERS_TABLE_FIND_QUERY,
      },
    ],
  });

  const CreatePublisherForm = Form.create<FormComponentProps>({
    name: 'createPublisherForm',
  })((props: FormComponentProps) => {
    const { form } = props;
    const { getFieldDecorator, validateFields } = form;

    const publisherCreateHandler = (e: FormEvent<HTMLFormElement>): void => {
      e.preventDefault();
      validateFields((errors, values) => {
        if (!errors) {
          return publisherCreate({
            variables: { input: values },
          });
        }
      });
    };

    return (
      <Form onSubmit={publisherCreateHandler} layout={'inline'}>
        <Form.Item>
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Пожалуйста, введите имя издателя!',
              },
            ],
          })(<Input placeholder="BMSProduction" />)}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType={'submit'}>
            {loading ? <Spin /> : 'Добавить издателя'}
          </Button>
        </Form.Item>
      </Form>
    );
  });

  return <CreatePublisherForm />;
}
