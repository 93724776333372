import React from 'react';
import { NavLink } from 'react-router-dom';
import { HeaderSearch } from './HeaderSearch';
import { ROUTES } from '../../routes';

import cx from 'classnames';
import { MenuOutlined } from '@ant-design/icons';
import { IS_AUTHENTICATED, isAuthenticated } from '../../graphql/common.queries';
import {useQuery} from "@apollo/react-hooks";


const styles = require('./HeaderNav-styles.module.css');

const MenuItem = (props: { href: string; children: string }): JSX.Element => (
  <li className={styles.li}>
    <NavLink to={props.href} className={styles.menuItemLink} exact={true} activeClassName={styles.active}>
      {props.children}
    </NavLink>
  </li>
);

export function HeaderNav(): JSX.Element {
  const { data, loading } = useQuery(IS_AUTHENTICATED/*, {fetchPolicy: "no-cache"}*/);
  return (
    <div className={cx('d-none d-md-block', styles.widenavContainer)}>
      <div className={cx('container', styles.headerNavContainer)}>
        <ul className={styles.nav}>
          <MenuItem href={ROUTES.COMMON.MAIN}>Главная</MenuItem>
          <MenuItem href={ROUTES.COMMON.TRACKS.MAIN}>Купить музыку</MenuItem>
          <MenuItem href={isAuthenticated(data) ? ROUTES.CABINET.COMPOSER.TRACKS.NEW :  ROUTES.AUTH.SIGN_UP_COMPOSER}>Продать музыку</MenuItem>
          <MenuItem href={ROUTES.COMMON.CONTACTS}>Контакты</MenuItem>
          <MenuItem href={ROUTES.COMMON.FAQ} >Помощь</MenuItem>
        </ul>
        <ul className={styles.searchUl}>
          <li className={styles.searchLi}>
            <HeaderSearch />
          </li>
        </ul>
      </div>
    </div>
  );
}

export function MobileNav(): JSX.Element {
  return (
    <ul className={styles.mobileNavUl}>
      <li className={styles.mobileNavLi}>
        <a
          href="#"
          data-open="#main-menu"
          data-pos="left"
          data-bg="main-menu-overlay"
          data-color=""
          className={styles.navMenuOpenLink}
          aria-controls="main-menu"
          aria-expanded="false"
        >
          <MenuOutlined className={styles.icon} />
          <span className={cx(styles.navMenuOpenTitle)}>MENU</span>{' '}
        </a>
      </li>
    </ul>
  );
}
