// @ts-nocheck
import React, { Suspense, lazy } from 'react';
import { RegularPageTemplate } from '../RegularPageTemplate';
import { Collapse } from 'antd';
const { Panel } = Collapse;
import { useQuery } from '@apollo/react-hooks';

import gql from 'graphql-tag';


export function AgreementPage(): JSX.Element {

	const COMMON_AGREEMENT_FIND_QUERY = gql`
    query agreement {
        common {
	      agreement {
	          id
	          text
	          title
	      }
	    }
	  }
	`;

	function callback(key) {
		console.log(key);
	}

	const {data, loading} = useQuery(COMMON_AGREEMENT_FIND_QUERY);

	console.log("DATA" , data)
	const text = data?.common?.agreement[0].text

	return (
		<RegularPageTemplate  style={{ padding: 0 }}>
			<div className={"container"}>
				<h2>
					Пользовательское соглашение
				</h2>
				<div  dangerouslySetInnerHTML={{__html: text}} />
			</div>

		</RegularPageTemplate>
	);
}
