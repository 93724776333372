import React, {useEffect} from 'react';
import {RegularPageTemplate} from '../RegularPageTemplate';
import {Layout, message} from 'antd';
import {CabinetSideMenu} from './CabinetSideMenu';
import {IS_AUTHENTICATED, isAuthenticated} from '../../graphql/common.queries';
import {Redirect, useHistory} from 'react-router';
import {ROUTES} from '../../routes';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {Spinner} from '../Spinner';
import {Button, notification} from 'antd';
import gql from "graphql-tag";


const ACCOUNT_NOTIFICATION_MUTATION = gql`
mutation UpdateAccountNotification($input:  UpdateAccountNotificationInput)   {
    client {
      updateNotificationInfo(input: $input)  {
        status 
        errors {
          message
        }
      }
    }
  }`;

let account;

export function CabinetPageTemplate(props: React.HTMLProps<HTMLDivElement>): JSX.Element {
    const {loading, data} = useQuery(IS_AUTHENTICATED);
    const [state, setState] = React.useState([])
    const [location, setLocation] = React.useState('')
    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            console.log(`You changed the page to: ${location.pathname}`)
            setLocation(location.pathname);
            if (account?.id) {
                const notificationMutation = updateNotification({
                    variables: {input: {id: account.id, newNotification: false}},
                })
            }
        })
    }, [history])

    useEffect(() => {
        if (data) {
            setState(data)
        }
    }, [data])


    const [updateNotification] = useMutation(ACCOUNT_NOTIFICATION_MUTATION, {
        onCompleted: data => {
            console.log(data);
        },
        refetchQueries: [{query: IS_AUTHENTICATED}],
    });

    const close = () => {
        console.log('Notification was closed. Either the close button was clicked or duration time elapsed.');
    };

    //console.log("loading ", loading);
    //console.log("data newNotification ", data?.client?.account?.newNotification);
    let newNotification = data?.client?.account?.newNotification
    account = data?.client?.account;


    const key = `open${Date.now()}`;

    useEffect(() => {
        openNotification();
        return () => {
            notification.close(key);
        }
    }, []);

    const closeNotification = () => {
        notification.close(key)
        const notificationMutation = updateNotification({
            variables: {input: {id: account.id, newNotification: false}},
        })
        history.push(ROUTES.COMMON.AGREEMENT);
        return notificationMutation;
    };


    const openNotification = () => {
        if (newNotification) {
            const btn = (
                <Button type="primary" size="small" onClick={closeNotification}>
                    Перейти
                </Button>
            );
            notification.open({
                message: 'Обновлено пользовательское соглашение',
                description:
                    'Пожалуйста, ознакомьтесь с новым пользовательским соглашением',
                btn,
                duration: 0,
                key,
                onClose: close,
            });
        } else {
            //console.log('No new notifications')
        }
    };

    return loading ? (
        <Spinner/>
    ) : isAuthenticated(data) ? (
        <RegularPageTemplate style={{padding: 0}}>

            <Layout>
                <CabinetSideMenu/>
                <Layout.Content
                    style={{
                        padding: 24,
                        background: '#fff',
                        minHeight: 280,
                    }}
                >
                    {props.children}
                </Layout.Content>
            </Layout>
        </RegularPageTemplate>
    ) : (
        <Redirect from="/cabinet/composer/track/new" to={ROUTES.AUTH.LOGIN}/>
    );
}
