import React, { MouseEvent, useCallback } from 'react';
import { PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';


const styles = require('./TablePlayButton-styles.module.css');

export function TablePlayButton(props: {
  onClick(): void;
  playing: boolean;
  style?: React.CSSProperties;
}): JSX.Element {
  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    props.onClick();
  }, []);

  return (
    <button className={styles.playButton} onClick={handleClick} style={props.style}>
      {props.playing ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
    </button>
  );
}

export default TablePlayButton;
